<div class="tab-content" id="pills-tabContent">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link pb-4"
        id="profile-tab"
        data-bs-toggle="tab"
        data-bs-target="#profile"
        type="button"
        role="tab"
        name="crear Talleres"
        aria-controls="profile"
        aria-selected="false"
        routerLink="./"
        routerLinkActive="is-active"
      >
        Todos los Usuarios
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <app-user-history></app-user-history>
  </div>
</div>
