<div class="card" style="width: 100%; height: 230px">
  <div class="card-body" style="height: 8rem">
    <div class="row icon-container">
      <div
        class="col-4 text-bold"
        [ngClass]="{
          'icon-ticket': icon == 'ticket',
          'icon-comment': icon == 'comment'
        }"
      >
        <h4 class="total">{{ totalTitle }}</h4>
      </div>
      <div class="col-6 m-auto">
        <p class="title h4 mb-2">
          {{ title }}<br />
          <span class="h6">EN TOTAL</span>
        </p>
      </div>
    </div>
  </div>
</div>
