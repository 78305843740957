<form [formGroup]="userServiceForm">

  <div class="row">

      <div class="col-lg-4 col-md-12 col-12">
        <label class="form-label form-control-lg d-block"
          >Rango de fecha
          <small
            [@warning]="tool.stateName(show)"
            class="text-danger"
            *ngIf="!userServiceForm.get('date').value"
            >*</small
          >
        </label>
        <p-calendar
          formControlName="date"
          [yearNavigator]="true"
          [maxDate]="maxDate"
          [showIcon]="true"
          dateFormat="dd/mm/yy"
          [inputStyle]="{ width: '100% !important' }"
          yearRange="2000:2030"
          selectionMode="range"
          [readonlyInput]="true"
          [disabledDays]="[0, 7]"
        ></p-calendar>
      </div>
   
    <div class="col-lg-3 col-md-12 col-12">
      <label class="form-label form-control-lg"
        >Representación Local
        <small
          [@warning]="tool.stateName(show)"
          class="text-danger"
          *ngIf="!userServiceForm.get('repLocalProvCode').value"
          >*</small
        >
      </label>
      <el-dropdown
        [options]="localReps"
        customClass="form-control form-control-lg colection-type"
        formControlName="repLocalProvCode"
        valueName="code"
        [stringOut]="true"
        placeHolder="Elige una representación"
      ></el-dropdown>
    </div>
    <div class="col-lg-3 col-md-6 col-12  m-5">
      <button
        type="submit"
        class="rounded-pill btn btn-save px-5"
        (click)="generateReport()"
      >
        Generar Reporte
      </button>
    </div>
  </div>

  


  </form>
  