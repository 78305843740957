import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateParserFormatter, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import saveAs from 'file-saver';
import * as moment from 'moment';
import { ConnectionService } from 'ngx-connection-service';
import { PrimeNGConfig } from 'primeng/api';
import { Observable, Subject, Subscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { user } from 'src/app/modules/auth/_models/User';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { PrimeTableComponent } from 'src/app/modules/shared/_elements/element-ui/prime-table/prime-table.component';
import { ButtonModel } from 'src/app/modules/shared/_elements/element-ui/table/models/button-model';
import { TableConfigView } from 'src/app/modules/shared/_elements/element-ui/table/models/table-config-view';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { environment } from 'src/environments/environment';
import { serviceOrder } from '../../serviceOrderForm/_models/serviceOrder';
import { inspectionReport } from '../../serviceOrderForm/inspection-report/_models/inspectionReport';
import { InspectionReportComponent } from '../../serviceOrderForm/inspection-report/inspection-report.component';
import { AdvancedFilterComponent } from '../advance-Filters/advanced-filter/advanced-filter.component';
import { AdvancedInspectionFilterComponent } from '../advance-Filters/advanced-inspection-filter/advanced-inspection-filter.component';
import { AdvancedProgramFilterComponent } from '../advance-Filters/advanced-program-filter/advanced-program-filter.component';
import { AdvancedUserServiceFilterComponent } from '../advance-Filters/advanced-user-service-filter/advanced-user-service-filter.component';
import { inspectionProgram } from '../inspection-program/_models/inspectionProgram';
import { UserService as serviceUser } from '../user-service-form/_model/UserService';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-record-history',
  templateUrl: './record-history.component.html',
  styleUrls: ['./record-history.component.css'], providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class RecordHistoryComponent implements OnInit, OnDestroy {
  urlApi: any;
  history: Subscription
  cols: TableConfigView[];
  hasRouter: boolean = false
  records: any[];
  filters: any[];
  loading: boolean = true;
  InitialFormValue: FormGroup;
  forms: any[] = [];
  localReps: DropDownOptionModel[] = []
  inspectors: any[] = []
  isUserDirector: boolean = false;
  isSupervisorOrHigher: boolean;
  currentUser: user;
  maxDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() }
  isNotReport: boolean
  hasInternet: boolean;
  InternetSubject = new Subject<boolean>();
  isDig: boolean = false;
  @ViewChild('table') table: PrimeTableComponent;
  @ViewChild('toggleBtn') toggleButton: ElementRef;

  constructor(private http: HttpClientService, private route: Router, private user: UserService,
    private fb: FormBuilder, private ngBModal: NgbModal, private primengConfig: PrimeNGConfig, private connectionService: ConnectionService,
    private tool: ToolsService, private renderer: Renderer2
  ) {
    this.urlApi = environment.api_url;
    this.connectionService.updateOptions({
      heartbeatExecutor: options => new Observable<any>(subscriber => {
        if (Math.random() > .5) {
          subscriber.next();
          subscriber.complete();
        } else {
          // throw new Error('Connection error');

        }
      })
    });

    this.connectionService.monitor().subscribe((currentState) => {
      this.InternetSubject.next(currentState.hasNetworkConnection);

    })
  }
  onFilterClick() {
    this.renderer.listen('window', 'click', (e: Event) => {
      let element: any = e;
      if (element.target.id === 'toggleBtn' || element.target.id === 'toggleBtnUserServ' || element.target.id === 'toggleBtnInspection') {
        this.toggleAdvanceFilter(true);
      }
    });
  }
  advanceFilterState(): boolean {
    const history = localStorage.getItem('typeHistory')

    switch (history) {
      case "ORDSE":
        if (this.tool.getFilterLS('orderFilter') && this.tool.getFilterLS('orderFilter').active) {
          return true
        }
        break;

      case "PROGI":
        if (this.tool.getFilterLS('programFilter') && this.tool.getFilterLS('programFilter').active) {
          return true
        }
        break;
      case "SERVU":
        if (this.tool.getFilterLS('serviceFilter') && this.tool.getFilterLS('serviceFilter').active) {
          return true
        }

        break;
      case "INFIN":
        if (this.tool.getFilterLS('inspectionFilter') && this.tool.getFilterLS('inspectionFilter').active) {
          return true
        }
        break;
    }
    return false
  }
  get getShow() {
    return this.searchForm.get('show').value
  }
  get getNearToClose() {
    return this.searchForm.get('almostClose').value
  }
  selectedHistory(): string {

    return localStorage.getItem('typeHistory');


  }
  searchForm: FormGroup;

  checkRole() {
    if (this.currentUser.roleCode !== 'DIG' && this.currentUser.roleCode !== 'INSP') {

      this.isSupervisorOrHigher = true
    }
    else {
      this.isSupervisorOrHigher = false
      if (this.currentUser.roleCode == 'DIG') {
        this.isDig = true;
      }
    }
  }

  toggleAdvanceFilter(autoActivete: boolean = false) {
    if (autoActivete) this.searchForm.get('advancedFilter').setValue(true);
    const state = this.searchForm.get('advancedFilter').value;

    switch (this.searchForm.get('typeHistory').value) {
      case "ORDSE":
        state ? this.tool.activateFilter('orderFilter') : this.tool.deactivateFilter('orderFilter')
        break;

      case "PROGI":
        state ? this.tool.activateFilter('programFilter') : this.tool.deactivateFilter('programFilter')
        break;
      case "SERVU":
        state ? this.tool.activateFilter('serviceFilter') : this.tool.deactivateFilter('serviceFilter')

        break;
      case "INFIN":
        state ? this.tool.activateFilter('inspectionFilter') : this.tool.deactivateFilter('inspectionFilter')
        break;
    }

    this.loadSelectedHistory()

  }
  ngOnDestroy(): void {
    // this.history.unsubscribe()

  }
  customButtons: ButtonModel[];

  async ngOnInit() {
    this.onFilterClick();
    this.hasInternet = this.tool.hasInternetConnection
    this.InternetSubject.pipe(startWith(true), pairwise()).subscribe(([p, c]) => {

      if (p == false && c == true) {
        this.hasInternet = true
        this.ngOnInit()
      } else if (p == true && c == false) {
        this.hasInternet = false
      } else if (c == true) {
        this.hasInternet = true

      }
      else {
        this.hasInternet = false

      }
    })
    this.currentUser = this.user.getUserData() as user
    this.checkRole()

    this.primengConfig.ripple = true;
    if (this.currentUser.roleCode == "DIR") {
      this.isUserDirector = true;
    }
    localStorage.getItem('typeHistory') ? localStorage.getItem('typeHistory') : localStorage.setItem('typeHistory', 'ORDSE');

    this.searchForm = this.fb.group({
      'sinceDate': new FormControl(this.dateToObject(moment().subtract(1, 'M').set('date', 1).format().toString())),
      untilDate: new FormControl(this.dateToObject(moment().format().toString())),
      show: new FormControl(false),
      almostClose: new FormControl(false),
      typeHistory: new FormControl(localStorage.getItem('typeHistory') ? localStorage.getItem('typeHistory') : 'ORDSE'),
      LocalRep: new FormControl(null),
      advancedFilter: new FormControl(this.advanceFilterState())
    })
    //this.InitialFormValue = {...this.searchForm.value};
    if (this.tool.hasInternetConnection) {
      this.localReps = await this.http.get<DropDownOptionModel[]>(`LocalRepresentativeProvince`).toPromise()
      this.inspectors = await this.http.get<DropDownOptionModel[]>(`User/UserByRolAndRepresentant?rolId=2`).toPromise()
      this.http.get<any[]>('TypeOfRegister?independentRecord=true&registrationStatus=true').subscribe(
        response => {
          this.forms = response.map((form: any) => { return { value: form['alternateField'], text: form['text'] } })
          this.loadSelectedHistory()
        }
      )


      if (localStorage.getItem('typeHistory') == "INFIN") this.isNotReport = false
    }
  }


  get Type() {

    return this.searchForm.get('typeHistory').value
  }

  loadSelectedHistory() {

    if (localStorage.getItem('typeHistory')) {
      this.searchForm.patchValue({
        typeHistory: localStorage.getItem('typeHistory')
      })
      this.changeTable(localStorage.getItem('typeHistory'))
    }
    else {
      this.getServiceOrder();
    }
  }

  showAlmostClose() {

    // this.searchForm.patchValue({
    //   'almostClose': !this.searchForm.get('almostClose').value
    // })


    if (this.searchForm.get('almostClose').value) {

      this.getRecordsWithDate()
    }
    else {

      this.searchForm.patchValue({
        'sinceDate': this.dateToObject(moment().subtract(1, 'M').set('date', 1).format().toString()),
        untilDate: this.dateToObject(moment().format().toString()),
      })
      this.getRecordsWithDate()
    }

  }
  showAllRecords() {


    // this.searchForm.patchValue({
    //   'show': !this.searchForm.get('show').value
    // })


    if (this.searchForm.get('show').value && this.isUserDirector) {
      this.searchForm.patchValue({
        'LocalRep': null
      })
      this.getRecordsWithDate()
    }
    else if (this.searchForm.get('show').value && !this.isUserDirector) {

      this.getRecordsWithDate()
    }
    else {

      // this.searchForm.reset(initial)
      this.searchForm.patchValue({
        'sinceDate': this.dateToObject(moment().subtract(1, 'M').set('date', 1).format().toString()),
        untilDate: this.dateToObject(moment().format().toString()),
      })
      this.getRecordsWithDate()
    }

  }


  dateToObject(date: string) {
    if (date) {
      let a: any[] = date.slice(0, 10).split('-').map(x => {
        return Number(x)
      })
      return { year: a[0], month: a[1], day: a[2] };
    }
    return null
  }

  getLocalRep() {
    return this.searchForm.get('LocalRep').value;
  }

  andAssigneeInspectorId(): string {
    const allowAny = ['SUP', 'REPLO', 'DIR'].some(i => i === this.currentUser.roleCode);

    if (allowAny) return '';

    return '&assignedInspectorId=' + this.currentUser.userId;
  }

  getServiceOrder(query?: string) {
    // this.InitialFormValue = {...this.searchForm.value};
    this.records = [];
    this.loading = true;

    this.customButtons = [
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 me-2",
        function: async (record: serviceOrder) => {
          this.route.navigate([`Casos/ordenServicio`, record.serviceOrderNumber])

        },
        tooltip: 'ver',
        icon: 'pi pi-eye'
      },
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2",
        function: async (record: serviceOrder) => {
          saveAs(`${this.urlApi}/PdfReport/ServiceOrder/${record.serviceOrderNumber}`, `${record.serviceOrderNumber}.pdf`)
        },
        tooltip: 'pdf',
        icon: 'pi pi-file-pdf'
      }]
    let since = this.searchForm.get('sinceDate').value
    let until = this.searchForm.get('untilDate').value
    let sinceDate = moment(`${since['year']}-${since['month']}-${since['day']}`).format().toString()
    let untilDate = moment(`${until['year']}-${until['month']}-${until['day']} 23:59:59`).format().toString()
    this.history = this.http.get<serviceOrder[]>(!query ? `ServiceOrderFirstStep/ServiceOrder/FirstStep?Status=true${this.andAssigneeInspectorId()}
    &MyCases=${this.isSupervisorOrHigher ? '' : this.currentUser.userCode}
${this.isUserDirector ? `&LocalRepresentativeId=${this.getLocalRep() ? this.getLocalRep() : ''}` : `&LocalRepresentativeId=${this.currentUser.repLocalProvId}`}
    &FromDate=${sinceDate}&ToDate=${untilDate}` : `ServiceOrderFirstStep/Massive?${query}`).subscribe(
      {
        next: response => {
          this.records = response
          this.loading = false;
          this.hasRouter = false;

        },
        error: error => {
          this.records = []
          this.loading = false
        }
      }
    )
    this.filters = ["serviceTypeInfo", "registeredByName", "serviceOrderNumber", "recordDateStr", "orderDateStr", "assignedInspectorName", "companyTradeName", "repLocalProvInfo", "statusOrderServiceInfo"]
    this.cols = [
      {
        field: 'serviceOrderNumber',
        header: 'NÚMERO ORDEN',
        view: { table: true, detail: true },

        tooltip: 'NÚMERO ORDEN',
        fixedColumn: true,
        color: "#1460B8",
      },
      {
        field: 'orderDateStr',
        header: 'FECHA ORDEN',
        view: { table: true, detail: true },

        tooltip: 'FECHA ORDEN',
        fixedColumn: true,

      },
      {
        field: 'serviceTypeInfo',
        header: 'TIPO',
        view: { table: true, detail: true },

        tooltip: 'TIPO DE ORDEN',
        fixedColumn: true,

      },
      {
        field: 'registeredByName',
        header: 'REGISTRADO POR',
        view: { table: true, detail: true },

        tooltip: 'REGISTRADO POR',
        fixedColumn: true,

      },
      {
        field: 'registrationDateStr',
        header: 'FECHA REGISTRO',
        view: { table: true, detail: true },

        tooltip: 'FECHA REGISTRO',
        fixedColumn: true,

      },
      {
        field: 'assignedInspectorName',
        header: 'INSPECTOR',
        view: { table: true, detail: true },

        tooltip: 'INSPECTOR',
        fixedColumn: true,
        hasMulti: this.isSupervisorOrHigher,
        multiFilter: {
          options: this.inspectors,
          text: 'fullName'
        }
      },
      {
        field: 'repLocalProvInfo',
        header: 'REP. LOCAL',
        view: { table: true, detail: true },

        tooltip: 'REP. LOCAL',
        fixedColumn: true,
        hasMulti: this.isSupervisorOrHigher,
        multiFilter: {
          options: this.localReps,
          text: 'text'
        }
      },
      {
        field: 'companyTradeName',
        header: 'EMPRESA     ',
        view: { table: true, detail: true },

        tooltip: 'EMPRESA',
        fixedColumn: true,

      },
      {
        field: 'statusOrderServiceInfo',
        header: 'ESTATUS',
        view: { table: true, detail: true },
        length: 30,
        tooltip: 'ESTATUS',
        fixedColumn: true,
        style: "statusColor",

      },

    ]
    if (this.isDig) {
      this.customButtons.pop()
    }
  }
  
  getUserServices(query?: string) {
    this.records = [];
    this.loading = true;
    this.customButtons = [
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2",
        function: async (record: serviceUser) => {
          this.route.navigate([`Casos/ServicioUsuario`, record.userServiceId])

        },
        tooltip: 'ver',
        icon: 'pi pi-eye'
      }]
    let since = this.searchForm.get('sinceDate').value
    let until = this.searchForm.get('untilDate').value
    let sinceDate = moment(`${since['year']}-${since['month']}-${since['day']}`).format().toString()
    let untilDate = moment(`${until['year']}-${until['month']}-${until['day']} 23:59:59`).format().toString()
    this.history = this.http.get<serviceUser[]>(!query ? `UserService/UserServices?Status=true&MyCases=${this.isSupervisorOrHigher ? '' : this.currentUser.userCode}&LocalRepresentativeId=${this.searchForm.get('LocalRep').value ? this.searchForm.get('LocalRep').value : this.currentUser.repLocalProvId}&FromDate=${sinceDate}&ToDate=${untilDate}`
      : `UserService/Advanced?${query}`).subscribe(
        {
          next: response => {

            this.records = response
            this.loading = false;
            this.hasRouter = false;

          },
          error: error => {
            this.records = []
            this.loading = false
          }
        }
      )

    this.filters = ["userServiceNumber", "reportDateSt", "applicantsName", "groupQuantity", "registeredByName"]
    this.cols = [
      {
        field: 'userServiceNumber',
        header: 'NÚMERO SERVICIO',
        view: { table: true, detail: true },

        tooltip: 'NÚMERO SERVICIO',
        fixedColumn: true,
        color: "#1460B8",

      },

      {
        field: 'reportDateStr',
        header: 'FECHA SOLICITUD',
        view: { table: true, detail: true },

        tooltip: 'FECHA SOLICITUD',
        fixedColumn: true,

      },
      {
        field: 'typeOfServiceListedStr',
        header: 'TIPO SERVICIO',
        view: { table: true, detail: true },

        tooltip: 'TIPO SERVICIO',
        fixedColumn: true,

      },
      {
        field: 'groupQuantity',
        header: 'CANTIDAD SOLICITANTES',
        view: { table: true, detail: true },

        tooltip: 'CANTIDAD SOLICITANTES',
        fixedColumn: true,

      },
      {
        field: 'repLocalProvInfo',
        header: 'REP. LOCAL',
        view: { table: true, detail: true },

        tooltip: 'REP. LOCAL',
        fixedColumn: true,
        hasMulti: this.isSupervisorOrHigher,
        multiFilter: {
          options: this.localReps,
          text: 'text'
        }
      },
      {
        field: 'registeredByName',
        header: 'REGISTRADO POR',
        view: { table: true, detail: true },

        tooltip: 'REGISTRADO POR',
        fixedColumn: true,


      }]
  }
  getInspectionPrograms(query?: string) {
    // this.InitialFormValue = {...this.searchForm.value};
    this.records = [];
    this.loading = true;
    this.customButtons = [
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2",
        function: async (record: inspectionProgram) => {
          this.route.navigate([`Casos/programaInspeccion`, record.inspectionProgramId])

        },
        tooltip: 'ver',
        icon: 'pi pi-eye'
      }]
    let since = this.searchForm.get('sinceDate').value
    let until = this.searchForm.get('untilDate').value
    let sinceDate = moment(`${since['year']}-${since['month']}-${since['day']}`).format().toString()
    let untilDate = moment(`${until['year']}-${until['month']}-${until['day']} 23:59:59`).format().toString()

    this.history = this.http.get<inspectionProgram[]>(!query ? `InspectionProgram?Status=true&LocalRepresentativeId=${this.searchForm.get('LocalRep').value ? this.searchForm.get('LocalRep').value : this.currentUser.repLocalProvId}&FromDate=${sinceDate}&ToDate=${untilDate}`
      : `InspectionProgram/Advanced?${query}`).subscribe(
        {
          next: response => {

            this.records = response
            this.loading = false;
            this.hasRouter = false;

          },
          error: error => {
            this.records = []
            this.loading = false
          }
        }
      )

    this.filters = ["inspectionProgramNumber", "programName", "localRepresentativeProvinceInfo", "geographicLimit", "statusInspectionProgramInfo"]
    this.cols = [
      {
        field: 'inspectionProgramNumber',
        header: 'NÚMERO PROGRAMA',
        view: { table: true, detail: true },

        tooltip: 'NÚMERO PROGRAMA',
        fixedColumn: true,
        color: "#1460B8",

      },
      {
        field: 'programName',
        header: 'PROGRAMA   ',
        view: { table: true, detail: true },

        tooltip: 'PROGRAMA',
        fixedColumn: true,

      },
      {
        field: 'registrationDateStr',
        header: 'FECHA REGISTRO',
        view: { table: true, detail: true },

        tooltip: 'FECHA REGISTRO',
        fixedColumn: true,

      },
      {
        field: 'registeredByName',
        header: 'REGISTRADO POR ',
        view: { table: true, detail: true },

        tooltip: 'REGISTRADO POR',
        fixedColumn: true,

      },
      {
        field: 'localRepresentativeProvinceInfo',
        header: 'REP. LOCAL',
        view: { table: true, detail: true },

        tooltip: 'REP. LOCAL',
        fixedColumn: true,
        hasMulti: this.isSupervisorOrHigher,
        multiFilter: {
          options: this.localReps,
          text: 'text'
        }
      },
      {
        field: 'geographicLimit',
        header: 'LÍMITE GEOGRAFICO',
        view: { table: true, detail: true },

        tooltip: 'LÍMITE GEOGRAFICO',
        fixedColumn: true,

      },
      {
        field: 'statusInspectionProgramInfo',
        header: 'ESTADO     ',
        view: { table: true, detail: true },

        tooltip: 'ESTADO',
        fixedColumn: true,

      }]
  }

  getInspectionReports(query?: string) {
    // this.InitialFormValue = {...this.searchForm.value};
    this.records = [];
    this.loading = true;

    this.customButtons = [
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 me-2",
        function: async (record: inspectionReport) => {
          let edit = true;

          if (record.reportStatusInfo === "Informe Archivado") {
            edit = false;
          }
          const ngmodal: NgbModalRef = this.ngBModal.open(
            InspectionReportComponent, { size: 'xl' });
          ngmodal.componentInstance.inspectionReport = cloneDeep(record);
          ngmodal.componentInstance.canEdit = edit
          ngmodal.result.then(edit => {
            if (edit) {
              this.loadSelectedHistory()
            }
          })
        },
        tooltip: 'ver',
        icon: 'pi pi-eye'
      },
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2",
        function: async (record: inspectionReport) => {
          saveAs(`${this.urlApi}/PdfReport/InspectionReport/${record.inspectionReportNumber}`, `${record.inspectionReportNumber}.pdf`)
        },
        tooltip: 'pdf',
        icon: 'pi pi-file-pdf'
      }]
    let since = this.searchForm.get('sinceDate').value
    let until = this.searchForm.get('untilDate').value
    let sinceDate = moment(`${since['year']}-${since['month']}-${since['day']}`).format().toString()
    let untilDate = moment(`${until['year']}-${until['month']}-${until['day']} 23:59:59`).format().toString()
    this.history = this.http.get<inspectionReport[]>(!query ? `InspectionReport?Status=true&MyCases=${this.isSupervisorOrHigher ? '' : this.currentUser.userCode}&LocalRepresentativeId=${this.searchForm.get('LocalRep').value ? this.searchForm.get('LocalRep').value : this.currentUser.repLocalProvId}&FromDate=${sinceDate}&ToDate=${untilDate}` :
      `InspectionReport/Advanced?${query}`).subscribe(
        {
          next: response => {

            this.records = response
            this.loading = false;
            this.hasRouter = true;

          },
          error: error => {
            this.records = []
            this.loading = false
          }
        }
      )

    this.filters = ["registeredByName", "assignedInspectorInfo", "reportDate", "reportStatusInfo", "serviceOrderNumber", "inspectionReportNumber"]
    this.cols = [

      {
        field: 'serviceOrderNumber',
        view: { table: true, detail: true },
        header: 'NÚMERO ORDEN',
        width: 3,
        tooltip: 'NÚMERO ORDEN',
        fixedColumn: true,

      },
      {
        field: 'inspectionReportNumber',
        view: { table: true, detail: true },
        header: 'NÚMERO INFORME',
        width: 3,
        tooltip: 'NÚMERO INFORME',
        fixedColumn: true,

      },


      {
        field: 'reportDateStr',
        header: 'FECHA INFORME',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'FECHA INFORME',
        fixedColumn: true,

      },
      {
        field: 'assignedInspectorInfo',
        header: 'INSPECTOR',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'INSPECTOR',
        fixedColumn: true,
        hasMulti: this.isSupervisorOrHigher,
        multiFilter: {
          options: this.inspectors,
          text: 'fullName'
        }
        // multiFilter: true
      }
      ,
      {
        field: 'repLocalProvInfo',
        header: 'REP. LOCAL',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'REP. LOCAL',
        fixedColumn: true,
        length: 30,
        hasMulti: this.isSupervisorOrHigher,
        multiFilter: {
          options: this.localReps,
          text: 'text'
        }
      },
      {
        field: 'companyTradeName',
        header: 'EMPRESA',
        tooltip: 'EMPRESA',
        view: { table: true, detail: true },
        fixedColumn: true,
      },
      {
        field: 'reportStatusInfo',
        header: 'ESTADO',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'ESTADO',
        fixedColumn: true,
        length: 30
      }


    ]
    if (this.isDig) {
      this.customButtons.pop()
    }
  }


  getRecordsWithDate() {

    /// SERVICE ORDER
    if (this.selectedHistory() == 'ORDSE') {

      let since = this.searchForm.get('sinceDate').value
      let until = this.searchForm.get('untilDate').value
      let sinceDate = moment(`${since['year']}-${since['month']}-${since['day']}`).format().toString()
      let untilDate = moment(`${until['year']}-${until['month']}-${until['day']} 23:59:59`).format().toString()

      this.loading = true;
      this.history = this.http.get<serviceOrder[]>(`ServiceOrderFirstStep/ServiceOrder/FirstStep?Status=true${this.andAssigneeInspectorId()}
      ${this.isSupervisorOrHigher ? '' : '&MyCases=' + this.currentUser.userCode}
      ${this.isUserDirector ? `&LocalRepresentativeId=${this.getLocalRep() ? this.getLocalRep() : ''}` : `&LocalRepresentativeId=${this.currentUser.repLocalProvId}`}
      ${this.getShow ? '' : `&FromDate=${sinceDate}&ToDate=${untilDate}`}
      &NearToClose=${this.getNearToClose ? true : false}`).subscribe(
        {

          next: response => {

            this.records = response
            this.loading = false;

          },
          error: error => {


            this.records = []
            this.loading = false;
          }
        }
      )
    }


    /// INSPECTION PROGRAM
    if (this.selectedHistory() == 'PROGI') {

      let since = this.searchForm.get('sinceDate').value
      let until = this.searchForm.get('untilDate').value
      let sinceDate = moment(`${since['year']}-${since['month']}-${since['day']}`).format().toString()
      let untilDate = moment(`${until['year']}-${until['month']}-${until['day']} 23:59:59`).format().toString()
      this.loading = true;
      this.history = this.http.get<inspectionProgram[]>(`InspectionProgram?Status=true
      ${this.isUserDirector ? `&LocalRepresentativeId=${this.getLocalRep() ? this.getLocalRep() : ''}` : `&LocalRepresentativeId=${this.currentUser.repLocalProvId}`}
      ${this.getShow ? '' : `&FromDate=${sinceDate}&ToDate=${untilDate}`}
      &NearToClose=${this.getNearToClose ? true : false}`)
        .subscribe(
          {

            next: response => {

              this.records = response
              this.loading = false;

            },
            error: error => {


              this.records = []
              this.loading = false;
            }
          }
        )
    }


    /// USER SERVICE
    if (this.selectedHistory() == 'SERVU') {

      let since = this.searchForm.get('sinceDate').value
      let until = this.searchForm.get('untilDate').value
      let sinceDate = moment(`${since['year']}-${since['month']}-${since['day']}`).format().toString()
      let untilDate = moment(`${until['year']}-${until['month']}-${until['day']} 23:59:59`).format().toString()
      this.loading = true;
      this.history = this.http.get<serviceUser[]>(`UserService/UserServices?Status=true
      ${this.isUserDirector ? `&LocalRepresentativeId=${this.getLocalRep() ? this.getLocalRep() : ''}` : `&LocalRepresentativeId=${this.currentUser.repLocalProvId}`}
      ${this.getShow ? '' : `&FromDate=${sinceDate}&ToDate=${untilDate}`}`)
        .subscribe(
          {

            next: response => {

              this.records = response
              this.loading = false;

            },
            error: () => {


              this.records = []
              this.loading = false;
            }
          }
        )
    }


    ///INSPECTION REPORT
    if (this.selectedHistory() == 'INFIN') {

      let since = this.searchForm.get('sinceDate').value
      let until = this.searchForm.get('untilDate').value
      let sinceDate = moment(`${since['year']}-${since['month']}-${since['day']}`).format().toString()
      let untilDate = moment(`${until['year']}-${until['month']}-${until['day']} 23:59:59`).format().toString()
      this.loading = true;
      this.history = this.http.get<serviceUser[]>(`InspectionReport?Status=true
      ${this.isUserDirector ? `&LocalRepresentativeId=${this.getLocalRep() ? this.getLocalRep() : ''}` : `&LocalRepresentativeId=${this.currentUser.repLocalProvId}`}
      ${this.getShow ? '' : `&FromDate=${sinceDate}&ToDate=${untilDate}`}`)
        .subscribe(
          {

            next: response => {

              this.records = response
              this.loading = false;

            },
            error: error => {


              this.records = []
              this.loading = false;
            }
          }
        )
    }

  }
  changeLocalRep() {
    this.changeTable(localStorage.getItem('typeHistory'));

  }
  changeTable(id) {
    this.table.resetTable();
    localStorage.setItem('typeHistory', id);
    this.searchForm.patchValue({
      'sinceDate': this.dateToObject(moment().subtract(1, 'M').set('date', 1).format().toString()),
      untilDate: this.dateToObject(moment().format().toString()),
      typeHistory: id,
      almostClose: false,
      show: false
    })
    if (localStorage.getItem('typeHistory')) {
      let query;
      let alternateField = localStorage.getItem('typeHistory')
      switch (alternateField) {
        case "ORDSE":
          if (this.advanceFilterState() && this.searchForm.get('advancedFilter').value) { query = this.tool.getQueryFromLS('orderFilter') }
          this.getServiceOrder(query)
          break;

        case "PROGI":
          if (this.advanceFilterState() && this.searchForm.get('advancedFilter').value) { query = this.tool.getQueryFromLS('programFilter') }
          this.getInspectionPrograms(query)
          break;
        case "SERVU":

          if (this.advanceFilterState() && this.searchForm.get('advancedFilter').value) { query = this.tool.getQueryFromLS('serviceFilter') }
          this.getUserServices(query)
          break;
        case "INFIN":

          if (this.advanceFilterState() && this.searchForm.get('advancedFilter').value) {
            query = this.tool.getQueryFromLS('inspectionFilter')
          }
          this.getInspectionReports(query)
          break;
      }
    }
  }

  advancedFilter() {
    const active = this.searchForm.get('advancedFilter').value
    switch (this.searchForm.get('typeHistory').value) {
      case "ORDSE":
        const orderFilterModal: NgbModalRef = this.ngBModal.open(
          AdvancedFilterComponent, { size: 'xl' });
        orderFilterModal.componentInstance.isActive = this.advanceFilterState()
        orderFilterModal.result.then(query => {
          if (query && active) {
            this.getServiceOrder(query)
          }
        })

        break;

      case "PROGI":
        const programFilterModal: NgbModalRef = this.ngBModal.open(
          AdvancedProgramFilterComponent, { size: 'xl' });
        programFilterModal.componentInstance.isActive = this.advanceFilterState()
        programFilterModal.result.then(query => {
          if (query && active) {
            this.getInspectionPrograms(query)
          }
        })

        break;
      case "SERVU":
        const serviceFilterModal: NgbModalRef = this.ngBModal.open(
          AdvancedUserServiceFilterComponent, { size: 'xl' });
        serviceFilterModal.componentInstance.isActive = this.advanceFilterState()
        serviceFilterModal.result.then(query => {
          if (query && active) { this.getUserServices(query) }
        })
        break;
      case "INFIN":
        const inspectionFilterModal: NgbModalRef = this.ngBModal.open(
          AdvancedInspectionFilterComponent, { size: 'xl' });
        inspectionFilterModal.componentInstance.isActive = this.advanceFilterState()
        inspectionFilterModal.result.then(query => {
          if (query && active) { this.getInspectionReports(query) }
        })
        break;
    }
  }

}

