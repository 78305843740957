import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { ButtonModel } from 'src/app/modules/shared/_elements/element-ui/table/models/button-model';
import { TableConfigView } from 'src/app/modules/shared/_elements/element-ui/table/models/table-config-view';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import Swal from 'sweetalert2';
import { serviceOrder } from '../../../serviceOrderForm/_models/serviceOrder';
import { visitData } from '../../../serviceOrderForm/_models/visitData';
import { VisitDataComponent } from '../../../serviceOrderForm/visit-data/visit-data.component';
import { visitDataService } from '../../../serviceOrderForm/visit-data/visitData.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-visit-history',
  templateUrl: './visit-history.component.html',
  styleUrls: ['./visit-history.component.css']
})
export class VisitHistoryComponent implements OnInit {
  @Input() serviceOrder: serviceOrder;
  @Output() empty = new EventEmitter<boolean>()
  @Input() canEdit: boolean;
  amount = 0;
  reLoad: Function;
  history: Subscription
  headers: TableConfigView[];
  records: visitData[];
  cols: any[];
  filters: any[];
  loading: boolean = true;
  InitialFormValue: FormGroup;
  subs: Subscription;
  maxDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() }
  customButtons: ButtonModel[];
  constructor(private http: HttpClientService, private route: Router, private user: UserService,
    private fb: FormBuilder, private visitService: visitDataService, private ngBModal: NgbModal
  ) {

  }

  searchForm: FormGroup;

  ngOnDestroy(): void {

    this.history.unsubscribe()
    this.subs.unsubscribe();
  }

  deleteRecord(record) {
    Swal.fire({
      icon: 'warning',
      title: '¿Está seguro que desea eliminarlo?',
      showDenyButton: true,
      confirmButtonText: `Eliminar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.patch<visitData[]>(record, `VisitData/${record.visitDataId}?registrationStatus=false`).subscribe(
          {
            next: response => {
              this.getRecords()
            },
            error: error => {
            }
          })
      } else if (result.isDenied) {
      }
    });
  }
  ngOnInit(): void {

    this.assignButtons()
    if (!this.canEdit) {
      this.customButtons.pop()
    }
    this.getRecords()
    this.subs = this.visitService.subject.subscribe(data => {
      this.getRecords()

    });

    this.searchForm = this.fb.group({
      sinceDate: new FormControl(this.dateToObject(moment().subtract(1, 'M').set('date', 1).format().toString())),
      untilDate: new FormControl(this.dateToObject(moment().format().toString())),
      show: new FormControl(false)
    })


    this.InitialFormValue = this.searchForm.value;
    this.history = this.http.get<visitData[]>(`VisitData/VisitDataByServiceOrderNumber/${this.serviceOrder.serviceOrderNumber}?status=true`).subscribe(
      {
        next: response => {

          this.records = response
          this.loading = false;

        },
        error: error => {
          this.records = []
          this.loading = true
        }
      }
    )

    this.filters = ["visitDataId", "actionTakenInfo", "registeredByName", "visitDate", "typeOfScheduleInfo"]
    this.cols = [

      {
        field: 'visitDataId',
        view: { table: true, detail: true },
        header: 'NÚMERO VISITA',
        width: 3,
        tooltip: 'NÚMERO VISITA',
        fixedColumn: true,

      },
      {
        field: 'visitDateStr',
        view: { table: true, detail: true },
        header: 'FECHA VISITA',
        width: 3,
        tooltip: 'FECHA VISITA',
        fixedColumn: true,

      },
      {
        field: 'actionTakenInfo',
        view: { table: true, detail: true },
        header: 'ACCIÓN TOMADA',
        width: 3,
        tooltip: 'ACCIÓN TOMADA',
        fixedColumn: true,

      },

      {
        field: 'typeOfScheduleInfo',
        header: 'HORA VISITA',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'HORA VISITA',
        fixedColumn: true,

      },
      {
        field: 'registrationDateStr',
        header: 'FECHA REGISTRO',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'FECHA REGISTRO',
        fixedColumn: true,

      },



    ]
  }

  showAllRecords() {


    this.searchForm.patchValue({
      'show': !this.searchForm.get('show').value
    })

    if (this.searchForm.get('show').value) {

      this.getRecords()
    }
    else {
      this.searchForm.reset(this.InitialFormValue)
      this.getRecords()
    }

  }

  dateToObject(date: string) {
    if (date) {
      let a: any[] = date.slice(0, 10).split('-').map(x => {
        return Number(x)
      })
      return { year: a[0], month: a[1], day: a[2] };
    }
    return null
  }

  getRecords() {

    this.loading = true;
    this.history = this.http.get<visitData[]>(`VisitData/VisitDataByServiceOrderNumber/${this.serviceOrder.serviceOrderNumber}?status=true`).subscribe(
      {

        next: response => {

          this.records = response
          this.loading = false;
          this.amount = response.length

        },
        error: error => {

          this.empty.emit(true);
          this.records = []
          this.loading = false;
        }
      }
    )

  }

  assignButtons() {
    this.customButtons = [
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 me-2",
        function: async (record: serviceOrder) => {
          const ngmodal: NgbModalRef = this.ngBModal.open(
            VisitDataComponent, { size: 'lg' });
          ngmodal.componentInstance.visitData = cloneDeep(record);
          ngmodal.componentInstance.canEdit = this.canEdit
          ngmodal.componentInstance.edit.subscribe(el => {
            let { estados, id } = el

            this.visitService.edition.next({ estados, id })
          })
        }, label: `${this.canEdit ? 'Editar' : 'Consultar'}`
      },
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 tableDeleteButton",
        function: async (record: serviceOrder) => {
          this.deleteRecord(record)
        }, label: "Eliminar"
      }]
  }
}
