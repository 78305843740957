<div class="mb-2" #content id="contentToConvert">
  <div class="row container mt-5 mb-3">
    <p class="title h3 mb-2">
      <strong>¡Hola</strong> {{ userService.getUserData().firstName }}!
    </p>
  </div>
  <div class="row w-90 mb-4">
    <form class="form-inline ps-0">
      <div class="col-lg-2 col-md-6 col-12 mb-sm-2 mb-2" style="height: 46px">
        <div class="form-group hidden">
          <div class="input-group">
            <input
              style="will-change: none; transform: none"
              [hidden]="true"
              name="datepicker"
              class="form-control"
              ngbDatepicker
              #datepicker="ngbDatepicker"
              [autoClose]="'outside'"
              (dateSelect)="onDateSelection($event, datepicker)"
              [displayMonths]="2"
              [dayTemplate]="t"
              outsideDays="hidden"
              [startDate]="fromDate!"
              [maxDate]="maxDate"
              tabindex="-1"
            />
            <ng-template #t let-date let-focused="focused">
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="input-group">
          <input
            #dpFromDate
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="dpFromDate"
            [value]="formatter.format(fromDate)"
            (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
            disabled="true"
          />
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-12 mb-sm-2 mb-2" style="height: 46px">
        <div class="form-group">
          <div class="input-group">
            <input
              #dpToDate
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dpToDate"
              [value]="formatter.format(toDate)"
              (input)="toDate = validateInput(toDate, dpToDate.value)"
              disabled="true"
            />
            <div class="input-group-append">
              <button
                style="border-radius: 0px"
                pButton
                icon="pi pi-calendar"
                class="btn btn-outline-secondary calendar"
                (click)="datepicker.toggle()"
                type="button"
                [disabled]="isloading"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12 mb-sm-2 mb-2">
        <el-dropdown
          [options]="localReps"
          name="representation"
          [(ngModel)]="repLocalCode"
          [stringOut]="true"
          customClass="form-control form-control-lg mainDrop"
          placeHolder="representación"
          valueName="code"
          (onChange)="fetchData()"
          [disabled]="!isSupervisorOrHigher || isloading"
        ></el-dropdown>
        <!-- <div class="col-lg-6 col-12 drops">
          <el-dropdown
            [options]="localReps"
            [(ngModel)]="repLocalId"
            customClass="form-control form-control-lg"
            placeHolder="Exportar"
            customStyle="width:15rem"
          ></el-dropdown>
        </div> -->
      </div>
      <div
        class="
          col-lg-3 col-12
          me-5
          text-lg-left text-md-center text-center
          mb-sm-2
        "
        style="margin-left: -21px"
      >
        <button
          class="me-2"
          pButton
          icon="pi pi-refresh"
          style="border-radius: 0px"
          class="buttonIcon me-2"
          (click)="fetchData()"
          [disabled]="isloading"
        ></button>
        <button
          pButton
          iconPos="left"
          icon="pi pi-file-pdf"
          label="Exportar"
          style="border-radius: 0px"
          class="buttonIcon"
          (click)="exportToPdf()"
          [disabled]="isloading"
        ></button>
      </div>
    </form>
  </div>
  <div *ngIf="!isloading; else loading">
    <div class="row w-90 mb-3">
      <div class="col-lg-4 col-12">
        <pie-chart
          [hasFooter]="false"
          [title]="' Órdenes de Servicio'"
          [totalTitle]="chartData.serviceOrderPie.total"
          [labels]="chartData.serviceOrderPie.labels"
          [data]="chartData.serviceOrderPie.data"
          [colors]="chartData.serviceOrderPie.colors"
        ></pie-chart>
      </div>
      <div class="col-lg-4 col-12">
        <pie-chart
          [hasFooter]="true"
          [title]="' Órdenes de Servicio por Tipo'"
          [totalTitle]="chartData.serviceOrderPieStatus.total"
          [labels]="chartData.serviceOrderPieStatus.labels"
          [data]="chartData.serviceOrderPieStatus.data"
          [colors]="chartData.serviceOrderPieStatus.colors"
          [pieStatus]="pieStatus"
          [fromDate]="tools.objToDate(this.fromDate)"
          [toDate]="tools.objToDate(this.toDate)"
          [repLocalCode]="repLocalCode"
          [totalGeneral]="chartData.serviceOrderPieStatus.total"
        ></pie-chart>
      </div>
      <div class="col-lg-4 col-12">
        <div class="row">
          <div class="col-12" style="margin-bottom: 34px">
            <app-iconchart
              icon="ticket"
              title="Servicios al Usuario"
              [totalTitle]="chartData.totalUserService"
            ></app-iconchart>
          </div>
          <div class="col-12">
            <app-iconchart
              icon="comment"
              [totalTitle]="chartData.totalInfractionReport"
              title="Infracciones"
            ></app-iconchart>
          </div>
        </div>
      </div>
    </div>
    <div class="row w-90 mt-3 justify-space-between">
      <div class="col-lg-4 col-12">
        <donut-chart
          [type]="'doughnut'"
          [title]="'VISITAS PROGRAMADAS'"
          [totalTitle]="'Total Visitas '"
          [labels]="chartData.visitDataDonut.labels"
          [data]="chartData.visitDataDonut.data"
          [total]="chartData.visitDataDonut.total"
          [colors]="chartData.visitDataDonut.colors"
        ></donut-chart>
      </div>
      <div class="col-lg-4 col-12">
        <donut-chart
          [type]="'doughnut'"
          [title]="'ACTAS DE APERCIBIMIENTO'"
          [totalTitle]="'Total Actas Levantadas'"
          [labels]="chartData.warningReportDonut.labels"
          [data]="chartData.warningReportDonut.data"
          [total]="chartData.warningReportDonut.total"
          [colors]="chartData.warningReportDonut.colors"
          [total]="chartData.warningReportDonut.total"
        ></donut-chart>
      </div>

      <div class="col-lg-4 col-12">
        <donut-chart
          [type]="'doughnut'"
          [title]="'INFORME DE INSPECCIÓN'"
          [totalTitle]="'Total Informes'"
          [labels]="chartData.inspectionReportDonut.labels"
          [data]="chartData.inspectionReportDonut.data"
          [total]="chartData.inspectionReportDonut.total"
          [colors]="chartData.inspectionReportDonut.colors"
        ></donut-chart>
      </div>
    </div>
    <div class="row w-90 mt-3">
      <app-barchart
        [title]="' ORDENES DE SERVICIO POR ESTADO'"
        [totalTitle]="chartData.serviceOrderBarStatus.total"
        [labels]="chartData.serviceOrderBarStatus.labels"
        [data]="chartData.serviceOrderBarStatus.data"
        [colors]="chartData.serviceOrderBarStatus.colors"
      ></app-barchart>
    </div>
    <div class="row w-90 mt-3">
      <app-barchart
        [title]="' INFORMES DE INSPECCIÓN POR ESTADO'"
        [totalTitle]="chartData.inspectionReportBarStatus.total"
        [labels]="chartData.inspectionReportBarStatus.labels"
        [data]="chartData.inspectionReportBarStatus.data"
        [colors]="chartData.inspectionReportBarStatus.colors"
      ></app-barchart>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="d-flex align-items-center justify-content-center">
    <img
      class="align-self-center image"
      src="../../../../../assets/icons/icon-152x152.png"
      alt=""
    />
  </div>
</ng-template>
