import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { ButtonModel } from 'src/app/modules/shared/_elements/element-ui/table/models/button-model';
import { TableConfigView } from 'src/app/modules/shared/_elements/element-ui/table/models/table-config-view';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { WarningReportComponent } from '../../../serviceOrderForm/warning-report/warning-report.component';
import { warningReport } from '../../../serviceOrderForm/warning-report/_models/warningReport';
import { serviceOrder } from '../../../serviceOrderForm/_models/serviceOrder';
import { WarningReportService } from '../../../serviceOrderForm/warning-report/warning-report.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { cloneDeep } from 'lodash';
 
@Component({
  selector: 'app-warning-report-history',
  templateUrl: './warning-report-history.component.html',
  styleUrls: ['./warning-report-history.component.css']
})
export class WarningReportHistoryComponent implements OnInit {

  @Input() serviceOrder: serviceOrder;
  @Output() empty = new EventEmitter<boolean>()
  @Input() canEdit: boolean = true;
  @ViewChild('btnEditSaveWarningReport') toggleButton: ElementRef;

  amount = 0;
  reLoad: Function;
  history: Subscription
  headers: TableConfigView[];
  records: warningReport[];
  cols: any[];
  filters: any[];
  loading: boolean = true;
  InitialFormValue: FormGroup;
  subs: Subscription;
  maxDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() }
  @Input() canJustify: boolean = true;

  constructor(private http: HttpClientService, private route: Router, private user: UserService,
    private fb: FormBuilder, private warningData: WarningReportService, private ngBModal: NgbModal
    , private httpservice: HttpClient,private toast: ToastService, private sweet: SweetAlertService, private renderer: Renderer2) {

  }

  searchForm: FormGroup;

  ngOnDestroy(): void {
    this.history.unsubscribe()
    this.subs.unsubscribe();
  }
  customButtons: ButtonModel[];

  deleteRecord(record) {
    Swal.fire({
      icon: 'warning',
      title: '¿Esta seguro que desea eliminarlo?',
      showDenyButton: true,
      confirmButtonText: `Eliminar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.http.patch<warningReport[]>(record, `WarningReport/${record.warningReportId}?registrationStatus=false`).subscribe(
          {
            next: response => {
              this.getRecords()
            },
            error: error => {
            }
          })
      } else if (result.isDenied) {
      }
    });
  }
  ngOnInit(): void {

    this.assignButtons()
    this.getRecords()
    this.subs = this.warningData.subject.subscribe(data => {
      this.getRecords()

    });
    this.onSavedJustClick();

    this.searchForm = this.fb.group({
      sinceDate: new FormControl(this.dateToObject(moment().subtract(1, 'M').set('date', 1).format().toString())),
      untilDate: new FormControl(this.dateToObject(moment().format().toString())),
      show: new FormControl(false)
    })


    this.filters = ["warningReportNumber", "registeredByName", "notifiedName", "recordStatusStr"]
    this.cols = [


      {
        field: 'warningReportNumber',
        view: { table: true, detail: true },
        header: 'NÚMERO ACTA',
        width: 3,
        tooltip: 'NÚMERO ACTA',
        fixedColumn: true,

      },


      {
        field: 'notifiedName',
        header: 'NOMBRE NOTIFICADO',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'NOMBRE NOTIFICADO',
        fixedColumn: true,

      },


      {
        field: 'registeredByName',
        header: 'REGISTRADO POR',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'REGISTRADO POR',
        fixedColumn: true,

      },
      {
        field: 'recordStatusStr',
        view: { table: true, detail: true },
        header: 'ESTATUS',
  
        tooltip: 'ESTATUS',
        fixedColumn: true,
  
      },


    ]
  }

  onSavedJustClick(){
    this.renderer.listen('window', 'click',(e:Event)=>{
     let element:any = e;
     if(element.target.id ==='btnEditSaveWarningReport'){
       setTimeout(() => {this.getRecords() }, 1000);
      
     }
  });  
  }

  dateToObject(date: string) {
    if (date) {
      let a: any[] = date.slice(0, 10).split('-').map(x => {
        return Number(x)
      })
      return { year: a[0], month: a[1], day: a[2] };
    }
    return null
  }

  getRecords() {

    this.loading = true;
    this.history = this.http.get<warningReport[]>(`WarningReport/ServiceOrder/${this.serviceOrder.serviceOrderNumber}`).subscribe(
      {

        next: response => {
          response.forEach(element => {
            
            switch (element.recordStatus) {
              case true:
                element.recordStatusStr = "Activo";
                break;
            
              default:
                element.recordStatusStr = "Eliminado";
                break;
            }
                      
            
          });        
 
          this.records = response
          this.loading = false;
          this.amount = response.length

        },
        error: error => {

          this.empty.emit(true);
          this.records = []
          this.loading = false;
        }
      }
    )

  }

  assignButtons() {
     
    this.customButtons = [
      {
        class: 'p-button-rounded p-button-info css-label-btn-grid px-2 me-2',
        function: async (record: serviceOrder) => {

            const ngmodal: NgbModalRef = this.ngBModal.open(
              WarningReportComponent, { size: 'xl' });
            ngmodal.componentInstance.warningReport = cloneDeep(record);
            ngmodal.componentInstance.canEdit = this.canEdit
            ngmodal.componentInstance.canJustify = false;
        },
        tooltip: `${this.canEdit ? 'Editar' : 'Consultar'}`,
        icon: `${this.canEdit ? 'pi pi-user-edit' : 'pi-search'}` 
        
      },
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 me-2 ",
        function: async (record: warningReport) => {
          if(record.registeredBy !==  this.user.getUserData().userCode){
            this.sweet.record('warning', (record.warningReportNumber  ===""?"Esta acta de apercibimiento": record.warningReportNumber ),`Solo puede ser eliminada por el usuario que la registró.`, ``);
          }else{
            if(!record.recordStatus){
              this.sweet.record('warning', `Esta acta ${record.warningReportNumber}`,`Ya ha sido eliminada.`, `Se recomienda registrar una nueva en caso de ser necesario.`);
            }else{
              const ngmodal: NgbModalRef = this.ngBModal.open(
                WarningReportComponent, { size: 'lg' });
              ngmodal.componentInstance.warningReport = cloneDeep(record);
              ngmodal.componentInstance.canEdit = this.canEdit
              ngmodal.componentInstance.canJustify = this.canJustify;
            }            

          }


        },
        tooltip: 'Eliminar Acta de Apercibimiento',
        icon: 'pi pi pi-trash'
      },
      
 


    ]
  }

}
