import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { user } from 'src/app/modules/auth/_models/User';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { chartsData } from '../../components/models/chartData';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.css'],

  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class DashboardLayoutComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
  constructor(public userService: UserService, private http: HttpClientService, private FB: FormBuilder, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, public tools: ToolsService, private userS: UserService) {
    this.currentUser = this.userService.getUserData() as user
    this.fromDate = calendar.getPrev(calendar.getToday(), 'm', 1)
    this.toDate = calendar.getToday();

  }
  maxDate = { year: this.calendar.getToday().year, month: this.calendar.getToday().month, day: this.calendar.getToday().day }
  localReps: any[] = []
  repLocalCode: number = null
  export: number = null
  isloading: boolean = true
  user = this.userS.getUserData() as user
  chartData: chartsData;
  pieStatus: any[];
  currentUser: user;
  isSupervisorOrHigher: boolean;
  async ngOnInit() {

    this.checkRole()
    this.localReps = await this.http.get<any[]>(`LocalRepresentativeProvince`).toPromise()
    if(this.currentUser.roleCode === 'DIR' ){
      //Agregar al inicio del Array la opcion de todos
      this.localReps.unshift({value: null, text: '000 - Todas las Representaciones', code: '000'});
    }
     
    this.repLocalCode = this.localReps.find(el => el.value == this.user.repLocalProvId).code
    this.fetchData()

  }
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;


  onDateSelection(date: NgbDate, picker: NgbInputDatepicker) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;


      picker.close()
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate && this.toDate) {
      this.fetchData()
    }
  }

  checkRole() {

    if (this.currentUser.roleCode !== 'DIG' && this.currentUser.roleCode !== 'INSP') {

      this.isSupervisorOrHigher = true
    }
    else {
      this.isSupervisorOrHigher = false

    }
  }
  async fetchData() {

    this.isloading = true;
    this.pieStatus = await this.http.get<DropDownOptionModel[]>('TypeOfService').toPromise()
    let custProviciaCode = (this.repLocalCode.toString() !=="000"? `&ProvinceCode=${this.repLocalCode}`:'')
    this.chartData = await this.http.get<chartsData>(`DashBoard?FromDate=${this.tools.objToDate(this.fromDate)}&ToDate=${this.tools.objToDate(this.toDate)}${custProviciaCode}`).toPromise()

    this.isloading = false;
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  exportToPdf() {
    let data = document.getElementById('contentToConvert');
    this.tools.openPDF(data)
  }
}
