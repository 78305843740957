<div *ngIf="!isFilter; else isTheFilter">
  <div class="modal-content" [ngClass]="{ cantEditCols: !canEdit }">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="canEdit ? close(false) : close(true)"
      ></button>
    </div>
    <div class="modal-body">
      <div class="centered-content">
        <form [formGroup]="visitDataForm" [ngClass]="{ cantEdit: !canEdit }">
          <div class="form-row">
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg"
                >Fecha Visita
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="visitDataForm.get('visitDate').invalid"
                  >*</small
                ></label
              >
              <div class="input-group">
                <input
                  class="form-control form-control-lg"
                  placeholder="Fecha Visita"
                  name="dp"
                  formControlName="visitDate"
                  ngbDatepicker
                  [maxDate]="maxDate"
                  [minDate]="minDate"
                  #d="ngbDatepicker"
                  maxlength="10"
                  [appMaskValue]="visitDataForm.get('visitDate').value"
                  dateMask
                  (ngModelChange)="
                    this.tool.dateVerification(
                      visitDataForm,
                      'visitDate',
                      $event
                    )
                  "
                  (dateSelect)="refreshDateValidators()"
                />
                <div class="input-group-append">
                  <button
                    pButton
                    icon="pi pi-calendar"
                    class="btn btn-outline-secondary btn-lg"
                    (click)="d.toggle()"
                    type="button"
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg"
                >Tipo Horario
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="visitDataForm.get('typeOfScheduleId').invalid"
                  >*</small
                ></label
              >
              <el-dropdown
                [options]="TypeOfSchedule"
                formControlName="typeOfScheduleId"
                customClass="form-control form-control-lg"
                placeHolder="Elige un tipo de horario"
              ></el-dropdown>
            </div>
            <div class="col-md col-6 mb-1" *ngIf="visitData">
              <label class="form-label form-control-lg"
                >Acción Tomada
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="visitDataForm.get('actionTakenId').invalid"
                  >*</small
                ></label
              >
              <el-dropdown
                [options]="TypeOfActionTaken"
                formControlName="actionTakenId"
                customClass="form-control form-control-lg"
                (onChange)="actionDescription($event)"
                placeHolder="Elige una acción tomada"
              ></el-dropdown>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1" *ngIf="actionType">
              <label class="form-label form-control-lg">
                Acción
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="visitDataForm.get('actionTakenOthers').invalid"
                  >*</small
                ></label
              >
              <textarea
                specialIsAlphaNumeric
                [fControl]="visitDataForm.get('actionTakenOthers')"
                placeholder="Acción"
                type="text"
                class="form-control form-control-lg mb-3"
                formControlName="actionTakenOthers"
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">
                Detalle de Visita
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="visitDataForm.get('finishedVisitDetail').invalid"
                  >*</small
                ></label
              >
              <textarea
                specialIsAlphaNumeric
                [fControl]="visitDataForm.get('finishedVisitDetail')"
                type="text"
                placeholder="Detalle de visita"
                class="form-control form-control-lg mb-3"
                formControlName="finishedVisitDetail"
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-6 mb-1" *ngIf="visitData">
              <label class="form-label form-control-lg mb-5"
                >Hora Visita
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="visitDataForm.get('visitDate').invalid"
                  >*</small
                ></label
              >
              <ngb-timepicker
                formControlName="scheduleVisit"
                [minuteStep]="1"
                size="large"
                class="mx-auto"
                [meridian]="meridian"
              ></ngb-timepicker>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer" *ngIf="canEdit">
        <button
          type="button"
          class="rounded-pill btn btn-save px-5"
          data-bs-dismiss="modal"
          (click)="close(false)"
        >
          Cerrar
        </button>
        <button
          type="button"
          class="rounded-pill btn btn-save px-5"
          data-bs-dismiss="modal"
          (click)="saveVisitData()"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #isTheFilter>
  <div class="centered-content">
    <form [formGroup]="visitDataForm">
      <div class="form-row">
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg">Fecha Visita </label>
          <div class="input-group">
            <input
              class="form-control form-control-lg"
              placeholder="Fecha Visita"
              name="dp"
              formControlName="visitDate"
              ngbDatepicker
              [maxDate]="maxDate"
              [minDate]="minDate"
              #d="ngbDatepicker"
              maxlength="10"
              [appMaskValue]="visitDataForm.get('visitDate').value"
              dateMask
              (ngModelChange)="
                this.tool.dateVerification(visitDataForm, 'visitDate', $event)
              "
            />
            <div class="input-group-append">
              <button
                pButton
                icon="pi pi-calendar"
                class="btn btn-outline-secondary btn-lg"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
        <div class="col-md col-6 mb-1">
          <label class="form-label form-control-lg">Tipo Horario </label>
          <el-dropdown
            [options]="TypeOfSchedule"
            formControlName="typeOfScheduleId"
            customClass="form-control form-control-lg"
            placeHolder="Elige un tipo de horario"
          ></el-dropdown>
        </div>
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg">Acción Tomada </label>
          <el-dropdown
            [options]="TypeOfActionTaken"
            formControlName="actionTakenId"
            customClass="form-control form-control-lg"
            placeHolder="Elige una acción tomada"
          ></el-dropdown>
        </div>
      </div>
    </form>
  </div>
</ng-template>
