import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropDownOptionModel, dependency } from '../../element-ui/dropdown/models/dropdown-option-model';
import { ButtonModel } from '../table/models/button-model';


@Component({
  selector: 'el-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi: true
    }],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class DropdownComponent implements ControlValueAccessor {
  // onTouch: any = () => { }
  private onTouched = () => { };
  // private onChanged = (_: any) => { };

  constructor(private changeDetector: ChangeDetectorRef) {
    // this.control.valueAccessor = this;
  }


  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetector.markForCheck();
  }

  @Input() disabled: boolean = false;
  @Input() dependency: dependency
  @Input() customClass: string;
  @Input() options: Array<DropDownOptionModel>;
  @Input() objectOptions: Array<any>
  @Output() onChange = new EventEmitter<boolean | string>()
  _selectedId = null;
  _selectedText = null
  @Input() button: ButtonModel;
  @Input() class
  @Input() stringOut: boolean = false;
  @Input() placeHolder: string;
  @Input() valueName: string;
  @Input() textName: string;
  @Input() customStyle: StyleSheet;
  @Input() filterable: boolean = true;

  writeValue(value: any): void {

    this._selectedId = value;
    this.changeDetector.markForCheck();
    // console.log('writeValue', value);
  }

  // getDependencyOptions() {
  //   if (this.dependency) {
  //     this.http
  //   }
  // }

  get counterValue() {

    return this._selectedId;
  }

  changeValue(val) {
    // console.log(val, 'changeValue');
    val = val === 'true'
      ? true : val === 'false'
        ? false : val;

    if (typeof val === 'boolean') {
      this.propagateChange(val)
    }

    else
      if (this.stringOut) {
        this._selectedId = val;

        this.propagateChange(this._selectedId);
      }
      else {
        this._selectedId = +val;

        this.propagateChange(this._selectedId);
      }

    this.onChange.emit(val)
  }


  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  ngOnChanges(): void {
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn
  }
  // ngAfterViewInit(): void {
  //   //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //   //Add 'implements AfterViewInit' to the class.
  //   // console.log(this.options);
  // }


}
