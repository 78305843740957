import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PickListModule } from 'primeng/picklist';
import { TabViewModule } from 'primeng/tabview';
import { SharedModule } from '../shared/shared.module';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin.routing';
import { RoleManagementComponent } from './role-management/role-management.component';
import { UserHistoryEditComponent } from './user-history-edit/user-history-edit.component';
import { UserHistoryComponent } from './user-history/user-history.component';


// module
import { ReactiveFormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';


@NgModule({
  declarations: [
    AdminComponent,
    UserHistoryComponent,
    RoleManagementComponent,
    UserHistoryEditComponent
  ],
  imports: [
    CommonModule,
    PickListModule,
    FormsModule,
    AdminRoutingModule,
    SharedModule,
    TabViewModule,
    SelectButtonModule,
    ReactiveFormsModule,
  ]
})
export class AdminModule { }
