import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { InspectionProgramComponent } from '../../inspection-program/inspection-program.component';
import { inspectionProgram } from '../../inspection-program/_models/inspectionProgram';
import { ProgramFilter } from './programFilter';

@Component({
  selector: 'app-advanced-program-filter',
  templateUrl: './advanced-program-filter.component.html',
  styleUrls: ['./advanced-program-filter.component.css']
})
export class AdvancedProgramFilterComponent implements OnInit {

  constructor(public ngbActiveModal: NgbActiveModal, private tool: ToolsService) { }
  @Input() isActive: boolean;
  ngOnInit(): void {
  }

  buildSearch(form: inspectionProgram) {


    const program: ProgramFilter = {
      programTypeId: form.programTypeId,
      programName: form.programName,
      classificationTypeId: form.classificationTypeId,
      realStartDate: form.realStartDate,
      realEndDate: form.realStartDate
    }

    const filter = {
      form: program,
      active: this.isActive
    }
    this.tool.setFilterLS('programFilter', JSON.stringify(filter))
    this.tool.buildSearch(program, this.ngbActiveModal)
  }

  clean(inspection: InspectionProgramComponent) {
    this.tool.cleanFilterLS('programFilter')
    //inspection.clean();
  }
}
