<div class="card" style="width: 100%; height: 246px">
  <div class="card-header">{{ title }}</div>
  <div class="card-body" style="height: 8rem">
    <canvas
      *ngIf="data; else noData"
      baseChart
      #chart
      [chartType]="type"
      [data]="data"
      [labels]="labels"
      [options]="chartOptions"
      [colors]="chartColors"
    >
    </canvas>
    <ng-template #noData>
      <div class="text-center m-auto" style="margin-top: 2rem !important">
        <i class="pi pi-eye-slash noData-icon"></i>
        <h6 class="title">sin información</h6>
      </div>
    </ng-template>
  </div>
  <div class="footer text-center card-footer">
    <p style="margin: 0">
      {{ totalTitle }} <strong>{{ total }}</strong>
    </p>
  </div>
</div>
