import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { components } from './components';
import { TruncateTextPipe } from './_pipes/truncate-text.pipe';
import { SweetAlertService } from './_services/sweetAlert/sweet-alert.service';
import { SafePipe } from './_pipes/safeUrl.pipe';
import { } from 'primeng/dropdown';
import { ToolsService } from './tools/tools.service';
import { modules } from './modules';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [components, TruncateTextPipe, SafePipe],
  imports: [
    ...modules,
    ProgressSpinnerModule
  ], schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe, SweetAlertService, ToolsService],
  exports: [components, SafePipe]
})
export class SharedModule { }
