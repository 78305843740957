<div *ngIf="!isFilter; else isTheFilter">
  <div [ngClass]="{ cantEditCols: !canEdit }" class="pr-3">
    <div class="row" [ngClass]="{ cantEdit: !canEdit }">
      <form [formGroup]="inspectionProgramForm" class="pl-sm-0">
        <div class="form-row">
          <div class="col-md-4 col-lg-4 col-12 mb-1">
            <label class="form-label form-control-lg"
              >Nombre Programa
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="inspectionProgramForm?.get('programName').invalid"
                >*
              </small>
            </label>

            <input
              specialIsAlphaNumeric
              [fControl]="inspectionProgramForm?.get('programName')"
              type="text"
              maxlength="35"
              class="form-control form-control-lg"
              formControlName="programName"
            />
          </div>
          <div
            class="col-md-4 col-lg-4 col-12 mb-1"
            *ngIf="inspectionProgram && !this.isFilter"
          >
            <label class="form-label form-control-lg">No. Programa </label>

            <input
              placeholder="Numero de programa"
              type="text"
              class="form-control form-control-lg"
              formControlName="inspectionProgramNumber"
              readonly
            />
          </div>

          <div class="col-md-4 col-lg-4 col-12 mb-1">
            <label class="form-label form-control-lg"
              >Tipo Programa
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="inspectionProgramForm.get('programTypeId').invalid"
                >*</small
              >
            </label>

            <el-dropdown
              [disabled]="!isUserDirector || inspectionProgram"
              [options]="programTypes"
              formControlName="programTypeId"
              customClass="form-control form-control-lg"
              placeHolder="Elige un tipo de programa"
            ></el-dropdown>
          </div>
          <div class="col-md col-6 mb-1" *ngIf="inspectionProgram">
            <label class="form-label form-control-lg"
              >Fecha Creación
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="inspectionProgramForm.get('registrationDate').invalid"
                >*
              </small>
            </label>
            <div class="input-group">
              <input
                class="form-control form-control-lg"
                placeholder="Fecha Creación"
                name="dp"
                formControlName="registrationDate"
                ngbDatepicker
                #d="ngbDatepicker"
                maxlength="10"
                [appMaskValue]="
                  inspectionProgramForm.get('registrationDate').value
                "
                [markDisabled]="tool.isDisabled"
                dateMask
                (ngModelChange)="
                  this.tool.dateVerification(
                    inspectionProgramForm,
                    'registrationDate',
                    $event
                  )
                "
                (dateSelect)="refreshMin()"
                disabled="true"
              />
              <div class="input-group-append">
                <button
                  pButton
                  icon="pi pi-calendar"
                  class="btn btn-outline-secondary btn-lg"
                  (click)="d.toggle()"
                  type="button"
                  [disabled]="true"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-12 mb-1">
            <label class="form-label form-control-lg"
              >Clasificación
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="
                  inspectionProgramForm.get('classificationTypeId').invalid
                "
                >*
              </small>
            </label>

            <el-dropdown
              [options]="clasificationTypes"
              formControlName="classificationTypeId"
              customClass="form-control form-control-lg"
              [disabled]="
                (!isUserDirector || this.inspectionProgram) && !isFilter
              "
              placeHolder="Elige un tipo de clasificación"
            ></el-dropdown>
          </div>
        </div>
        <div class="form-row" *ngIf="!this.isFilter">
          <div class="col-md-3 col-lg-3 col-12 mb-1">
            <label class="form-label form-control-lg"
              >Representación&nbsp;Local&nbsp;
              <small
                [@warning]="tool.stateName(show)"
                [@warning]="tool.stateName(show)"
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="
                  inspectionProgramForm.get('localRepresentativeProvinceId')
                    .invalid
                "
                >*</small
              >
            </label>

            <el-dropdown
              [options]="localReps"
              formControlName="localRepresentativeProvinceId"
              customClass="form-control form-control-lg"
              [disabled]="!isUserDirector && !this.isFilter"
              placeHolder="Elige una representación local"
            ></el-dropdown>
          </div>
          <div class="col-md-3 col-lg-3 col-12 mb-1">
            <label class="form-label form-control-lg"
              >Fecha Inicio
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="inspectionProgramForm.get('estimatedStartDate').invalid"
                >*</small
              >
            </label>
            <div class="input-group">
              <input
                class="form-control form-control-lg"
                placeholder="Fecha Inicio"
                name="dp"
                formControlName="estimatedStartDate"
                ngbDatepicker
                [minDate]="minDate"
                #d="ngbDatepicker"
                maxlength="10"
                [appMaskValue]="
                  inspectionProgramForm.get('estimatedStartDate').value
                "
                [markDisabled]="tool.isDisabled"
                dateMask
                (ngModelChange)="
                  this.tool.dateVerification(
                    inspectionProgramForm,
                    'estimatedStartDate',
                    $event
                  );
                  changeMinDate($event)
                "
                [disabled]="inspectionProgram && !isFilter"
                (dateSelect)="changeMinDate($event); refreshMin()"
              />
              <div class="input-group-append">
                <button
                  pButton
                  icon="pi pi-calendar"
                  class="btn btn-outline-secondary btn-lg"
                  (click)="d.toggle()"
                  type="button"
                  [disabled]="inspectionProgram && !isFilter"
                ></button>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-lg-3 col-12 mb-1">
            <label class="form-label form-control-lg"
              >Fecha Fin
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="inspectionProgramForm.get('estimatedEndDate').invalid"
                >*
              </small>
            </label>
            <div class="input-group">
              <input
                class="form-control form-control-lg"
                placeholder="Fecha Fin"
                name="dp2"
                formControlName="estimatedEndDate"
                ngbDatepicker
                [minDate]="MinEstimatedEndDate"
                #d2="ngbDatepicker"
                [readonly]="
                  inspectionProgramForm.get('estimatedStartDate').invalid
                "
                [disabled]="
                  inspectionProgramForm.get('estimatedStartDate').invalid
                "
                maxlength="10"
                [appMaskValue]="
                  inspectionProgramForm.get('estimatedEndDate').value
                "
                [markDisabled]="tool.isDisabled"
                dateMask
                (ngModelChange)="
                  this.tool.dateVerification(
                    inspectionProgramForm,
                    'estimatedEndDate',
                    $event
                  )
                "
                (dateSelect)="changeMinDate($event); refreshMin()"
              />
              <div class="input-group-append">
                <button
                  pButton
                  icon="pi pi-calendar"
                  class="btn btn-outline-secondary btn-lg"
                  (click)="d2.toggle()"
                  type="button"
                  [disabled]="
                    inspectionProgramForm.get('estimatedStartDate').invalid &&
                    !isFilter
                  "
                ></button>
              </div>
            </div>
          </div>

          <div class="col-md-3 col-lg-3 col-12 mb-1">
            <label class="form-label form-control-lg"
              >Estatus
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="
                  inspectionProgramForm.get('statusInspectionProgramId').invalid
                "
                >*</small
              >
            </label>

            <el-dropdown
              [options]="StatusPrograms"
              formControlName="statusInspectionProgramId"
              customClass="form-control form-control-lg"
              [disabled]="!isUserDirector"
              placeHolder="Elige un estatus"
            ></el-dropdown>
          </div>
        </div>

        <div class="form-row" *ngIf="!this.isFilter">
          <div class="col-md col-12 mb-1">
            <label class="form-label form-control-lg">
              Objetivo Programa
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="inspectionProgramForm.get('programObjective').invalid"
                >*
              </small>
            </label>
            <textarea
              specialIsAlphaNumeric
              [fControl]="inspectionProgramForm?.get('programObjective')"
              placeholder="Objetivo de programa"
              type="text"
              class="form-control form-control-lg mb-3"
              formControlName="programObjective"
            ></textarea>
          </div>
          <div class="col-md col-12 mb-1">
            <label class="form-label form-control-lg">
              Limite Geográfico
              <small
                [@warning]="tool.stateName(show)"
                class="text-danger"
                *ngIf="inspectionProgramForm.get('geographicLimit').invalid"
                >*
              </small>
            </label>
            <textarea
              specialIsAlphaNumeric
              [fControl]="inspectionProgramForm?.get('geographicLimit')"
              type="text"
              placeholder="Limite Geográfico"
              class="form-control form-control-lg mb-3"
              formControlName="geographicLimit"
            ></textarea>
          </div>
        </div>
        <div>
          <div class="form-row">
            <div class="col-md col-lg col-12 mb-1" *ngIf="!this.isFilter">
              <label class="form-label form-control-lg"
                >Aprobado&nbsp;
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="
                    inspectionProgramForm.get('approvedInspection').invalid
                  "
                  >*</small
                >
              </label>
              <el-dropdown
                [options]="options"
                formControlName="approvedInspection"
                customClass="form-control form-control-lg"
                [disabled]="
                  (!isUserDirector ||
                    this.inspectionProgram?.approvedInspection !== null) &&
                  !isFilter
                "
                (onChange)="validateBy($event)"
                placeHolder="Elige si o no"
              ></el-dropdown>
            </div>
            <div
              class="col-md col-lg col-12"
              *ngIf="
                inspectionProgramForm.get('approvedInspection').valid &&
                inspectionProgram
              "
            >
              <label class="form-label form-control-lg"
                >Aprobado&nbsp;Por</label
              >

              <input
                type="text"
                placeholder="Aprobado por"
                maxlength="35"
                class="form-control form-control-lg"
                [value]="inspectionProgram.validatedByName"
                readonly
              />
            </div>
            <div class="col-md col-lg col-12 mb-1">
              <label class="form-label form-control-lg"
                >Fecha&nbsp;Inicio&nbsp;Real
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="inspectionProgramForm.get('realStartDate').invalid"
                  >*</small
                ></label
              >
              <div class="input-group">
                <input
                  class="form-control form-control-lg"
                  placeholder="Fecha Inicio Real"
                  name="d2"
                  formControlName="realStartDate"
                  [minDate]="minDate"
                  ngbDatepicker
                  #realStartDate="ngbDatepicker"
                  [readonly]="
                    !inspectionProgramForm.get('approvedInspection').value ||
                    isUserDirector
                  "
                  [disabled]="
                    !inspectionProgramForm.get('approvedInspection').value ||
                    isUserDirector
                  "
                  maxlength="10"
                  [appMaskValue]="
                    inspectionProgramForm.get('estimatedStartDate').value
                  "
                  [markDisabled]="tool.isDisabled"
                  dateMask
                  (ngModelChange)="
                    this.tool.dateVerification(
                      inspectionProgramForm,
                      'estimatedStartDate',
                      $event
                    )
                  "
                  (dateSelect)="changeMinFinalDate($event); refreshMin()"
                />
                <div class="input-group-append">
                  <button
                    pButton
                    icon="pi pi-calendar"
                    class="btn btn-outline-secondary btn-lg"
                    (click)="realStartDate.toggle()"
                    type="button"
                    [disabled]="
                      (!inspectionProgramForm.get('approvedInspection').value ||
                        isUserDirector) &&
                      !isFilter
                    "
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-md col-lg col-12 mb-1">
              <label class="form-label form-control-lg"
                >Fecha&nbsp;Fin&nbsp;Real
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="inspectionProgramForm.get('realEndDate').invalid"
                  >*</small
                ></label
              >
              <div class="input-group">
                <input
                  class="form-control form-control-lg"
                  placeholder="Fecha Fin Real"
                  name="dp3"
                  formControlName="realEndDate"
                  ngbDatepicker
                  #d3="ngbDatepicker"
                  [minDate]="MinEndDate"
                  [readonly]="
                    !inspectionProgramForm.get('realStartDate').value ||
                    isUserDirector
                  "
                  [disabled]="
                    !inspectionProgramForm.get('realStartDate').value ||
                    isUserDirector
                  "
                  maxlength="10"
                  [appMaskValue]="
                    inspectionProgramForm.get('realEndDate').value
                  "
                  [markDisabled]="tool.isDisabled"
                  dateMask
                  (ngModelChange)="
                    this.tool.dateVerification(
                      inspectionProgramForm,
                      'realEndDate',
                      $event
                    )
                  "
                  (dateSelect)="changeMinFinalDate($event); refreshMin()"
                />
                <div class="input-group-append">
                  <button
                    pButton
                    icon="pi pi-calendar"
                    class="btn btn-outline-secondary btn-lg"
                    (click)="d3.toggle()"
                    type="button"
                    [disabled]="
                      (!inspectionProgramForm.get('realStartDate').value ||
                        isUserDirector) &&
                      !isFilter
                    "
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row" *ngIf="!this.isFilter">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">
                Comentario de Aprobación
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="inspectionProgramForm.get('validationComment').invalid"
                  >*</small
                ></label
              >
              <textarea
                specialIsAlphaNumeric
                [fControl]="inspectionProgramForm?.get('validationComment')"
                type="text"
                placeholder="Comentario de aprobación"
                class="form-control form-control-lg mb-3"
                formControlName="validationComment"
 
              ></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- <div class="btn-group float-right m-3" *ngIf="!this.isFilter">
      <button
        *ngIf="canEdit"
        type="button"
        class="rounded-pill btn btn-save px-5"
        (click)="saveRecord()"
        [disabled]="inspectionProgram ? checkAproved() : false"
      >
        Guardar
      </button>
    </div> -->
  </div>
</div>

<ng-template #isTheFilter>
  <div class="container-fluid" [ngClass]="{ cantEditCols: !canEdit }">
    <div class="row" [ngClass]="{ cantEdit: !canEdit }">
      <form [formGroup]="inspectionProgramForm">
        <div class="form-row">
          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg">Nombre Programa </label>

            <input
              specialIsAlphaNumeric
              [fControl]="inspectionProgramForm?.get('programName')"
              type="text"
              maxlength="35"
              class="form-control form-control-lg"
              formControlName="programName"
            />
          </div>

          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg">Tipo Programa </label>

            <el-dropdown
              [options]="programTypes"
              formControlName="programTypeId"
              customClass="form-control form-control-lg"
              placeHolder="Elige un tipo de programa"
            ></el-dropdown>
          </div>

          <div class="col-md col-6 mb-1">
            <label class="form-label form-control-lg">Clasificación </label>

            <el-dropdown
              [options]="clasificationTypes"
              formControlName="classificationTypeId"
              customClass="form-control form-control-lg"
              placeHolder="Elige un tipo de clasificación"
            ></el-dropdown>
          </div>
        </div>

        <div>
          <div class="form-row">
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg"
                >Fecha&nbsp;Inicio&nbsp;Real
              </label>
              <div class="input-group">
                <input
                  class="form-control form-control-lg"
                  placeholder="Fecha Inicio Real"
                  name="d2"
                  formControlName="realStartDate"
                  [minDate]="minDate"
                  ngbDatepicker
                  #realStartDate="ngbDatepicker"
                  readonly
                  (click)="realStartDate.toggle()"
                />
                <div class="input-group-append">
                  <button
                    pButton
                    icon="pi pi-calendar"
                    class="btn btn-outline-secondary btn-lg"
                    (click)="realStartDate.toggle()"
                    type="button"
                  ></button>
                </div>
              </div>
            </div>
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg"
                >Fecha&nbsp;Fin&nbsp;Real
                <small
                  [@warning]="tool.stateName(show)"
                  class="text-danger"
                  *ngIf="inspectionProgramForm.get('realEndDate').invalid"
                  >*</small
                ></label
              >
              <div class="input-group">
                <input
                  class="form-control form-control-lg"
                  placeholder="Fecha Fin Real"
                  name="dp3"
                  formControlName="realEndDate"
                  ngbDatepicker
                  #d3="ngbDatepicker"
                  [minDate]="MinEndDate"
                  readonly
                  (click)="d3.toggle()"
                />
                <div class="input-group-append">
                  <button
                    pButton
                    icon="pi pi-calendar"
                    class="btn btn-outline-secondary btn-lg"
                    (click)="d3.toggle()"
                    type="button"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
