import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbCalendar, NgbDateParserFormatter, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import Swal from 'sweetalert2';
import { AdvanceFilterModel } from '../../service/advance-Filters/advanced-filter/AdvanceFilterModel';
import { serviceOrder } from '../_models/serviceOrder';
import { workerData } from '../_models/workerData';
import { OrderWorkerNewComponent } from '../order-worker-new/order-worker-new.component';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-order-worker',
  templateUrl: './order-worker.component.html',
  styleUrls: ['./order-worker.component.css'],

})
export class OrderWorkerComponent implements OnInit {
  constructor(private calendar: NgbCalendar, private fb: FormBuilder,
    private HttpService: HttpClientService, private ngbDateParserFormatter: NgbDateParserFormatter,
    private User: UserService, private tool: ToolsService, private ngBModal: NgbModal, private renderer: Renderer2, private toast: ToastService, private sweet: SweetAlertService
  ) {

  }

  @Input() workerData: workerData;
  companyWorkerId?: number;
  workerDataForm: FormGroup;
  @Input() serviceOrder: serviceOrder;
  @Input() isFilter: boolean = false
  @Output() hasValue = new EventEmitter<boolean>();
  @Input() canEdit: boolean = true;
  @Output() valid = new EventEmitter<boolean>();
  @Output() itChange = new EventEmitter<boolean>();
  @Input() assignmentEdit: boolean = false;
  CurrentUser: UserService;
  @ViewChild('btnSaveWorkerPopulation') toggleButton: ElementRef;
  amount = 0;

  show = false
  orderFilter: AdvanceFilterModel = this.tool.getFilterLS('orderFilter')?.form
  hasRecords: boolean = false;
  records: any[];
  loading: boolean = true;
  customButtons: { class: string; function: (record: workerData) => Promise<void>; tooltip: string; icon: string; }[];
  history: any;
  filters: string[];
  cols: ({ field: string; header: string; view: { table: boolean; detail: boolean; }; tooltip: string; fixedColumn: boolean; color: string; hasMulti?: undefined; multiFilter?: undefined; length?: undefined; style?: undefined; } | { field: string; header: string; view: { table: boolean; detail: boolean; }; tooltip: string; fixedColumn: boolean; color?: undefined; hasMulti?: undefined; multiFilter?: undefined; length?: undefined; style?: undefined; } | { field: string; header: string; view: { table: boolean; detail: boolean; }; tooltip: string; fixedColumn: boolean; hasMulti: any; multiFilter: { options: any; text: string; }; color?: undefined; length?: undefined; style?: undefined; } | { field: string; header: string; view: { table: boolean; detail: boolean; }; length: number; tooltip: string; fixedColumn: boolean; style: string; color?: undefined; hasMulti?: undefined; multiFilter?: undefined; })[];


  async ngOnInit() {

    this.CurrentUser = this.User;
    this.companyWorkerId = this.workerData?.companyWorkerId;
    this.getPopulationWorkers()
    this.onSavedClick();

  }


  onSavedClick() {
    this.renderer.listen('window', 'click', (e: Event) => {
      let element: any = e;
      if (element.target.id === 'btnSaveWorkerPopulation') {
        this.getPopulationWorkers();
      }
    });
  }

  getPopulationWorkers() {

    this.records = [];
    this.loading = true;
    this.customButtons = [
      {
        class: "p-button-rounded p-button-info css-label-btn-grid px-2 me-2",

        function: async (workerUser: workerData) => {

          if (!workerUser.recordStatus) {
            this.sweet.record('warning', (workerUser.workerName === "" ? "Este registro" : workerUser.workerName), `No puede ser editado, ya esta eliminado.`, `Se recomienda crear un registro nuevo.`);
          } else {


            const ngmodal: NgbModalRef = this.ngBModal.open(
              OrderWorkerNewComponent, {
              size: 'xl', backdrop: 'static',
              keyboard: false
            })


            ngmodal.componentInstance.workerData = cloneDeep(workerUser);
            ngmodal.componentInstance.edit.subscribe(output => {
              let { estados, id } = output
              this.getPopulationWorkers();

            });

          }

        },
        tooltip: 'Editar Trabajador',
        icon: 'pi pi-user-edit'

      },
      {
        class: `p-button-rounded p-button-danger css-label-btn-grid px-2 me-2`,
        function: async (workerUser: workerData) => {

          if (workerUser.registeredBy !== this.User.getUserData().userCode) {
            this.sweet.record('warning', (workerUser.workerName === "" ? "Este registro" : workerUser.workerName), `Solo puede ser eliminado por el usuario que lo registró.`, ``);
          } else {
            if (!workerUser.recordStatus) {
              this.sweet.record('warning', (workerUser.workerName === "" ? "Este registro" : workerUser.workerName), `Ya esta eliminado!`, ``);
            } else {
              Swal.fire({
                icon: 'warning',
                title: '¿Esta seguro que desea eliminar este Trabajador?',
                showDenyButton: true,
                confirmButtonText: `Eliminar`,
                denyButtonText: `Cancelar`,
              }).then((result) => {

                if (result.isConfirmed) {

                  this.HttpService.patch<workerData>(workerUser, `ServiceOrderPopulationWorker/${workerUser.companyPopulationWorkerId}?registrationStatus=false`).subscribe(
                    {
                      next: response => {

                        this.sweet.record('success', workerUser.workerName, ` Ha sido eliminado correctamente`, ``);
                        this.getPopulationWorkers();

                      }
                      , error: err => {

                        this.toast.error(err, 'error')

                      }
                    }
                  )
                } else if (result.isDenied) {
                }



              });

            }
          }



        },
        tooltip: 'Eliminar Trabajador',
        icon: 'pi pi pi-trash',

      }

    ];

    if (this.workerData)
      this.history = this.HttpService.get<workerData[]>(`ServiceOrderPopulationWorker/ServiceOrderbyPopulationWorkerId/${this.workerData.companyWorkerId}`).subscribe(
        {
          next: response => {

            response.forEach(element => {
              switch (element.recordStatus) {
                case true:
                  element.recordStatusStr = "Activo"
                  break;

                default:
                  element.recordStatusStr = "Eliminado"
                  break;
              }

            });
            this.records = response;
            this.amount = this.records.length;
            if (this.records.length > 0) this.hasRecords = true;
            this.loading = false;

          },
          error: error => {
            this.records = []
            this.loading = false
            this.hasRecords = false;
          }
        }
      )

    this.filters = ["workerName", "workerAddress", "workerEmail", "workerPhone", "workerExtension", "recordStatusStr"]
    this.cols = [

      {
        field: 'workerName',
        header: 'NOMBRE',
        view: { table: true, detail: true },

        tooltip: 'NOMBRE',
        fixedColumn: true,
        color: "#1460B8",
      },
      {
        field: 'workerAddress',
        view: { table: true, detail: true },
        header: 'DOMICILIO',

        tooltip: 'DOMICILIO',
        fixedColumn: true,

      },
      {
        field: 'workerEmail',
        header: 'CORREO ELECTRÓNICO',
        view: { table: true, detail: true },

        tooltip: 'CORREO ELECTRÓNICO',
        fixedColumn: true,

      },
      {
        field: 'workerPhone',
        view: { table: true, detail: true },
        header: 'TELÉFONO',

        tooltip: 'TELÉFONO',
        fixedColumn: true,

      },
      {
        field: 'workerExtension',
        view: { table: true, detail: true },
        header: 'EXTENSIÓN',

        tooltip: 'EXTENSIÓN',
        fixedColumn: true,

      },
      {
        field: 'recordStatusStr',
        view: { table: true, detail: true },
        header: 'ESTATUS',

        tooltip: 'ESTATUS',
        fixedColumn: true,

      },


    ]


  }

  onClickBtnNew() {
    const ngmodal: NgbModalRef = this.ngBModal.open(
      OrderWorkerNewComponent, {
      size: 'xl', backdrop: 'static',
      keyboard: false
    })

    ngmodal.componentInstance.companyWorkerId = cloneDeep(this.workerData.companyWorkerId);
    ngmodal.componentInstance.edit.subscribe(output => {
      let { estados, id } = output
      this.getPopulationWorkers();

    });
  }





}
