import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ReportLayoutComponent } from "./layout/report-layout/report-layout.component";
import { MontlyViolationComponent } from "./reportForms/montly-violation/montly-violation.component";
import { MontlyWarningComponent } from "./reportForms/montly-warning/montly-warning.component";
import { MontlyInspectionWorkComponent } from "./reportForms/montlyInspectionWork/montly-inspection-work/montly-inspection-work.component";
import { OrderInspectionReasonsComponent } from "./reportForms/order-inspection-reasons-01/order-inspection-reasons.component";
import { ProgramVisitReportComponent } from "./reportForms/program-visit-report/program-visit-report.component";
import { ServiceOrderReportComponent } from "./reportForms/service-order-report/service-order-report.component";
import { UserServiceReportComponent } from "./reportForms/user-service-report/user-service-report.component";
import { WeeklyStatisticalReportComponent } from "./reportForms/weekly-statistical-report/weekly-statistical-report.component";



const routes: Routes = [
    {
        path: '',
        component: ReportLayoutComponent,
        data: { breadcrumb: `Reportes` }
        , children: [
            {
                path: 'reporteMensualInfraccion',
                component: MontlyViolationComponent
            },
            {
                path: 'reporteSemanalEstadistico',
                component: WeeklyStatisticalReportComponent
            }
            ,
            {
                path: 'reporteMotivosInspeccion',
                component: OrderInspectionReasonsComponent
            }

            ,
            {
                path: 'reporteMensualApercibimiento',
                component: MontlyWarningComponent
            }
            ,
            {
                path: 'reporteMensualTrabajoInspeccion',
                component: MontlyInspectionWorkComponent
            }
            ,
            {
                path: 'reporteMensualVisitaProgramada',
                component: ProgramVisitReportComponent
            },
            {
                path: 'reporteServicioUsuario',
                component: UserServiceReportComponent            
            },
            {
                path: 'reporteServiceOrder',
                component: ServiceOrderReportComponent
            }
        ]
    }]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ReportsRouterModule { }