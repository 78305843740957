import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { HttpClientService } from '../../shared/_services/http-client/http-client.service';
import { ServiceOrderAssignment } from '../serviceOrderForm/_models/ServiceOrderAssignment';
import { companyData } from '../serviceOrderForm/_models/companyData';
import { serviceOrder } from '../serviceOrderForm/_models/serviceOrder';
import { visitData } from '../serviceOrderForm/_models/visitData';
import { workerData } from '../serviceOrderForm/_models/workerData';
import { infractionReport } from '../serviceOrderForm/infraction-report/_models/infractionReport';
import { inspectionReport } from '../serviceOrderForm/inspection-report/_models/inspectionReport';
import { orderNotes } from '../serviceOrderForm/order-notes/_models/orderNotes';
import { warningReport } from '../serviceOrderForm/warning-report/_models/warningReport';

@Injectable({
  providedIn: 'root'
})
export class ServiceOrderGuardGuard implements Resolve<any> {
  constructor(private http: HttpClientService, private router: Router) {

  }
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<[serviceOrder, ServiceOrderAssignment, workerData, companyData, visitData[], warningReport[], infractionReport[], orderNotes[], inspectionReport, any]> {
    // let serviceOrder: serviceOrder;
    if (route.paramMap.get('id')) {
      let assignment: ServiceOrderAssignment;
      let workerData: workerData;
      let companyData: companyData
      let visitData: visitData[];
      let warningReport: warningReport[];
      let infractionReport: infractionReport[];
      let orderNotes: orderNotes[]
      let inspectionReport: inspectionReport;
      let companyInfo: any;
      let serviceOrder = await this.http.get<serviceOrder>(`ServiceOrderFirstStep/ServiceOrderByNumber/${route.paramMap.get('id')}`).toPromise()

      await this.http.get<ServiceOrderAssignment>(`ServiceOrderSecondStep/ServiceOrderByNumber/${serviceOrder.serviceOrderNumber}`).toPromise().then(
        r => {
          assignment = r
        }
      ).catch(p => {
        assignment = null
      })
      await this.http.get<any>(`ServiceOrderThirdStep/ServiceOrderByNumber/${serviceOrder.serviceOrderNumber}`).toPromise().then(
        r => {
          let workerName, workerEmail, workerExtension, workerPhone, companyWorkerId, identificationTypeId, workerIdentification, workerAddress, serviceOrderPopulationWorkersNav;
          ({ workerName, workerEmail, workerExtension, workerPhone, companyWorkerId, identificationTypeId, workerIdentification, workerAddress,serviceOrderPopulationWorkersNav } = r)
          workerData = { workerName, workerEmail, workerExtension, workerPhone, companyWorkerId, identificationTypeId, workerIdentification, workerAddress, serviceOrderPopulationWorkersNav } as workerData;
 
          companyData = r
        }
      ).catch(p => {
        workerData = null

      })
      await this.http.get<visitData[]>(`VisitData/VisitDataByServiceOrderNumber/${serviceOrder.serviceOrderNumber}`).toPromise().then(
        r => {
          visitData = r

        }
      ).catch(p => {
        visitData = []
      });

      await this.http.get<warningReport[]>(`WarningReport/ServiceOrder/${serviceOrder.serviceOrderNumber}`).toPromise().then(
        r => {
          warningReport = r

        }
      ).catch(p => {
        warningReport = []
      })
      await this.http.get<infractionReport[]>(`InfractionReport/ServiceOrder/${serviceOrder.serviceOrderNumber}`).toPromise().then(
        r => {
          infractionReport = r

        }
      ).catch(p => {
        infractionReport = []
      })
      await this.http.get<orderNotes[]>(`ServiceOrderNote/ServiceOrderNotes/${serviceOrder.serviceOrderNumber}`).toPromise().then(
        r => {
          orderNotes = r

        }
      ).catch(p => {
        orderNotes = []
      })

      await this.http.get<inspectionReport>(`InspectionReport/ServiceOrderNumber?serviceOrderNumber=${serviceOrder.serviceOrderNumber}&status=true`).toPromise().then(
        r => {
          inspectionReport = r

        }
      ).catch(p => {
        inspectionReport = null
      })
      await this.http.get<inspectionReport>(`ServiceOrderFirstStep/VisitResult/${serviceOrder.serviceOrderNumber}`).toPromise().then(
        r => {
          companyInfo = r

        }
      ).catch(p => {
        companyInfo = null
      })
      return [serviceOrder, assignment, workerData, companyData, visitData, warningReport, infractionReport, orderNotes,
        inspectionReport, companyInfo
      ]
    } else { this.router.navigate['ordenServicio'] }


  }
}
