<div *ngIf="!isFilter; else isTheFilter">
  <div class="modal-content" [ngClass]="{ cantEditCols: !canEdit }">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="canEdit ? close(false) : close(true)"
      ></button>
    </div>
    <div class="modal-body">
      <div class="centered-content">
        <form
          [formGroup]="inspectionReportForm"
          [ngClass]="{ cantEdit: !canEdit }"
        >
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">No. de Orden </label>
              <input
                type="text"
                placeholder="Numero de orden"
                formControlName="serviceOrderNumber"
                class="form-control form-control-lg"
                placeholder="000-000"
                readonly
              />
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">No. de Informe </label>
              <input
                type="text"
                placeholder="Numero de informe"
                formControlName="inspectionReportNumber"
                class="form-control form-control-lg"
                placeholder="000-000"
                readonly
              />
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Fecha Informe
                <small
                  class="text-danger"
                  *ngIf="inspectionReportForm.get('reportDate').invalid"
                  >*</small
                ></label
              >
              <div class="input-group">
                <input
                  class="form-control form-control-lg"
                  placeholder="Fecha Informe"
                  name="dp"
                  formControlName="reportDate"
                  ngbDatepicker
                  [maxDate]="maxDate"
                  [minDate]="minDate"
                  #d="ngbDatepicker"
                  maxlength="10"
                  [appMaskValue]="inspectionReportForm.get('reportDate').value"
                  [markDisabled]="tool.isDisabled"
                  dateMask
                  (ngModelChange)="
                    this.tool.dateVerification(
                      inspectionReportForm,
                      'reportDate',
                      $event
                    )
                  "
                  [disabled]="inspectionReport"
                />
                <div class="input-group-append">
                  <button
                    pButton
                    icon="pi pi-calendar"
                    class="btn btn-outline-secondary btn-lg"
                    (click)="d.toggle()"
                    type="button"
                    [disabled]="inspectionReport"
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Declaraciones de las Partes
              </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="inspectionReportForm.get('statementsOfParts')"
                type="text"
                placeholder="Declaraciones de las partes"
                class="form-control form-control-lg"
                formControlName="statementsOfParts"
                [readonly]="this.isInspector ? true : false"
                rows="10"
                [title]="this.isInspector ? 'Solo el inspector puede modificar este campo' : ''"
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Comentario Revisión
              </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="
                  inspectionReportForm.get('statementsOfPartsComment')
                "
                type="text"
                placeholder="Comentario revisión"
                class="form-control form-control-lg"
                formControlName="statementsOfPartsComment"
                [readonly]="!isSupervisorOrHigher"
                rows="1"
              ></textarea>
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Revisado Por </label>
              <input
                type="text"
                placeholder="Revisado por"
                [value]="inspectionReport?.statementsOfPartsCheckByName"
                class="form-control form-control-lg"
                readonly
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Hallazgos encontrados
              </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="inspectionReportForm.get('findingsFound')"
                type="text"
                placeholder="Hallazgos encontrados"
                class="form-control form-control-lg"
                formControlName="findingsFound"
                [readonly]="this.isInspector ? true : false"
                [title]="this.isInspector ? 'Solo el inspector puede modificar este campo' : ''"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div class="form-row" >
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Comentario Revisión
              </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="inspectionReportForm.get('findingsFoundComment')"
                type="text"
                placeholder="Comentaris revisión"
                class="form-control form-control-lg"
                formControlName="findingsFoundComment"
                [readonly]="!isSupervisorOrHigher"
                rows="1"
              ></textarea>
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Revisado Por </label>
              <input
                type="text"
                placeholder="Revisado por"
                [value]="inspectionReport?.findingsFoundCheckByName"
                class="form-control form-control-lg"
                readonly
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Comprobaciones </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="inspectionReportForm.get('checks')"
                placeholder="Comprobaciones"
                type="text"
                class="form-control form-control-lg"
                formControlName="checks"
                [readonly]="this.isInspector ? true : false"
                [title]="this.isInspector ? 'Solo el inspector puede modificar este campo' : ''"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Comentario Revisión
              </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="inspectionReportForm.get('checksComment')"
                type="text"
                placeholder="Comentario Revisión"
                class="form-control form-control-lg"
                formControlName="checksComment"
                [readonly]="!isSupervisorOrHigher"
                rows="1"
              ></textarea>
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Revisado Por </label>
              <input
                type="text"
                placeholder="Revisado por"
                [value]="inspectionReport?.checksCheckByName"
                class="form-control form-control-lg"
                readonly
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Orientación a las Partes
              </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="inspectionReportForm.get('orientationToTheParts')"
                type="text"
                placeholder="Orientación a las partes"
                class="form-control form-control-lg"
                formControlName="orientationToTheParts"
                [readonly]="this.isInspector ? true : false"
                [title]="this.isInspector ? 'Solo el inspector puede modificar este campo' : ''"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Comentario Revisión
              </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="
                  inspectionReportForm.get('orientationToThePartsComment')
                "
                type="text"
                placeholder="Comentario revisión"
                class="form-control form-control-lg"
                formControlName="orientationToThePartsComment"
                [readonly]="!isSupervisorOrHigher"
                rows="1"
              ></textarea>
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Revisado Por </label>
              <input
                type="text"
                placeholder="Revisado por"
                [value]="inspectionReport?.orientationToThePartsCheckByName"
                class="form-control form-control-lg"
                readonly
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Resultado Final </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="inspectionReportForm.get('endResult')"
                type="text"
                placeholder="Resultado final"
                class="form-control form-control-lg"
                formControlName="endResult"
                [readonly]="this.isInspector ? true : false"
                [title]="this.isInspector ? 'Solo el inspector puede modificar este campo' : ''"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Comentario Revisión
              </label>

              <textarea
                specialIsAlphaNumeric
                [fControl]="inspectionReportForm.get('endResultComment')"
                type="text"
                placeholder="Comentario revisión"
                class="form-control form-control-lg"
                formControlName="endResultComment"
                [readonly]="!isSupervisorOrHigher"
                rows="1"
              ></textarea>
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg">Revisado Por </label>
              <input
                type="text"
                placeholder="Revisado por"
                [value]="inspectionReport?.endResultCheckByName"
                class="form-control form-control-lg"
                readonly
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Estado del Informe
                <small
                  class="text-danger"
                  *ngIf="inspectionReportForm.get('reportStatusId').invalid"
                  >*</small
                >
              </label>

              <el-dropdown
                [options]="recordStatus"
                formControlName="reportStatusId"
                [_selectedId]="this.directDrop.id"
                [_selectedText]="this.directDrop.text"
                customClass="form-control form-control-lg"
                placeHolder="Elige un estado del informe"
                (onChange)="setEndDate()"
              >
              </el-dropdown>
            </div>
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Fecha Cambio Estado
                <small
                  class="text-danger"
                  *ngIf="inspectionReportForm.get('statusChangesDate').invalid"
                  >*</small
                ></label
              >
              <div class="input-group">
                <input
                  class="form-control form-control-lg"
                  placeholder="Fecha Cambio Estatus"
                  name="dp2"
                  formControlName="statusChangesDate"
                  ngbDatepicker
                  [maxDate]="maxDate"
                  [minDate]="minDate"
                  #d2="ngbDatepicker"
                  readonly
                  [disabled]="true"
                  (click)="d2.toggle()"
                  
                />
                <div class="input-group-append">
                  <button
                    pButton
                    icon="pi pi-calendar"
                    class="btn btn-outline-secondary btn-lg"
                    (click)="d2.toggle()"
                    type="button"
                    [disabled]="true"
                  ></button>
                </div>
              </div>
            </div>

          </div>
          <div class="form-row">
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Dirigido A
                <small
                  class="text-danger"
                  *ngIf="inspectionReportForm.get('directedTo').invalid"
                  >*</small
                >
              </label>

              <el-dropdown
                [options]="representantsDrop"
                [valueName]="'userCode'"
                [textName]="'userName'"
                formControlName="directedTo"
                customClass="form-control form-control-lg"
                placeHolder="Elige a quien sera dirigido"
                [disabled]="!isSupervisorOrHigher"
                [stringOut]="true"
              >
              </el-dropdown>
            </div>            
            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Fecha Finalización
                <small
                  class="text-danger"
                  *ngIf="inspectionReportForm.get('endDate').invalid"
                  >*</small
                ></label
              >
              <div class="input-group">
                <input
                  class="form-control form-control-lg"
                  placeholder="Fecha Finalización"
                  name="dp2"
                  formControlName="endDate"
                  ngbDatepicker
                  [maxDate]="maxDate"
                  [minDate]="minDate"
                  #d2="ngbDatepicker"
                  readonly
                  [disabled]="true"
                  (click)="d2.toggle()"
                />
                <div class="input-group-append">
                  <button
                    pButton
                    icon="pi pi-calendar"
                    class="btn btn-outline-secondary btn-lg"
                    (click)="d2.toggle()"
                    type="button"
                    [disabled]="true"
                  ></button>
                </div>
              </div>
            </div>

            <div class="col-md col-12 mb-1">
              <label class="form-label form-control-lg"
                >Informe Impreso ?
              </label>
              <el-dropdown
                [options]="options"
                formControlName="printedReport"
                customClass="form-control form-control-lg"
                placeHolder="Elige si o no"
              >
              </el-dropdown>
            </div>
          </div>
          <div class="form-row" *ngIf="inspectionReport">
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">Digitado por</label>
              <input
                type="text"
                placeholder="Digitado por"
                class="form-control form-control-lg"
                readonly
                [value]="inspectionReport?.registeredByName"
              />
            </div>
            <div class="col-md col-6 mb-1">
              <label class="form-label form-control-lg">Modificado por</label>
              <input
                type="text"
                placeholder="Modificado por"
                class="form-control form-control-lg"
                readonly
                [value]="inspectionReport?.modifiedByName"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          *ngIf="canEdit && !this.inspectionReport"
          type="button"
          class="rounded-pill btn btn-clean px-5"
          data-bs-dismiss="modal"
          (click)="clean()"
        >
          Limpiar
        </button>
        <button
          *ngIf="canEdit"
          type="button"
          class="rounded-pill btn btn-save px-5"
          data-bs-dismiss="modal"
          (click)="saveInspectionData(inspectionReportForm.value)"
          [disabled]="saving"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #isTheFilter>
  <div class="centered-content">
    <form [formGroup]="inspectionReportForm" [ngClass]="{ cantEdit: !canEdit }">
      <div class="col-md col-12 mb-1">
        <label class="form-label form-control-lg"
          >Dirigido A
          <small
            class="text-danger"
            *ngIf="inspectionReportForm.get('directedTo').invalid"
            >*</small
          >
        </label>

        <el-dropdown
          [options]="DirectedToDrop"
          formControlName="directedTo"
          customClass="form-control form-control-lg"
          placeHolder="Elige a quien sera dirigido"
          [stringOut]="true"
        >
        </el-dropdown>
      </div>

      <div class="col-md col-12 mb-1">
        <label class="form-label form-control-lg">Informe Impreso ? </label>
        <el-dropdown
          [options]="options"
          formControlName="printedReport"
          customClass="form-control form-control-lg"
          placeHolder="Elige si o no"
        >
        </el-dropdown>
      </div>
    </form>
  </div>
</ng-template>
