import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import Swal from 'sweetalert2';
import { user } from '../../auth/_models/User';
import { UserService } from '../../auth/_services/user.service';
import { DropDownOptionModel } from '../../shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { HttpClientService } from '../../shared/_services/http-client/http-client.service';
import { SweetAlertService } from '../../shared/_services/sweetAlert/sweet-alert.service';
import { ToastService } from '../../shared/_services/toast/toast.service';
import { tableNewUser } from '../models/tableNewUser';
import { TableUser } from '../models/tableUser';
import { TableUserAD } from '../models/tableUserAD';
import { RoleManagementComponent } from '../role-management/role-management.component';
import { UserHistoryEditComponent } from '../user-history-edit/user-history-edit.component';


@Component({
  selector: 'app-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.css']
})
export class UserHistoryComponent implements OnInit {
  records: any[];
  loading: boolean = true;
  customButtons: { class: string; function: (record: TableUser) => Promise<void>; tooltip: string; icon: string; }[];
  customAddButtons: { class: string; function: (record: TableUserAD) => Promise<void>; tooltip: string; icon: string; }[];
  history: any;
  recordAds: any[];
  filters: string[];
  cols: ({ field: string; header: string; view: { table: boolean; detail: boolean; }; tooltip: string; fixedColumn: boolean; color: string; hasMulti?: undefined; multiFilter?: undefined; length?: undefined; style?: string; } | { field: string; header: string; view: { table: boolean; detail: boolean; }; tooltip: string; fixedColumn: boolean; color?: undefined; hasMulti?: undefined; multiFilter?: undefined; length?: undefined; style?: undefined; } | { field: string; header: string; view: { table: boolean; detail: boolean; }; tooltip: string; fixedColumn: boolean; hasMulti: any; multiFilter: { options: any; text: string; }; color?: undefined; length?: undefined; style?: undefined; } | { field: string; header: string; view: { table: boolean; detail: boolean; }; length: number; tooltip: string; fixedColumn: boolean; style: string; color?: undefined; hasMulti?: undefined; multiFilter?: undefined; })[];
  localReps: any[];
  roles: { roleCode: string; roleInfo: string; }[];
  isNewUser: boolean = false;
  btnCssDefault: string = 'btn btn-outline-primary';
  ibtnCssDefault: string = 'fa fa-user-plus';
  titlebtn: string = "Nuevo Usuario";
  titleheader: string = "Lista de Usuarios existentes SICIT";
  historyAd: any;
  filtersAd: string[];
  colsAd: ({ field: string; header: string; view: { table: boolean; detail: boolean; }; tooltip: string; fixedColumn: boolean; color: string; hasMulti?: undefined; multiFilter?: undefined; length?: undefined; style?: undefined; } | { field: string; header: string; view: { table: boolean; detail: boolean; }; tooltip: string; fixedColumn: boolean; color?: undefined; hasMulti?: undefined; multiFilter?: undefined; length?: undefined; style?: undefined; } | { field: string; header: string; view: { table: boolean; detail: boolean; }; tooltip: string; fixedColumn: boolean; hasMulti: any; multiFilter: { options: any; text: string; }; color?: undefined; length?: undefined; style?: undefined; } | { field: string; header: string; view: { table: boolean; detail: boolean; }; length: number; tooltip: string; fixedColumn: boolean; style: string; color?: undefined; hasMulti?: undefined; multiFilter?: undefined; })[];
  fillNewUser: any = new tableNewUser();





  // usercode for edit users
  usercode: ({usercode: number})


  constructor(private http: HttpClientService, private ngBModal: NgbModal, private toast: ToastService, private currUser: UserService, private sweet: SweetAlertService,) {

  }

  /**
   * The ngOnInit function is used to initialize the component by calling various methods to populate
   * dropdowns, set default values, and retrieve basic information for a new user.
   */
  ngOnInit() {
    this.getDropdowns();
    this.onClickBtnNew();
    this.getBasicInfoForNewUser();
  }

 /**
  * The function `onClickBtnNew` toggles between displaying a list of existing users in Active
  * Directory and a list of existing users in SICIT.
  */
  onClickBtnNew() {
    if (this.isNewUser) {
      this.getusersActDir();
      this.isNewUser = false;
      this.btnCssDefault = "btn btn-outline-danger";
      this.ibtnCssDefault = 'fa fa-arrow-circle-left';
      this.titlebtn = "Regresar";
      this.titleheader = `Lista de Usuarios Existentes en Active Directory`;

    } else {
      this.isNewUser = true;
      this.getusers();
      this.btnCssDefault = "btn btn-outline-primary";
      this.ibtnCssDefault = 'fa fa-user-plus';
      this.titlebtn = "Nuevo Usuario";
      this.titleheader = "Lista de Usuarios Existentes SICIT";
    }

  }
 /**
  * The `getusers()` function retrieves user records from an API, sets up custom buttons for each user,
  * and defines the columns and filters for displaying the user data in a table.
  */
  getusers() {
    this.records = [];
    this.loading = true;

    this.customButtons = [
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 me-2",
        function: async (user: TableUser) => {
          const ngmodal: NgbModalRef = this.ngBModal.open(
            RoleManagementComponent, { size: 'xl' });
          ngmodal.componentInstance.currentUser = cloneDeep(user);
          ngmodal.closed.subscribe(itSaved => {
            if (itSaved) {
              this.getusers()
            }
          })
        },
        tooltip: 'administrar',
        icon: 'pi pi-eye'
      },
      {
        class: "p-button-rounded p-button-primary css-label-btn-grid px-2 me-2",
        function: (newUser: TableUser) => {
          const ngmodal: NgbModalRef = this.ngBModal.open(
            UserHistoryEditComponent, { size: 'xl' });
          ngmodal.componentInstance.currentUser = newUser;
          ngmodal.componentInstance.localReps = this.localReps;
          ngmodal.closed.subscribe(itSaved => {
            if (itSaved) {
              this.getusers()
            }
          })
          return Promise.resolve();
          
        },
        tooltip: 'Editar',
        icon: 'pi pi-pencil'
      }
      ]

    this.history = this.http.get<user[]>(`User/UserControl`).subscribe(
      {
        next: response => {
          this.records = response;
          this.records.forEach(r => r.recordStatus = !r.recordStatus);
          this.loading = false;

        },
        error: error => {
          this.records = []
          this.loading = false
        }
      }
    )
    this.filters = ["userName", "registrationDateStr", "email", "repLocalProvInfo", "lastTimeLoginDate", "roleInfo"]
    this.cols = [
      {
        field: 'userName',
        header: 'NOMBRE',
        view: { table: true, detail: true },

        tooltip: 'NOMBRE',
        fixedColumn: true,
        color: "#1460B8",

      },
      {
        field: 'registrationDateStr',
        header: 'FECHA ENTRADA',
        view: { table: true, detail: true },

        tooltip: 'FECHA ENTRADA',
        fixedColumn: true,

      },
      {
        field: 'email',
        header: 'CORREO ELECTRONICO',
        view: { table: true, detail: true },

        tooltip: 'CORREO ELECTRONICO',
        fixedColumn: true,

      },
      {
        field: 'repLocalProvInfo',
        header: 'REPRESENTACION LOCAL',
        view: { table: true, detail: true },
        hasMulti: true,
        multiFilter: {
          options: this.localReps,
          text: 'text'
        },
        tooltip: 'REPRESENTACION LOCAL',
        fixedColumn: true,

      },

      {
        field: 'lastTimeLoginDate',
        header: 'ULTIMA CONEXION',
        view: { table: true, detail: true },
        tooltip: 'ULTIMA CONEXION',
        fixedColumn: true,

      },
      {
        field: 'roleInfo',
        header: 'ROL',
        view: { table: true, detail: true },
        hasMulti: true,
        multiFilter: {
          options: this.roles,
          text: 'roleInfo'
        },
        tooltip: 'ROL',
        fixedColumn: true,

      },

    ]
  }

  getDropdowns() {
    this.http.get<{ roleCode: string, roleInfo: string }[]>(`Role?status=true`).subscribe(
      roles => this.roles = roles
    )

    this.http.get<DropDownOptionModel[]>(`LocalRepresentativeProvince`).subscribe(reps => {
      this.localReps = reps;
      this.getusers();
    })
  }

  getBasicInfoForNewUser() {
    let defaultRepProv = "001";
    this.http.get<{ code: string, value: number }[]>(`LocalRepresentativeProvince`).subscribe(reps => {
      this.fillNewUser.repLocalProvId = reps.find(item => item.code == defaultRepProv).value;
    });

    this.http.get<{ roleCode: string, roleId: number }[]>(`Role?status=true`).subscribe(reps => {
      this.fillNewUser.roleId = reps.find(item => item.roleCode == 'INSP').roleId;
    });

    this.http.get<{ alternateField: string, value: number }[]>(`TypeOfIdentification`).subscribe(reps => {
      this.fillNewUser.typeOfIdentificationId = reps.find(item => ['C', 'CD'].some(d => d === item.alternateField)).value;
    });

    this.http.get<{ userId: number, userCode: string, isLocalRepresentative: boolean }[]>(`User/LocalRepresentativeAndDirector/provinceCode?provinceCode=${defaultRepProv}`).subscribe(reps => {
      if (reps.length > 0) this.fillNewUser.supervisorUserId = (reps.find(x => x.isLocalRepresentative == true).userId ?? null);
    });

  }
  fillUserInfo(newUser: TableUserAD) {
    this.fillNewUser.firstName = newUser.firstName;
    this.fillNewUser.firstLastName = newUser.lastName;
    this.fillNewUser.phone = (newUser.homePhone ?? newUser.mobile);
    this.fillNewUser.userCode = newUser.samAccountName.toString().trim();
    this.fillNewUser.registeredBy = this.currUser.getUserData().userCode;
    this.fillNewUser.address = newUser.streetAddress;
    this.fillNewUser.email = (newUser.userPrincipalName ?? newUser.email);
  }
  getusersActDir() {
    this.recordAds = [];
    this.loading = true;
    this.customAddButtons = [
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 me-2",
        function: async (newUser: TableUserAD) => {
          Swal.fire({
            icon: 'info',
            title: '¿Esta seguro que desea agregar este usuario al SICIT?',
            showDenyButton: true,
            confirmButtonText: `Agregar`,
            denyButtonText: `Cancelar`,
          }).then((result) => {

            this.fillUserInfo(newUser);
            if (result.isConfirmed) {
              this.http.post<tableNewUser>(this.fillNewUser, 'User').subscribe(
                {
                  next: response => {
                    if (response.message === "Ok") {
                      this.sweet.record('success', `Usuario "${this.fillNewUser.userCode}" ha sido agregado al SICIT`, '', `Para asignarle rol, Supervisor  y/o Representante debe dirigirse a la sección lista de usuarios existentes SICIT, presionando botón de Regresar.`)
                    } else {
                      this.sweet.record('info', `Usuario "${this.fillNewUser.userCode}" ya existe en SICIT`, '', `Para asignarle rol, Supervisor  y/o Representante debe dirigirse a la sección lista de usuarios existentes SICIT, presionando botón de Regresar.`)
                    }
                    this.getusersActDir();
                  }
                  , error: err => {

                    this.toast.error(err, 'error')

                  }
                }
              )
            } else if (result.isDenied) {
            }
          });
        },
        tooltip: 'Agregar al SICIT',
        icon: 'pi pi-user-plus'
      },
      ]

    this.historyAd = this.http.get<user[]>(`AuthenticationActiveDirectory/getAllUsers`).subscribe(
      {
        next: response => {
          this.recordAds = response;
          this.loading = false;

        },
        error: error => {
          this.recordAds = []
          this.loading = false
        }
      }
    )
    this.filtersAd = ["fullName", "samAccountName", "userPrincipalName", "department", "physicalDeliveryOfficeName", "lastTimeLoginDate", "roleInfo", "userIsOnSicit"]
    this.colsAd = [
      {
        field: 'fullName',
        header: 'NOMBRE',
        view: { table: true, detail: true },

        tooltip: 'NOMBRE',
        fixedColumn: true,
        color: "#1460B8",
      },
      {
        field: 'samAccountName',
        header: 'USUARIO',
        view: { table: true, detail: true },

        tooltip: 'USUARIO',
        fixedColumn: true,

      },
      {
        field: 'userPrincipalName',
        header: 'CORREO ELECTRONICO',
        view: { table: true, detail: true },

        tooltip: 'CORREO ELECTRONICO',
        fixedColumn: true,

      },
      {
        field: 'department',
        header: 'DEPARTAMENTO',
        view: { table: true, detail: true },

        tooltip: 'FECHA ENTRADA',
        fixedColumn: true,

      },
      {
        field: 'physicalDeliveryOfficeName',
        header: 'OFICINA',
        view: { table: true, detail: true },
        tooltip: 'OFICINA',
        fixedColumn: true,

      },
      {
        field: 'userIsOnSicit',
        header: 'REGISTRADO EN SICIT',
        view: { table: true, detail: true },
        hasMulti: true,
        multiFilter: {
          options: [{ userIsOnSicit: "Si" }, { userIsOnSicit: "No" }],
          text: 'userIsOnSicit'
        },
        tooltip: 'REGISTRADO EN SICIT',
        fixedColumn: true,

      },

    ]
  }




}
