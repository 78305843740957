<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      {{ title }} - {{ this.currentCopy.roleInfo }}
    </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="this.ngbActiveModal.close()"></button>
  </div>
  <div class="modal-body">
    <p-tabView>
      <p-tabPanel header="Asignación de Roles" leftIcon="pi pi-user-edit" tooltip="Cambio de Representación Local">
        <div *ngIf="!roles?.length; else load">
          <div class="spinnerContainer">
            <div class="spinner-loading"></div>
            <div class="spinner-text">Cargando</div>
          </div>
        </div>
        <ng-template #load>
          <div class="form-row">
            <div class="col-md-4 col-12 mb-1">
              <label class="form-label form-control-lg">Representación&nbsp;Local
              </label>
              <el-dropdown [options]="localReps" customClass="form-control form-control-lg"
                placeHolder="Elige una representación Local" [(ngModel)]="currentUser.repLocalProvCode"
                (ngModelChange)="getDirectSuperiors(true)" valueName="code" [stringOut]="true"></el-dropdown>
            </div>
            <div class="col-md-4 col-12 mb-1">
              <label class="form-label form-control-lg">Supervisor Inmediato
                <small class="text-danger" *ngIf="!currentUser.userSupervisorCode">*</small>
              </label>
              <el-dropdown [options]="superiors" customClass="form-control form-control-lg"
                [(ngModel)]="currentUser.userSupervisorCode" placeHolder="Elige un Supervisor Inmediato"
                valueName="userCode" textName="name" [stringOut]="true"></el-dropdown>
            </div>
            <div class="col-md-4 col-12 mb-1">
              <label class="form-label form-control-lg">Rol </label>
              <el-dropdown [options]="roles" [(ngModel)]="currentUser.roleCode"
                (ngModelChange)="getDirectSuperiors(true)" customClass="form-control form-control-lg"
                placeHolder="Elige un tipo de solicitante" valueName="roleCode" textName="roleInfo"
                [stringOut]="true"></el-dropdown>
            </div>
          </div>
          <!--[disabled]="!currentUser.userSupervisorCode" -->
          <div class="btn-group float-right m-3">
            <button type="button" class="rounded-pill btn btn-save px-5" (click)="changeRole()">
              Guardar
            </button>
          </div>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Transferencia de Usuarios" leftIcon="pi pi-users" [disabled]="
          this.currentCopy.roleCode == 'DIG' ||
          this.currentCopy.roleCode == 'INSP'
        ">
        <div class="form-row">
          <div class="col-12 col-sm-6 mb-1">
            <label class="form-label form-control-lg">Transferir Colaboradores Desde
            </label>
            <el-dropdown [options]="sameRoleUsers" customClass="form-control form-control-lg "
              placeHolder="Elige un usuario para transferir" [(ngModel)]="userToTransfer"
              (onChange)="getAssignedUsers()" valueName="userCode" textName="fullName" [stringOut]="true"></el-dropdown>
          </div>
        </div>
        <div *ngIf="userToTransfer">
          <div *ngIf="loadingLists; else loadList">
            <div class="spinnerListContainer">
              <div class="spinner-loading"></div>
              <div class="spinner-text">Cargando</div>
            </div>
          </div>
          <ng-template #loadList>
            <p-pickList [source]="sourceUserList" [target]="targetUserList"
              sourceHeader="Colaboradores de {{ userToTransferName }}" [targetHeader]="'Colaboradores de ' + title"
              [dragdrop]="true" [responsive]="true" [sourceStyle]="{ height: '300px' }"
              [targetStyle]="{ height: '300px' }" filterBy="userName" sourceFilterPlaceholder="Buscar por nombre"
              targetFilterPlaceholder="Buscar por nombre" [showSourceControls]="false" [showTargetControls]="false">
              <ng-template let-user pTemplate="item">
                <div class="product-item">
                  <div class="product-list-detail">
                    <h5 class="p-mb-2">{{ user.userName }}</h5>
                    <i class="pi pi-user"></i>
                  </div>
                  <div class="product-list-action">
                    <h6 class="p-mb-2">{{ user.roleInfo }}</h6>
                  </div>
                </div>
              </ng-template>
            </p-pickList>

            <div class="btn-group float-right m-3">
              <button type="button" class="rounded-pill btn btn-save px-5" [disabled]="isSaving"
                (click)="transferUsers()">
                Guardar
              </button>
            </div>
          </ng-template>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>