<form [formGroup]="montlyWarningForm">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-12">
      <label class="form-label form-control-lg"
        >Representación Local
        <small
          [@warning]="tool.stateName(show)"
          class="text-danger"
          *ngIf="!montlyWarningForm.get('repLocalProvId').value"
          >*</small
        >
      </label>
      <el-dropdown
        style="font-weight: 600"
        [options]="localReps"
        customClass="form-control form-control-lg colection-type"
        formControlName="repLocalProvId"
        placeHolder="Elige una representación"
        valueName="code"
        [stringOut]="true"
      ></el-dropdown>
    </div>
    <div class="col-lg-6 col-md-6 col-12">
      <label class="form-label form-control-lg d-block"
        >Mes de Reporte
        <small
          [@warning]="tool.stateName(show)"
          class="text-danger"
          *ngIf="!montlyWarningForm.get('date').value"
          >*</small
        >
      </label>
      <p-calendar
        formControlName="date"
        view="month"
        dateFormat="mm/yy"
        [yearNavigator]="true"
        [maxDate]="maxDate"
        [showIcon]="true"
        [inputStyle]="{ width: '100% !important' }"
        yearRange="2000:2030"
      ></p-calendar>
    </div>
  </div>
  <div class="btn-group float-right m-3">
    <button
      type="button"
      class="rounded-pill btn btn-save px-5"
      (click)="generateReport()"
    >
      Generar Reporte
    </button>
  </div>
</form>
