import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { ButtonModel } from 'src/app/modules/shared/_elements/element-ui/table/models/button-model';
import { TableConfigView } from 'src/app/modules/shared/_elements/element-ui/table/models/table-config-view';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { infractionReportComponent } from '../../../serviceOrderForm/infraction-report/infraction-report.component';
import { InfractionReportService } from '../../../serviceOrderForm/infraction-report/infraction-report.service';
import { infractionReport } from '../../../serviceOrderForm/infraction-report/_models/infractionReport';
import { serviceOrder } from '../../../serviceOrderForm/_models/serviceOrder';
import Swal from 'sweetalert2';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-infraction-history',
  templateUrl: './infraction-history.component.html',
  styleUrls: ['./infraction-history.component.css']
})
export class InfractionHistoryComponent implements OnInit {

  @Input() serviceOrder: serviceOrder;
  @Output() empty = new EventEmitter<boolean>()
  @Input() canEdit: boolean = true;
  @ViewChild('btnEditSaveInfractionReport') toggleButton: ElementRef;
  amount = 0;
  reLoad: Function;
  history: Subscription
  headers: TableConfigView[];
  records: infractionReport[];
  cols: any[];
  filters: any[];
  loading: boolean = true;
  InitialFormValue: FormGroup;
  subs: Subscription;
  maxDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() }
  @Input() canJustify: boolean = true;

  constructor(private http: HttpClientService, private route: Router, private user: UserService,
    private fb: FormBuilder, private infractionData: InfractionReportService, private ngBModal: NgbModal, private sweet: SweetAlertService, private renderer: Renderer2) { }

  searchForm: FormGroup;

  ngOnDestroy(): void {
    this.history.unsubscribe()
    this.subs.unsubscribe();
  }
  customButtons: ButtonModel[];

  deleteRecord(record) {
    Swal.fire({
      icon: 'warning',
      title: '¿Esta seguro que desea eliminarlo?',
      showDenyButton: true,
      confirmButtonText: `Eliminar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.http.patch<infractionReport[]>(record, `infractionReport/${record.infractionReportId}?registrationStatus=false`).subscribe(
          {
            next: response => {
              this.getRecords()
            },
            error: error => {
            }
          })
      } else if (result.isDenied) {
      }
    });
  }
  ngOnInit(): void {

    this.assignButtons()
    this.getRecords()
    this.subs = this.infractionData.subject.subscribe(data => {
      this.getRecords()

    });
    this.onSavedInfractionJustClick()

    this.searchForm = this.fb.group({
      sinceDate: new FormControl(this.dateToObject(moment().subtract(1, 'M').set('date', 1).format().toString())),
      untilDate: new FormControl(this.dateToObject(moment().format().toString())),
      show: new FormControl(false)
    })


    this.filters = ["registeredByName", "registeredByName", "infractionReportNumber", 'conceptInfo', "recordStatusStr"]
    this.cols = [


      {
        field: 'infractionReportNumber',
        view: { table: true, detail: true },
        header: 'NÚMERO ACTA',
        width: 3,
        tooltip: 'NÚMERO ACTA',
        fixedColumn: true,

      },


      {
        field: 'reportDateStr',
        header: 'FECHA ACTA',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'FECHA ACTA',
        fixedColumn: true,

      },

      {
        field: 'conceptInfo',
        header: 'CONCEPTOS',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'CONCEPTOS',
        fixedColumn: true,
        length: 30
      },
      {
        field: 'registeredByName',
        header: 'REGISTRADO POR',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'REGISTRADO POR',
        fixedColumn: true,

      },
      {
        field: 'recordStatusStr',
        view: { table: true, detail: true },
        header: 'ESTATUS',

        tooltip: 'ESTATUS',
        fixedColumn: true,

      },


    ]
  }

  onSavedInfractionJustClick() {
    this.renderer.listen('window', 'click', (e: Event) => {
      let element: any = e;
      if (element.target.id === 'btnEditSaveInfractionReport') {
        setTimeout(() => { this.getRecords() }, 1000);

      }
    });
  }

  dateToObject(date: string) {
    if (date) {
      let a: any[] = date.slice(0, 10).split('-').map(x => {
        return Number(x)
      })
      return { year: a[0], month: a[1], day: a[2] };
    }
    return null
  }

  getRecords() {

    this.loading = true;
    this.history = this.http.get<infractionReport[]>(`infractionReport/ServiceOrder/${this.serviceOrder.serviceOrderNumber}`).subscribe(
      {

        next: response => {
          response.forEach(element => {

            element.recordStatusStr = element.recordStatus ? 'Activo' : 'Eliminado';

            switch (element.recordStatus) {
              case true:
                element.recordStatusStr = "Activo";
                break;

              default:
                element.recordStatusStr = "Eliminado";
                break;
            }
          });

          this.records = response
          this.loading = false;
          this.amount = response.length

        },
        error: error => {

          this.empty.emit(true);
          this.records = []
          this.loading = false;
        }
      }
    )

  }
  assignButtons() {
    this.customButtons = [
      {
        class: "p-button-rounded p-button-info css-label-btn-grid px-2 me-2",
        function: async (record: serviceOrder) => {
          const ngmodal: NgbModalRef = this.ngBModal.open(
            infractionReportComponent, { size: 'xl' });
          ngmodal.componentInstance.infractionReport = cloneDeep(record);
          ngmodal.componentInstance.canEdit = this.canEdit;

        },
        tooltip: `${this.canEdit ? 'Editar' : 'Consultar'}`,
        icon: `${this.canEdit ? 'pi pi-user-edit' : 'pi-search'}`
      },
      {
        class: `${this.user.getUserData().roleCode !== 'REPLO' ? 'btn-inform-del p-button-rounded p-button-danger css-label-btn-grid px-2 me-2 ' : 'p-button-rounded p-button-danger css-label-btn-grid px-2 me-2 '}`,
        function: async (record: infractionReport) => {
          if (this.user.getUserData().roleCode !== 'REPLO') {
            this.sweet.record('warning', (record.infractionReportNumber === "" ? "Esta acta de infracción" : record.infractionReportNumber), `Solo puede ser eliminada por un Representante Local.`, ``);
          } else {
            if (!record.recordStatus) {
              this.sweet.record('warning', `Esta acta ${record.infractionReportNumber}`, `Ya ha sido eliminada.`, `Se recomienda registrar una nueva en caso de ser necesario.`);
            } else {
              const ngmodal: NgbModalRef = this.ngBModal.open(
                infractionReportComponent, { size: 'lg' });
              ngmodal.componentInstance.infractionReport = cloneDeep(record);
              ngmodal.componentInstance.canEdit = this.canEdit
              ngmodal.componentInstance.canJustify = this.canJustify;
            }

          }


        },
        tooltip: `${this.user.getUserData().roleCode !== 'REPLO' ? 'Solo los Representantes Locales pueden eliminar Actas de Infracción' : 'Eliminar Acta de Infracción'}`,
        icon: 'pi pi pi-trash'
      },


    ]
  }
}
