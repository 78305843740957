import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthChildGuardService } from '../auth/_services/auth-child-guard.service';
import { AuthGuardService } from '../auth/_services/auth-guard.service';
import { CanExitGuard } from './Guards/can-exit.guard';
import { InspectionProgramGuard } from './Guards/inspection-program.guard';
import { RoleGuard } from './Guards/role.guard';
import { ServiceOrderGuardGuard } from './Guards/service-order-guard.guard';
import { UserServiceResolverGuard } from './Guards/user-service-resolver.guard';
import { FormsCreationComponent } from './service/forms-creation/forms-creation.component';
import { InspectionProgramComponent } from './service/inspection-program/inspection-program.component';
import { RecordHistoryComponent } from './service/record-history/record-history.component';
import { ServiceOrderComponent } from './service/service-order/service-order.component';
import { ServiceComponent } from './service/service.component';
import { UserServiceFormComponent } from './service/user-service-form/user-service-form.component';


const routes: Routes = [

  {
    
    path: '',
    component: ServiceComponent,
    data: { breadcrumb: `Colección` },
    canActivate:[AuthGuardService],
    children: [

      {
        path: '', redirectTo: '/Casos/historial', pathMatch: 'full'
      },

      {
        path: 'creacionRecord',
        component: FormsCreationComponent,
        // data: { breadcrumb: 'Crear' },
        children: [
          {
            path: 'ordenServicio',
            component: ServiceOrderComponent,
               data: { breadcrumb: 'Orden de Servicio' },
            canDeactivate: [CanExitGuard],
            canActivate:[RoleGuard]

          },
          {
            path: 'programaInspeccion',
            component: InspectionProgramComponent,
            data: { breadcrumb: 'Programa de Inspección' },
            canDeactivate: [CanExitGuard],
            canActivate:[RoleGuard]

          },
          {
            path: 'ServicioUsuario',
            component: UserServiceFormComponent,
            data: { breadcrumb: 'Servicio de Usuario' },
            canDeactivate: [CanExitGuard],
            canActivate:[RoleGuard]

          }
        ]
      },

      {
        path: 'ordenServicio/:id',
        component: ServiceOrderComponent,
        data: { breadcrumb: 'Orden de Servicio' },
        resolve: { order: ServiceOrderGuardGuard },
        canDeactivate: [CanExitGuard],
        
      },
      {
        path: 'programaInspeccion/:id',
        component: InspectionProgramComponent,
           data: { breadcrumb: 'Programa de Inspección' },
        resolve: {program:InspectionProgramGuard},
            canDeactivate: [CanExitGuard],

      },
      {
        path: 'ServicioUsuario/:id',
        component: UserServiceFormComponent,
           data: { breadcrumb: 'Servicio de Usuario' },
        resolve: {UserService:UserServiceResolverGuard},
        canDeactivate: [CanExitGuard]
      },
      { 
        path: 'historial',
          data: { breadcrumb: 'Historial' },
        component: RecordHistoryComponent,
      }




    ],
   // canActivateChild:[AuthChildGuardService]
  },
     
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class serviceRoutingModule { }