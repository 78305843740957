<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title fw-500" name="exampleModalLabel">
            Editar usuario
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
            (click)="this.ngbActiveModal.close()"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="profileForm">            
          <div class="form-row p-2">
            <div class="col-6 p-1">
              <label class="form-label" for="email">Primer nombre</label>
              <input type="text" class="form-control" name="email" formControlName="firstName"
              >
            </div>
            <div class="col-6 p-1">
              <label class="form-label">Segundo Nombre</label>
              <input type="text" class="form-control" name="secondName" formControlName="secondName">
            </div>
            <div class="col-6 p-1">
              <label class="form-label">Primer Apellido</label>
              <input type="text" class="form-control" name="lastName" formControlName="lastName">
            </div>
            <div class="col-6 p-1">
              <label class="form-label">Segundo Apellido</label>
              <input type="text" class="form-control" name="secondLastName" formControlName="secondLastName">
            </div>
            <div class="col-6 p-1">
              <label class="form-label">Tipo de identifcación</label>
              <el-dropdown
                  customClass=""
                  formControlName="typeOfIdentificationId"
                  valueName="value"
                  [stringOut]="true"
                  [options]="identificationTypes"
                  (onChange)="typeOfIdentificationChanged($event)">
                </el-dropdown>
            </div>
          
            <div class="col-6 p-1">
              <label class="form-label">Identificación</label>
              <input type="text" class="form-control" name="identification" formControlName="identification" />
            </div>
            <div class="col-6 p-1">
              <label class="form-label">Correo</label>
              <input type="email" class="form-control" name="email" formControlName="email">
            </div>
            <div class="col-6 p-1">
              <label class="form-label">Representación local</label>
                <el-dropdown
                  customClass=""
                  formControlName="repLocalProvId"
                  valueName="value"
                  [stringOut]="true"
                  [options]="localReps">
                </el-dropdown>

            </div>
            <div class="col-6 p-1">
              <label class="form-label">Estado</label>
                <div class="flex justify-content-center">
                  <p-selectButton  name="recordStatus" [options]="stateOptions" optionValue="value" optionLabel="label" formControlName="recordStatus"></p-selectButton>
                </div>
            </div>
            <!-- button save -->
            <div class=" d-flex justify-content-end col-6 p-1">
              <button class="btn btn-outline-info ps-4 pe-4 m-2 btn-sm fw-700 rounded-pill" type="submit" (click)="userUpdate()" [disabled]="!profileForm.valid">Guardar</button> 
            </div>
          </div>
        </form>
    </div>
</div>
