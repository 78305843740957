import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ServiceComponent } from './modules/service/service/service.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuardService } from './modules/auth/_services/auth-guard.service';


const appRoutes: Routes = [

   { path: '', redirectTo: 'login', pathMatch: 'full' },
   { path: 'login', component: LoginComponent },


   {
      path: '', component: HomeComponent,
      canActivate: [AuthGuardService],
      children:
         [

            {
               path: 'Dashboard',
               loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
               path: 'Casos',
               loadChildren: () => import('./modules/service/service.module').then(m => m.ServiceModule)
            },
            {
               path: 'Reportes',
               loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
            },
            {
               path: 'Talleres',
               loadChildren: () => import('./modules/workshop/workshop.module').then(m => m.WorkshopModule)
            },
            {
               path: 'Admin',
               loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
            }

         ]

   },


];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });