import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-montly-warning',
  templateUrl: './montly-warning.component.html',
  styleUrls: ['./montly-warning.component.css'],
  animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ]
})
export class MontlyWarningComponent implements OnInit {
  urlApi: any;
  constructor(private translate: TranslateService, private config: PrimeNGConfig, private FB: FormBuilder, public tool: ToolsService, private http: HttpClientService, private el: ElementRef) {

    this.translate.setDefaultLang('es');
    this.urlApi = environment.api_url;
  }
  montlyWarningForm: FormGroup;
  show: boolean = false
  reportTypes: any[]
  localReps: any[];
  maxDate = moment().toDate()
  async ngOnInit() {
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
    this.montlyWarningForm = this.FB.group({
      repLocalProvId: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required)
    })
    await this.getDropdowns()
  }

  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }


  async getDropdowns() {
    this.localReps = await this.http.get<any[]>(`LocalRepresentativeProvince`).toPromise();
  }

  generateReport() {
    if (this.montlyWarningForm.invalid) {
      this.tool.createWarning(this.montlyWarningForm, this.el).then(result => this.notFound())
    } else {
      const { date, repLocalProvId } = this.montlyWarningForm.value
      let month = moment(date).get('month') + 1
      let year = moment(date).get('year')
      this.tool.showLoading(`${this.urlApi}/ExcelReports/Rlt06Warning?localRepresentativeCode=${repLocalProvId}&month=${month}&year=${year}`)
    }
  }
}
