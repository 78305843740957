import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PrimeNGConfig } from 'primeng/api';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-inspection-reasons',
  templateUrl: './order-inspection-reasons.component.html',
  styleUrls: ['./order-inspection-reasons.component.css'],
  animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ]
})
export class OrderInspectionReasonsComponent implements OnInit {
  urlApi: any;
  constructor(private translate: TranslateService, private config: PrimeNGConfig, private FB: FormBuilder, public tool: ToolsService, private http: HttpClientService, private el: ElementRef) {
    this.getDropdowns()
    this.translate.setDefaultLang('es');
    this.urlApi = environment.api_url;
  }
  orderInspectionReasonForm: FormGroup;
  show: boolean = false
  localReps: any[];
  dropdownSettings: IDropdownSettings = {};
  inspectionMotives: DropDownOptionModel[];
  maxDate = moment().toDate()
  async ngOnInit() {
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Seleccionar Todos',
      unSelectAllText: 'Deseleccionar Todos',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: true,
      searchPlaceholderText: 'Buscar'

    };

    this.orderInspectionReasonForm = this.FB.group({
      repLocalProvId: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      inspectionMotiveId: new FormControl(null, Validators.required)
    })
  }

  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }


  async getDropdowns() {
    this.localReps = await this.http.get<any[]>(`LocalRepresentativeProvince`).toPromise();
    this.inspectionMotives = await this.http.get<DropDownOptionModel[]>(`InspectionReason`).toPromise()
  }

  generateReport() {
    if (this.orderInspectionReasonForm.invalid) {
      this.tool.createWarning(this.orderInspectionReasonForm, this.el).then(result => this.notFound())
    } else {
      const { date, repLocalProvId, inspectionMotiveId } = this.orderInspectionReasonForm.value

      let month = moment(date).get('month') + 1
      let year = moment(date).get('year')
      this.tool.showLoading(`${this.urlApi}/ExcelReports/BranchReasonRlt01Rlt05?localRepresentativeCode=${repLocalProvId}&month=${month}&year=${year}&idInspectioReason=${inspectionMotiveId}`)

    }
  }
}
