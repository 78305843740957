import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { IHttpClient } from './Ihttp-client';
import { RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService implements IHttpClient {
  private readonly url = environment.api_url;
  private readonly urlSirla = environment.sirla_Url;
  private headers: HttpHeaders;
  constructor(private _http: HttpClient) { }

  get<T>(route: string, options?: {}): Observable<T> {
    return this._http.get<T>(`${this.url}/${route}`, options);
  }

  getById<T>(route: string, id: number, options?: {}): Observable<T> {
    return this._http.get<T>(`${this.url}/${route}/${id}`, options);
  }

  post<T>(obj: object, route: string): Observable<T> {
    return this._http.post<T>(`${this.url}/${route}`, obj);
  }

  update<T>(obj: object, route: string): Observable<T> {
    return this._http.put<T>(`${this.url}/${route}`, obj);
  }

  patch<T>(obj: any, route: string): Observable<any> {
    return this._http.patch<T>(`${this.url}/${route}`, obj);
  }

  delete<T>(id: number, route: string): Observable<any> {
    return this._http.delete<T>(`${this.url}/${route}/${id}`);
  }

  getSirla<T>(route: string): Observable<string> {
    this.headers = new HttpHeaders()
      // .set('Accept', 'text/html; charset=utf-8')
      // .set('X-API-Key', environment.sirla_X_API_Key)
      .set('Access-Control-Allow-Origin', '*')
      .set('Access-Control-Allow-Methods', 'GET, OPTIONS')
      .set('Access-Control-Allow-Credentials', 'true')
      .set('Access-Control-Allow-Headers', 'Content-Type, Accept, X-API-Key, Authorization, X-Requested-With')

    return this._http.get(`${this.urlSirla}/${route}`, {
      headers: this.headers,
      responseType: 'blob',
      observe: 'response'
    }).
      pipe(map((res) => {
        console.log('getSirla', res);
        const fileUrl = URL.createObjectURL(res.body);
        return fileUrl;
      }));
  }
}
