<div class="modal-content">
  <div class="modal-header" style="border: 0px">
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    ></button>
  </div>
  <div class="modal-body pb-5">
    <div class="centered-content">
      <div class="row justify-content-center text-center mb-3">
        <div class="nova-bold h5 mb-0">Perfil</div>
      </div>
      <div class="row justify-content-center text-center mb-2">
        <p-avatar
          [label]="user.getInitials()"
          styleClass="p-mr-2 mx-auto"
          [style]="{ 'background-color': '#A6B9CF', color: '#FFFFFF' }"
          size="xlarge"
          shape="circle"
        ></p-avatar>
      </div>
      <div class="row justify-content-center text-center mb-4">
        <div class="h3 d-inline nova-bold mb-0">
          {{ user.getFullName() }}
        </div>
        <div class="d-inline nova">{{ user.roleInfo }}</div>
      </div>

      <div class="row justify-content-center mb-5">
        <div
          class="
            col-6
            d-inline d-inline-sm-block d-sm-flex
            justify-content-between
            p-4
          "
          style="background: #f3f7fc; width: 107px"
        >
          <div class="align-self-center text-center">
            <div for="rep" class="h6 fw-light">Representación Local</div>
            <div name="rep" class="nova-bold">
              {{ user.repLocalProvIdInfo }}
            </div>
          </div>
          <div class="align-self-center separator"></div>
          <div class="align-self-center text-center">
            <div for="group" class="h6 fw-light">Grupo</div>
            <div name="group" class="nova-bold">{{ user.groupInfo }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
