import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbCalendar, NgbDateParserFormatter, NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { user } from 'src/app/modules/auth/_models/User';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { AdvanceFilterModel } from '../../service/advance-Filters/advanced-filter/AdvanceFilterModel';
import { InspectionFilter } from '../../service/advance-Filters/advanced-inspection-filter/inspectionFilter';
import { inspectionReport } from './_models/inspectionReport';
import { InspectionReportService } from './inspection-report.service';
import { cloneDeep } from 'lodash';
const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null
      ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}`
      : null;
  }
}
@Component({
  selector: 'app-inspection-report',
  templateUrl: './inspection-report.component.html',
  styleUrls: ['./inspection-report.component.css'],
  animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }, { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }]
})
export class InspectionReportComponent implements OnInit {

  constructor(private fb: FormBuilder, private userService: UserService, private ngbActiveModal: NgbActiveModal,
    private Http: HttpClientService, private sweet: SweetAlertService, private inspectionData: InspectionReportService,
    private toast: ToastService, public tool: ToolsService, private elem: ElementRef, private router: Router, private route: ActivatedRoute, private calendar: NgbCalendar) {
    this.getDropdowns()
  }
  @Input() isFilter: boolean = false
  @Input() isChildren: boolean = false
  @Output() edit = new EventEmitter<boolean>();
  @Input() canEdit: boolean = true;
  @Output() hasValue = new EventEmitter<boolean>();

  serviceOrderNumber: string;
  serviceOrderId: number;
  recordStatus: any[] = []
  show: boolean = false;
  options: DropDownOptionModel[] = [{ value: true, text: 'Sí' }, { value: false, text: 'No' }]
  recordJustifications: any[] = [];
  saving: boolean = false;
  user: user = this.userService.getUserData();
  inspectionReportForm: FormGroup;
  inspectionReport: inspectionReport
  inspectionCopy: FormGroup;
  title: string;
  minDate: any;
  localRepProv: any[] = []
  representantsDrop: any[] = []
  statusCopy: any[] = []
  justification: boolean = false
  isSupervisorOrHigher: boolean = false;
  isInspector:boolean = false;
  orderInvalidSteps: string[] = []
  maxDate = { year: this.calendar.getToday().year, month: this.calendar.getToday().month, day: this.calendar.getToday().day }
  saveSubscribe: Subscription;
  directDrop = { id: null, text: null };
  inspectionFilter: InspectionFilter = this.tool.getFilterLS('inspectionFilter')?.form;
  inspectionFC: AdvanceFilterModel = this.tool.getFilterLS('orderFilter')?.form;
  DirectedToDrop: any[] = []
  get currentDay() {
    let date = moment().format('YYYY-MM-DD')

    return date
  }

  get currentDayObj() {
    return this.tool.dateToObject(moment().format('DD-MM-YYYY'))
  }
  async ngOnInit() {


    if (this.user.roleCode !== 'INSP' && this.user.roleCode !== 'DIG') {
      this.isSupervisorOrHigher = true;
    }
    if (this.user.roleCode !== 'INSP') {
      this.isInspector = true;
    }

    this.minDate = !this.inspectionReport ? this.tool.getMinDate(14) : null
    this.createForm()

    // if (this.inspectionReport) { this.checkStatus() }
    //this.filterStatus()

  }

  createForm() {
    this.title = "Crear Informe de Inspecciones"
    if (this.isFilter) {
      if (this.isChildren) {
        this.inspectionReportForm = this.fb.group({

          printedReport: new FormControl(this.inspectionFC ? this.inspectionFC.printedReport : null),
          directedTo: new FormControl(this.inspectionFC ? this.inspectionFC.directedTo : null)
        })
        this.hasValue.emit(this.tool.hasFormAnyValue(this.inspectionReportForm.value))
      }
      else {
        this.inspectionReportForm = this.fb.group({

          printedReport: new FormControl(this.inspectionFilter ? this.inspectionFilter.printedReport : null),
          directedTo: new FormControl(this.inspectionFilter ? this.inspectionFilter.directedTo : null)
        })
      }

    }
    else {
      if (!this.inspectionReport) {
        this.inspectionReportForm = this.fb.group({
          inspectionReportId: new FormControl(0),
          serviceOrderNumber: new FormControl(this.serviceOrderNumber),
          inspectionReportNumber: new FormControl(null),
          reportDate: new FormControl(this.calendar.getToday(), [Validators.required, this.tool.dateValidator]),
          statementsOfParts: new FormControl(null),
          statementsOfPartsComment: new FormControl(null),
          statementsOfPartsCheckBy: new FormControl(null),
          findingsFound: new FormControl(null),
          findingsFoundComment: new FormControl(null),
          findingsFoundCheckBy: new FormControl(null),
          checks: new FormControl(null),
          checksComment: new FormControl(null),
          checksCheckBy: new FormControl(null),
          orientationToTheParts: new FormControl(null),
          orientationToThePartsComment: new FormControl(null),
          orientationToThePartsCheckBy: new FormControl(null),
          endResult: new FormControl(null),
          endResultComment: new FormControl(null),
          endResultCheckBy: new FormControl(null),
          reportStatusId: new FormControl(null, Validators.required),
          // justificationChangeStatusId: new FormControl(null),
          // justificationChangeStatusOther: new FormControl(null),
          directedTo: new FormControl(null, Validators.required),

          endDate: new FormControl(null),
          printedReport: new FormControl(null),
          registeredBy: new FormControl(this.user.userCode),
          modifiedBy: new FormControl(null),
          recordStatus: new FormControl(true),
          registrationDate: this.currentDay,
          recordModificationDate: this.currentDay,
          statusChangesDate: new FormControl(null),
           
        })
        this.inspectionCopy = cloneDeep(this.inspectionReportForm)
        this.tool.removeValidators(this.inspectionReportForm)
        // this.preSelect()
      } else {
        this.title = "Editando Informe de Inspecciones"
 
 
        this.inspectionReportForm = this.fb.group({
          inspectionReportId: new FormControl(this.inspectionReport.inspectionReportId),
          serviceOrderNumber: new FormControl(this.inspectionReport.serviceOrderNumber),
          inspectionReportNumber: new FormControl(this.inspectionReport.inspectionReportNumber),
          reportDate: new FormControl(this.tool.dateToObject(this.inspectionReport.reportDate), Validators.required),
          statementsOfParts: new FormControl(this.inspectionReport.statementsOfParts),
          statementsOfPartsComment: new FormControl(this.inspectionReport.statementsOfPartsComment),
          statementsOfPartsCheckBy: new FormControl(this.inspectionReport.statementsOfPartsCheckBy),
          findingsFound: new FormControl(this.inspectionReport.findingsFound),
          findingsFoundComment: new FormControl(this.inspectionReport.findingsFoundComment),
          findingsFoundCheckBy: new FormControl(this.inspectionReport.findingsFoundCheckBy),
          checks: new FormControl(this.inspectionReport.checks),
          checksComment: new FormControl(this.inspectionReport.checksComment),
          checksCheckBy: new FormControl(this.inspectionReport.checksCheckBy),
          orientationToTheParts: new FormControl(this.inspectionReport.orientationToTheParts),
          orientationToThePartsComment: new FormControl(this.inspectionReport.orientationToThePartsComment),
          orientationToThePartsCheckBy: new FormControl(this.inspectionReport.orientationToThePartsCheckBy),
          endResult: new FormControl(this.inspectionReport.endResult),
          endResultComment: new FormControl(this.inspectionReport.endResultComment),
          endResultCheckBy: new FormControl(this.inspectionReport.endResultCheckBy),
          reportStatusId: new FormControl(this.inspectionReport.reportStatusId, Validators.required),
          // justificationChangeStatusId: new FormControl(this.inspectionReport.justificationChangeStatusId),
          // justificationChangeStatusOther: new FormControl(this.inspectionReport.justificationChangeStatusOther),
          directedTo: new FormControl(this.inspectionReport.directedTo, Validators.required),
          endDate: new FormControl(this.tool.dateToObject(this.inspectionReport.endDate)),
          printedReport: new FormControl(this.inspectionReport.printedReport),
          registeredBy: new FormControl(this.inspectionReport.registeredBy),
          modifiedBy: new FormControl(this.user.userCode),
          recordStatus: new FormControl(this.inspectionReport.recordStatus),
          registrationDate: this.inspectionReport.registrationDate,
          recordModificationDate: this.currentDay,
          statusChangesDate: new FormControl(this.tool.dateToObject(this.inspectionReport.statusChangesDate)),
 
        })

        //  this.otherJustification()
      }
    }
  }
  close(direct?: boolean) {
    if (direct) {
      this.ngbActiveModal.close(true);
    } else {
      this.tool.modalWarning().then((result) => {
        if (result.isConfirmed) {
          this.ngbActiveModal.close();
        }
      })

    }
  }

  directTo() {


    if (!this.isFilter) {
      this.inspectionReportForm.patchValue({
        'directedTo': this.representantsDrop.filter(el => el.isLocalRepresentative == true)[0].userCode
      })
    }
  }
  setEndDate() {
    if (this.inspectionReportForm.get('reportStatusId').value) {
      let statusId = this.inspectionReportForm.get('reportStatusId').value;

      if (this.recordStatus?.find(x => x.value == statusId)?.alternateField == 'FIZDO') {

        this.inspectionReportForm.patchValue({
          'endDate': this.tool.dateToObject(this.currentDay)
        })
      } else {
        this.inspectionReportForm.patchValue({
          'endDate': null
        })
      }
    }
  }

  async getDropdowns() {
    this.recordStatus = await this.Http.get<any[]>('StatusInspectionReport').toPromise()
    // this.recordJustifications = await this.Http.get<any[]>('JustificationChangeEstimatedDateInspectRep').toPromise();
    // this.otherJustification()
    //  this.preSelect()
    this.localRepProv = await this.Http.get<any[]>(`LocalRepresentativeProvince`).toPromise()
    const code = this.localRepProv.find(el => el.value === this.user.repLocalProvId)?.code
    this.representantsDrop = await this.Http.get<any[]>(`User/LocalRepresentativeAndDirector/provinceCode?provinceCode=${code}`).toPromise()
    if (this.inspectionReport) {
      this.orderInvalidSteps = await this.Http.get<any[]>(`InspectionReport/Pending/${this.inspectionReport.serviceOrderNumber}`).toPromise()
    }

    // let DirectedToDropMap = cloneDeep(this.representantsDrop)
    // this.DirectedToDrop = DirectedToDropMap.map(el => {
    //   return {
    //     value: el.userCode,
    //     text: el.userName
    //   }
    // })

    if (!this.isFilter) {
      this.filterStatus()
      this.directTo()
    }
    // console.log(this.orderInvalidSteps);

  }
  async saveInspectionData(inspectionReport: inspectionReport) {
    let code = this.recordStatus.find(el => el.value == this.inspectionReportForm.get('reportStatusId').value)?.alternateField

    if (this.inspectionReportForm.valid) {
      this.saving = true
      if (this.orderInvalidSteps.length > 0 && code == 'FIZDO') {
        this.tool.createPendingWarning(this.orderInvalidSteps).then(_ => this.saving = false)
      } else {
        if (this.inspectionReport) {

          try {
            await this.Http.update<inspectionReport>(this.mapInspectionReport(this.inspectionReportForm.value), 'InspectionReport').toPromise()
            this.edit.emit(true);
            this.close(true);
            this.sendNotification(this.user.userCode, this.inspectionReport.inspectionReportNumber)
            if (code == 'ARCDO') {
              this.changeState()
            } else {

              this.sweet.record('success', `Registro No.${this.inspectionReport.inspectionReportNumber}`, 'Su Informe se ha actualizado correctamente', ``)
              this.inspectionData.subject.next(true)
            }
          }
          catch (err) {
            this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
            this.saving = false

          }
        }
        else {
          try {
            const res = await this.Http.post<inspectionReport>(this.mapInspectionReport(this.inspectionReportForm.value), 'InspectionReport').toPromise()
            this.edit.emit(true);
            this.close(true);
            this.sendNotification(this.user.userCode, res.inspectionReportNumber);
            if (code == 'ARCDO') {
              this.changeState()
            } else {

              this.sweet.record('success', `Registro No.${res.inspectionReportNumber}`, 'Su informe se ha registrado correctamente', ``)
              this.inspectionData.subject.next(true)
            }
          }
          catch (err) {
            this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
            this.saving = false
          }

        }


      }
    } else {
      this.tool.createWarning(this.inspectionReportForm, this.elem)
    }

  }

  mapInspectionReport(InspectionReport: inspectionReport) {

    let { statementsOfPartsComment, findingsFoundComment, checksComment,
      orientationToThePartsComment, endResultComment } = InspectionReport
    let mapInspection;

    if(InspectionReport.statusChangesDate === null) InspectionReport.statusChangesDate =  new Date().toISOString();
    if (this.inspectionReport) {
      mapInspection = {

        reportDate: new Date(`${InspectionReport.reportDate['year']}-${InspectionReport.reportDate['month']}-${InspectionReport.reportDate['day']}`),
        endDate: InspectionReport.endDate ? new Date(`${InspectionReport.endDate['year']}-${InspectionReport.endDate['month']}-${InspectionReport.endDate['day']}`) : '',
        statementsOfPartsCheckBy: statementsOfPartsComment == this.inspectionReport.statementsOfPartsComment ? this.inspectionReport.statementsOfPartsCheckBy : this.user.userCode,
        findingsFoundCheckBy: findingsFoundComment == this.inspectionReport.findingsFoundComment ? this.inspectionReport.findingsFoundCheckBy : this.user.userCode,
        checksCheckBy: checksComment == this.inspectionReport.checksComment ? this.inspectionReport.checksCheckBy : this.user.userCode,
        orientationToThePartsCheckBy: orientationToThePartsComment == this.inspectionReport.orientationToThePartsComment ? this.inspectionReport.orientationToThePartsCheckBy : this.user.userCode,
        endResultCheckBy: endResultComment == this.inspectionReport.endResultComment ? this.inspectionReport.endResultCheckBy : this.user.userCode,
        statusChangesDate: new Date(`${InspectionReport.statusChangesDate['year']}-${InspectionReport.statusChangesDate['month']}-${InspectionReport.statusChangesDate['day']}`)
      }
    } else {

      mapInspection = {

        reportDate: new Date(`${InspectionReport.reportDate['year']}-${InspectionReport.reportDate['month']}-${InspectionReport.reportDate['day']}`),
        endDate: InspectionReport.endDate ? new Date(`${InspectionReport.endDate['year']}-${InspectionReport.endDate['month']}-${InspectionReport.endDate['day']}`) : null,
        statementsOfPartsCheckBy: statementsOfPartsComment ? this.user.userCode : null,
        findingsFoundCheckBy: findingsFoundComment ? this.user.userCode : null,
        checksCheckBy: checksComment ? this.user.userCode : null,
        orientationToThePartsCheckBy: orientationToThePartsComment ? this.user.userCode : null,
        endResultCheckBy: endResultComment ? this.user.userCode : null,
        statusChangesDate: new Date(`${InspectionReport.statusChangesDate['year']}-${InspectionReport.statusChangesDate['month']}-${InspectionReport.statusChangesDate['day']}`)
      }
    }
     
    return { ...InspectionReport, ...mapInspection }

  }
  cleanForm() {
    this.inspectionReportForm.reset(this.inspectionCopy.value)
  }



  // otherJustification() {
  //   let option = this.inspectionReportForm.get('justificationChangeStatusId').value;

  //   if (this.recordJustifications?.find(x => x.value == option)?.hasOptionalField == true) {

  //     this.justification = true;
  //     this.inspectionReportForm.get('justificationChangeStatusOther').setValidators([Validators.required])
  //     this.inspectionReportForm.get('justificationChangeStatusOther').updateValueAndValidity();

  //   }
  //   else {
  //     this.justification = false;
  //     this.inspectionReportForm.get('justificationChangeStatusOther').clearValidators()
  //     this.inspectionReportForm.get('justificationChangeStatusOther').updateValueAndValidity();
  //   }
  // }

  sendNotification(userCode, inspectionReportNumber) {
    let statusId = this.inspectionReportForm.get('reportStatusId').value;

    if (this.recordStatus?.find(x => x.value == statusId)?.inReview == true && this.inspectionReport.reportStatusId !== statusId) {
      this.Http.post({ userCode, inspectionReportNumber }, 'EmailSender/UserSupervisorInspectionReport').toPromise()
    }
  }

  ngOnDestroy(): void {
  }
  notFound() {
    this.show = true;
    setTimeout(() => {
      this.show = false
    }, 1000)
  }

  filterStatus() {
    this.statusCopy = [...this.recordStatus];
    if (this.user.roleCode == "INSP") {
      this.recordStatus = this.recordStatus.filter(el => {
        if (
          (this.getCurrentState() == 'DVRLT' && this.inspectionReport && (el.alternateField == 'REDAC' || el.alternateField == 'REVIS'))
        ) {
          return true;
        }
        else if (this.getCurrentState() == 'IRSUP' && this.inspectionReport && el.alternateField == 'FIZDO') {
          this.canEdit = false
          return true
        }
        else if ((this.getCurrentState() !== 'DVRLT' || this.getCurrentState() == 'FIZDO') && this.getCurrentState() !== 'REDAC' && this.getCurrentState() !== 'IRSUP') {
          if (this.inspectionReport) {
            this.canEdit = false
          }
        }

        else if (this.getCurrentState() !== 'IRSUP' && this.inspectionReport && el.alternateField == 'REVIS') { return true }
        else if (!this.inspectionReport && el.alternateField == 'REVIS') { return true }
        else {
          return false
        }
      }
      )
    } else if (this.user.roleCode == "SUP") {
      if (this.getCurrentState() == 'FIZDO') {
        this.canEdit = false
      }
      this.recordStatus = this.recordStatus.filter(el => {
        if (this.getCurrentState() == 'REDAC') {
          return false
        }
        else if (el.alternateField !== 'ARCDO' && el.alternateField !== 'FIZDO' && el.alternateField !== 'REDAC' && el.alternateField !== 'REVIS') {
          return true
        }
      })
    }
    else if (this.user.roleCode == "REPLO" || this.user.roleCode == "DIR") {
      if (this.inspectionReport) {
        this.recordStatus = this.recordStatus.filter(el => el.alternateField !== 'REDAC' && el.alternateField !== 'IRSUP' && el.alternateField !== 'REVIS')
      } else {
        this.recordStatus = []
      }
    }
    if (!this.inspectionReport) {
      // this.recordStatus.push(this.statusCopy.find(el => el.alternateField == 'REDAC'));
      let REDAC = this.statusCopy.find(el => el.alternateField == 'REDAC')
      this.directDrop.id = REDAC.value;
      this.directDrop.text = REDAC.text;
      this.inspectionReportForm.patchValue({
        'reportStatusId': REDAC.value
      })
    }
    if (this.inspectionReport) {
      let currectStatus = this.inspectionReport.reportStatusId;
      if (this.getCurrentState() == 'REDAC') {
        this.recordStatus.push(this.statusCopy.find(el => el.alternateField == 'REDAC'));
      }
      if (!this.recordStatus.find(el => el.value == currectStatus)) {
        let statusLost = this.statusCopy.find(el => el.value == currectStatus);
        this.directDrop.id = statusLost.value
        this.directDrop.text = statusLost.text
      }
    }
  }
  async changeState() {
    let code = this.recordStatus.find(el => el.value == this.inspectionReportForm.get('reportStatusId').value).alternateField
    let value = this.inspectionReportForm.get('reportStatusId').value
    let ServiceOrderNumber = this.serviceOrderNumber ? this.serviceOrderNumber : this.inspectionReportForm.get('serviceOrderNumber').value
    if (code == 'ARCDO') {
      await this.Http.patch({ statusOrderServiceCode: 'ARCDO' }, `ServiceOrderFirstStep/StatusServiceOrder/${ServiceOrderNumber}/${'ARCDO'}`).toPromise()
      this.router.navigate(['Casos/historial']);
    }
  }

  getCurrentState(): string {
    let id = this.inspectionReport?.reportStatusId
    return this.recordStatus.find(el => el.value == id)?.alternateField
  }
  checkStatus() {

    let value = this.inspectionReportForm.get('reportStatusId').value
    let option = this.recordStatus.find(el => el.value == value);
    if (!option) {
      this.directDrop = {
        text: this.inspectionReport.reportStatusInfo,
        id: this.inspectionReport.reportStatusId
      }
    }
    else {
      this.directDrop = {
        text: null,
        id: this.inspectionReport.reportStatusId
      }
    }
  }


  preSelect() {
    let REDAC = this.recordStatus.find(el => el.alternateField == 'REDAC').value
    this.inspectionReportForm.patchValue({
      reportStatusId: REDAC
    })

  }

  selectLocalRep() {
    const code = this.DirectedToDrop.find(el => el.isLocapRep == true).code
    this.inspectionReportForm.patchValue({ 'directedTo': code })
  }

  clean() {
    if (this.isFilter) {
      this.inspectionReportForm.patchValue({
        printedReport: null,
        directedTo: null
      })
    }
    else {
      this.inspectionReportForm.patchValue({
        inspectionReportId: null,
        serviceOrderNumber: null,
        inspectionReportNumber: null,
        reportDate: null,
        statementsOfParts: null,
        statementsOfPartsComment: null,
        statementsOfPartsCheckBy: null,
        findingsFound: null,
        findingsFoundComment: null,
        findingsFoundCheckBy: null,
        checks: null,
        checksComment: null,
        checksCheckBy: null,
        orientationToTheParts: null,
        orientationToThePartsComment: null,
        orientationToThePartsCheckBy: null,
        endResult: null,
        endResultComment: null,
        endResultCheckBy: null,

        directedTo: null,
        endDate: null,
        printedReport: null,
        registeredBy: null,
        modifiedBy: null,
        recordStatus: true,
        registrationDate: null,
        recordModificationDate: null
      })
    }
  }


}