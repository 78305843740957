import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ArchwizardModule } from 'angular-archwizard';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { ServiceComponent } from './service/service.component';



import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/es";
import localeDeExtra from "@angular/common/locales/extra/es";
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TagInputModule } from 'ngx-chips';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { AuthChildGuardService } from '../auth/_services/auth-child-guard.service';
import { CanExitGuard } from './Guards/can-exit.guard';
import { RoleGuard } from './Guards/role.guard';
import { serviceRoutingModule } from './service-routing.module';
import { AdvancedFilterComponent } from './service/advance-Filters/advanced-filter/advanced-filter.component';
import { AdvancedInspectionFilterComponent } from './service/advance-Filters/advanced-inspection-filter/advanced-inspection-filter.component';
import { AdvancedProgramFilterComponent } from './service/advance-Filters/advanced-program-filter/advanced-program-filter.component';
import { AdvancedUserServiceFilterComponent } from './service/advance-Filters/advanced-user-service-filter/advanced-user-service-filter.component';
import { FormsCreationComponent } from './service/forms-creation/forms-creation.component';
import { InfractionHistoryComponent } from './service/forms-history/infraction-history/infraction-history.component';
import { InspectionHistoryComponent } from './service/forms-history/inspection-history/inspection-history.component';
import { OrderNotesHistoryComponent } from './service/forms-history/order-notes-history/order-notes-history.component';
import { VisitHistoryComponent } from './service/forms-history/visit-history/visit-history.component';
import { WarningReportHistoryComponent } from './service/forms-history/warning-report-history/warning-report-history.component';
import { InspectionProgramFormComponent } from './service/inspection-program/inspection-program-form/inspection-program-form.component';
import { InspectionProgramComponent } from './service/inspection-program/inspection-program.component';
import { RouteHistoryComponent } from './service/inspection-program/route-history/route-history.component';
import { routeDataService } from './service/inspection-program/visit-route/routeData.service';
import { VisitRouteComponent } from './service/inspection-program/visit-route/visit-route.component';
import { RecordHistoryComponent } from './service/record-history/record-history.component';
import { ServiceOrderComponent } from './service/service-order/service-order.component';
import { UserServiceFormComponent } from './service/user-service-form/user-service-form.component';
import { AttachedDocumentsComponent } from './serviceOrderForm/attached-documents/attached-documents.component';
import { CompanyDataComponent } from './serviceOrderForm/company-data/company-data.component';
import { companyEdit } from './serviceOrderForm/company-data/companyEdit.service';
import { serviceOrderForm } from './serviceOrderForm/General-data/serviceOrderForm.component';
import { infractionReportComponent } from './serviceOrderForm/infraction-report/infraction-report.component';
import { InfractionReportService } from './serviceOrderForm/infraction-report/infraction-report.service';
import { InspectionReportComponent } from './serviceOrderForm/inspection-report/inspection-report.component';
import { InspectionReportService } from './serviceOrderForm/inspection-report/inspection-report.service';
import { OrderAssignmentComponent } from './serviceOrderForm/order-assignment/order-assignment.component';
import { OrderNotesComponent } from './serviceOrderForm/order-notes/order-notes.component';
import { OrderWorkerNewComponent } from './serviceOrderForm/order-worker-new/order-worker-new.component';
import { OrderWorkerComponent } from './serviceOrderForm/order-worker/order-worker.component';
import { VisitDataComponent } from './serviceOrderForm/visit-data/visit-data.component';
import { visitDataService } from './serviceOrderForm/visit-data/visitData.service';
import { WarningDocumentService } from './serviceOrderForm/warning-report/warning-document.service';
import { WarningReportComponent } from './serviceOrderForm/warning-report/warning-report.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

registerLocaleData(localeDe, "es", localeDeExtra);

@NgModule({
    declarations: [serviceOrderForm, ServiceComponent, ServiceOrderComponent, OrderAssignmentComponent, RecordHistoryComponent, OrderWorkerComponent, FormsCreationComponent, CompanyDataComponent, AttachedDocumentsComponent, VisitDataComponent, VisitHistoryComponent, InspectionProgramComponent, WarningReportComponent, WarningReportHistoryComponent, infractionReportComponent, InfractionHistoryComponent, InspectionHistoryComponent, UserServiceFormComponent, OrderNotesComponent, OrderNotesHistoryComponent, InspectionReportComponent, InspectionHistoryComponent, AdvancedFilterComponent, AdvancedProgramFilterComponent, AdvancedUserServiceFilterComponent, AdvancedInspectionFilterComponent, InspectionProgramFormComponent, VisitRouteComponent, RouteHistoryComponent, OrderWorkerNewComponent],
    imports: [TagInputModule, SharedModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ArchwizardModule,
        NgMultiSelectDropDownModule,
        NgxMaskModule.forChild(),
        serviceRoutingModule,
        FileUploadModule,
        CurrencyMaskModule, DropdownModule,
        ChipsModule,
        ToggleButtonModule,
        TabViewModule,
        ButtonModule,
        CalendarModule,
        TooltipModule,
        MultiSelectModule,
        ProgressSpinnerModule,
    ], schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    exports: [serviceOrderForm, ServiceOrderComponent],
    providers: [{ provide: LOCALE_ID, useValue: "es-Es" }, CanExitGuard, visitDataService, InfractionReportService, WarningDocumentService, RoleGuard, InspectionReportService, companyEdit, AuthChildGuardService, routeDataService, NgbActiveModal
    ]
})
export class ServiceModule { }
