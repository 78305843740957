import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-program-visit-report',
  templateUrl: './program-visit-report.component.html',
  styleUrls: ['./program-visit-report.component.css'],
  animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ]

})
export class ProgramVisitReportComponent implements OnInit {
  supervisors: any[];
  urlApi: any;
  
  constructor(private translate: TranslateService, private config: PrimeNGConfig,
    private FB: FormBuilder, public tool: ToolsService, private http: HttpClientService,
    private httP: HttpClient, private el: ElementRef,) {

    this.urlApi = environment.api_url;
    this.translate.setDefaultLang('es');
  }
  
  localRepresentative: any[];
  inspectors: DropDownOptionModel[];
  programVisitForm: FormGroup;
  show: boolean = false
  localReps: DropDownOptionModel[];
  maxDate = moment().toDate()
  async ngOnInit() {
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));
    this.programVisitForm = this.FB.group({
      repLocalProvCode: new FormControl(null, Validators.required),
      userCode: new FormControl(null, Validators.required),
      date: new FormControl(null, Validators.required),
      supervisorCode: new FormControl(null, Validators.required),
      localRepresentativeCode: new FormControl(null, Validators.required),
    })
    await this.getDropdowns()
  }

  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }


  async getDropdowns() {
    this.localReps = await this.http.get<DropDownOptionModel[]>(`LocalRepresentativeProvince`).toPromise();
    this.inspectors = await this.http.get<DropDownOptionModel[]>(`User/UserResumeByRolAndRepresentant?rolId=2&repLocalProvCode=${this.programVisitForm.get('repLocalProvCode').value}`).toPromise();

  }

  generateReport() {

    if (this.programVisitForm.invalid) {
      this.tool.createWarning(this.programVisitForm, this.el).then(result => this.notFound())
    } else {
      const { date, repLocalProvCode, userCode, supervisorCode, localRepresentativeCode } = this.programVisitForm.value
      let [startDate, endDate] = date;
      endDate = moment(endDate).format('MM/DD/YYYY').toString();
      startDate = moment(startDate).format('MM/DD/YYYY').toString();
      this.tool.showLoading(`${this.urlApi}/ExcelReports/SurpriseVisitReport?LocalRepresentativeCode=${repLocalProvCode}&UserCode=${userCode}&SupervisorCode=${supervisorCode}
      &RltUserCode=${localRepresentativeCode}&FromDate=${startDate}&ToDate=${endDate}`)
      
    }

  }


  async getRepresentative(event) {
    this.http.get<any[]>(`User/UserByRolAndRepresentant/?rolId=4&represenLocaId=${event}`).subscribe((response) => {
      this.localRepresentative = response.map(LR => {
        return { text: LR.fullName, value: LR.userCode }
      });
      this.programVisitForm.patchValue({
        'supervisorCode': null,
        'userCode': null,
        'localRepresentativeCode': null
      })
    });

  }
  async getSupervisor(event) {
    this.supervisors = [];
    let data = await this.http.get<any>(`User/SupervisorsByLocalRepresentativeUserCode/${event}`).toPromise()
    this.supervisors = data;
    this.programVisitForm.patchValue({
      'supervisorCode': null,
      'userCode': null
    })
  }

  async getInspector(event) {
    this.inspectors = [];
    this.programVisitForm.patchValue({
      'userCode': null
    })
    this.inspectors = await this.http.get<any>(`User/InspectorsBySupervisorCode/${event}`).toPromise()
  }

}
