import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule, } from '@angular/forms';    //Added by me for using Data-binding
import { CommonModule } from '@angular/common'; //Added by me for use ngIf
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';

/*Charts Reporting*/

/*Routes*/
import { routing, appRoutingProviders } from './app.routing';

//Components
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BreadcrumService } from './_services/breadcrum.service';
import { ArchwizardModule } from 'angular-archwizard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceModule } from './modules/service/service.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeComponent } from './components/home/home.component';
import { AuthModule } from './modules/auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './modules/auth/_interceptors/auth-interceptor';
import { AuthGuardService } from './modules/auth/_services/auth-guard.service';
import { JwtModule } from '@auth0/angular-jwt';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AuthModalComponent } from './modules/auth/auth-modal/auth-modal.component';
import { SesionServiceService } from './_services/sesion-service.service';
import { IdleExpiry, LocalStorageExpiry } from '@ng-idle/core';
import { ButtonModule } from 'primeng/button';
import { ConnectionServiceModule } from 'ngx-connection-service';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { AvatarModule } from 'primeng/avatar';
import { ReportsModule } from './modules/reports/reports.module';
import { AdminModule } from './modules/admin/admin.module';
import { DragDropModule } from '@angular/cdk/drag-drop';


export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    NavbarComponent,
    HomeComponent,
    AuthModalComponent,

  ],
  imports: [

    NgxMaskModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AvatarModule,
    ServiceModule,
    AuthModule,
    ReportsModule,
    DashboardModule,
    ButtonModule,
    LoadingBarRouterModule,
    routing,
    NgbModule,
    LoadingBarModule,
    ArchwizardModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    ToastrModule.forRoot(),

    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
    JwtModule.forRoot({
      config: {
        // ...
        tokenGetter: () => {
          return localStorage.getItem('token');
        },
      },
    }),
    NgIdleKeepaliveModule.forRoot(),
    LeafletModule
    ,
    CurrencyMaskModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ConnectionServiceModule,
    AdminModule,
    DragDropModule
  ],
  providers: [appRoutingProviders, BreadcrumService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    , AuthGuardService, SesionServiceService, {
      provide: IdleExpiry,
      useClass: LocalStorageExpiry,

    },],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

