import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../auth/_services/user.service';
import { DropDownOptionModel } from '../../shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { HttpClientService } from '../../shared/_services/http-client/http-client.service';
import { SweetAlertService } from '../../shared/_services/sweetAlert/sweet-alert.service';
import { ToastService } from '../../shared/_services/toast/toast.service';
import { ToolsService } from '../../shared/tools/tools.service';
import { TableUser } from '../models/tableUser';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit {

  @Input() currentUser: TableUser;
  title: string
  localReps: DropDownOptionModel[];
  roles: any[] = [];
  currentPersonAssigneess: [] = [];
  superiors: any[] = [];
  userToTransfer: string = null;
  loadingLists: boolean = true;
  sameRoleUsers: any[] = []
  sourceUserList: any[] = []
  targetUserList: any[] = []
  currentCopy: TableUser;
  userToTransferName: string;
  isSaving: boolean = false;
  constructor(public ngbActiveModal: NgbActiveModal, private fb: FormBuilder, private user: UserService,
    private http: HttpClientService, private toast: ToastService, private sweet: SweetAlertService, private tool: ToolsService) {
  }

  async ngOnInit() {
    this.getDirectSuperiors();

    this.title = this.currentUser.userName
    // consol e.log(this.currentUser);
    this.currentCopy = cloneDeep(this.currentUser)
    await this.getDropdowns();
  }

  async getDirectSuperiors(cleanSupervisor?: boolean) {
    if (cleanSupervisor) {
      this.currentUser.userSupervisorCode = null;
    }
    this.http.get<any[]>(`User/Superiors?roleCode=${this.currentUser.roleCode}&repLocalCode=${this.currentUser.repLocalProvCode}`)
      .subscribe(superiors => this.superiors = superiors.filter(user => user.userCode !== this.currentUser.userCode));

  }

  async getDropdowns() {

    const localRepP = this.http.get<DropDownOptionModel[]>(`LocalRepresentativeProvince`).toPromise();
    const rolesP = this.http.get<{ roleCode: string, roleInfo: string }[]>(`Role?status=true`).toPromise();
    const sameRoleUsersP = this.http.get<any[]>(`User/UserByRolAndRepresentant?rolId=${this.currentCopy.roleId}&repLocalProvId=${this.currentCopy.repLocalProvId}`).toPromise();
    let [localRep, roles, sameRoleUsers] = await Promise.all([localRepP, rolesP, sameRoleUsersP])
    this.localReps = localRep;
    this.roles = roles;
    this.sameRoleUsers = sameRoleUsers.filter(user => user.userCode !== this.currentCopy.userCode);;
  }

  getAssignedUsers() {
    console.log(this.userToTransfer);
    this.userToTransferName = this.sameRoleUsers.find(user => user.userCode == this.userToTransfer)?.fullName
    this.loadingLists = true

    this.http.get<any[]>(`User/AssignedUsers/${this.userToTransfer}`).subscribe(
      {
        next: users => {
          this.sourceUserList = users;
          this.loadingLists = false
        }, error: err => {
          if (err.error.error.errorCode == 404) {
            this.sourceUserList = [];
            this.loadingLists = false
          } else {
            this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible');
          }
        }
      }
    )

  }


  async transferUsers() {
    this.isSaving = true;
    const UsersTotransfer = this.mapList(this.targetUserList);

    try {
      await this.http.update(UsersTotransfer, `User/UsersSupervisor?supervisorCode=${this.currentUser.userCode}`).toPromise()

      this.sweet.record('success', ``, 'Usuarios transferidos exitosamente', ``)
      this.isSaving = false
      this.userToTransfer = null;
      this.targetUserList = [];
      this.ngbActiveModal.close(true)
    }
    catch (err) {
      this.isSaving = false
      this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
    }
  }

  changeRole() {
    this.http.update<any[]>({}, `User/UserRole?RoleCode=${this.currentUser.roleCode}&UserCode=${this.currentUser.userCode}&RepLocalProvCode=${this.currentUser.repLocalProvCode}&UserSupervisorCode=${this.currentUser.userSupervisorCode}`).subscribe(
      {
        next: users => {
          this.sweet.record('success', ``, 'Usuario Editado Correctamente', ``)
          this.ngbActiveModal.close(true)
        }, error: err => {

          if (err.error.error.errorCode == 400) {
            let names = (err.error.data as Array<any>)?.map(user => user.userName)
            this.tool.createPendingWarning(names, 'Los siguientes usuarios deberan ser reasignados', 'Usuarios Pendientes');

          } else {
            this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible');
          }
        }
      }
    )
  };

  mapList(userList: any[]) {
    return userList.map(el => {
      return { userCode: el.userCode, prevSupervisorCode: el.prevSupervisorCode }
    })
  }
}
