<div class="container-fluid h-100">
  <div class="row d-flex justify-content-around justify-content-lg-start">
    <div class="row col-12 col-sm-6">
      <label class="form-control-lg title pl-0"
        >TIPO&nbsp;DE&nbsp;COLECCIÓN</label
      >
      <br />
      <label for="" class="pl-0">Seleccione el Tipo de Colección</label>
      <el-dropdown
        style="font-weight: 600; padding-left: 0px"
        [options]="forms"
        (onChange)="route($event)"
        customClass="form-control form-control-lg colection-type mainDrop"
        [(ngModel)]="selection"
        placeHolder="Elige un tipo de colección"
      ></el-dropdown>
    </div>
    <div class="row col-12"></div>
  </div>
  <div class="row mt-3 mb-3 w-100" *ngIf="selected">
    <div class="divider"></div>
  </div>
  <div class="form-group">
    <div class="col-12 h-100 pl-sm-0">
      <router-outlet></router-outlet>
    </div>
  </div>
  <br />
  <div class="row col-12" *ngIf="!selected">
    <label class="">INFORMACI&Oacute;N&nbsp;GENERAL</label>
    <div class="alert alert-primary css-alert-light" role="alert">
      <span
        ><i
          class="fa fa-info-circle faa-flash animated faa-slow"
          aria-hidden="true"
        ></i
        >&nbsp;Debe selecconar el tipo de registro para colocar la
        informaci&oacute;n general</span
      >
    </div>
  </div>
</div>
