import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from "primeng/table";

import { TranslateModule } from '@ngx-translate/core';
import { FileSaverModule } from "ngx-filesaver";
import { NgxMaskModule } from "ngx-mask";
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ChipsModule } from 'primeng/chips';
import { } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { RippleModule } from 'primeng/ripple';
import { SliderModule } from "primeng/slider";
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
export const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TableModule,
    MultiSelectModule,
    ContextMenuModule,
    SliderModule,
    DialogModule,
    DropdownModule,
    ProgressBarModule,
    InputTextModule,
    NgxMaskModule.forRoot(),
    FileUploadModule,
    TranslateModule,
    MessagesModule,
    MessageModule,
    RippleModule,
    BadgeModule,
    ChipsModule,
    AvatarModule,
    InputTextModule,
    ToggleButtonModule,
    TabViewModule,
    TreeModule,
    FileSaverModule,
    TooltipModule
]