import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SharedModule } from 'primeng/api';
import { SesionServiceService } from 'src/app/_services/sesion-service.service';
import { authRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';



@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    authRoutingModule,
    ReactiveFormsModule,
    FormsModule,

  ],
  providers: [JwtHelperService, SesionServiceService]
})
export class AuthModule { }
