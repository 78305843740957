<div *ngIf="loading && !inspectionProgramId; else load">
  <div class="spinnerListContainer">
    <div class="spinner-loading"></div>
    <div class="spinner-text">Cargando</div>
  </div>
</div>

<ng-template #load>
  <div class="container-fluid pl-0" *ngIf="!isEdit; else edit">
    <div *ngIf="!visitResultId && !inspectionProgramId; else modal">
      <serviceOrderForm-Component
        #CreateServiceOrder
        [serviceOrderInput]="serviceOrder"
      ></serviceOrderForm-Component>
      <div class="btn-group float-right m-3">
        <button
          type="button"
          class="rounded-pill btn btn-save px-5"
          (click)="
            saveRecord(
              CreateServiceOrder.serviceOrder.value,
              CreateServiceOrder.serviceOrder,
              CreateServiceOrder.elem
            )
          "
        >
          Guardar
        </button>
        <!-- <button type="button" class="btn btn-primary" (click)="saveRecord()">
         Guardar
         </button> -->
      </div>
    </div>
    <ng-template #modal>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Creando Orden desde Resultado
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="ActiveModal.close()"
        ></button>
      </div>
      <div class="modal-body">
        <serviceOrderForm-Component
          #CreateServiceOrder
          [inspectionProgramId]="inspectionProgramId"
          [visitResultId]="visitResultId"
          [serviceOrderInput]="serviceOrder"
        ></serviceOrderForm-Component>
        <div class="btn-group float-right m-3">
          <button
            type="button"
            class="rounded-pill btn btn-save px-5"
            (click)="
              saveRecord(
                CreateServiceOrder.serviceOrder.value,
                CreateServiceOrder.serviceOrder,
                CreateServiceOrder.elem
              )
            "
          >
            Guardar
          </button>
          <!-- <button type="button" class="btn btn-primary" (click)="saveRecord()">
         Guardar
         </button> -->
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #edit>
    <div class="container-fluid" *ngIf="!fillAll; else fillAll">
      <serviceOrderForm-Component
        [canEdit]="canDigitEdit"
        #editServiceOrder
        [serviceOrderInput]="serviceOrder"
      ></serviceOrderForm-Component>
      <div class="btn-group float-right m-3">
        <button
          type="button"
          class="rounded-pill btn btn-save px-5"
          *ngIf="canDigitEdit"
          (click)="
            updateRecord(
              editServiceOrder.serviceOrder.value,
              editServiceOrder.serviceOrder,
              editServiceOrder.elem

            )
          "
        >
          Guardar
        </button>
        <!-- <button type="button" class="btn btn-primary" (click)="saveRecord()">
            Guardar
            </button> -->
      </div>
    </div>
    <ng-template #fillAll>
      <div class="container-fluid">
        <div class="row pe-4">
          <div
            [class]="
              'm-2  w-100 me-2 css-legend-title' +
              ' ' +
              'orderInfo' +
              serviceOrder.cssClosingDateStatus
            "
          >
            <div class="row my-3">
              <div
                class="
                  col-md-1 col-sm-12
                  d-none d-flex
                  justify-content-sm-start justify-content-center
                  align-items-center
                  iconContainer
                "
              >
                <i
                  [class]="
                    'pi pi-info-circle fa-2x  info' +
                    ' ' +
                    serviceOrder.cssClosingDateStatus
                  "
                ></i>
              </div>
              <div class="col-md-2 col-sm-12">
                <label for="closeDate" class="infoLabel">FEC. CIERRE</label>
                <h3 id="closeDate" class="infoText">
                  {{
                    serviceOrder.newEstimatedDeadlineStr
                      ? serviceOrder.newEstimatedDeadlineStr
                      : serviceOrder.estimatedDeadLineStr
                  }}
                </h3>
              </div>
              <div class="col-md-2 col-sm-12">
                <label for="orderNumber" class="infoLabel">NO. ORDEN</label>
                <h3 id="orderNumber" class="infoText backgreen">
                  {{ serviceOrder.serviceOrderNumber }}
                </h3>
              </div>
              <div class="col-md-2 col-sm-12">
                <label for="registerBy" class="infoLabel">REG. POR</label>
                <h3 id="registerBy" class="infoText">
                  {{ serviceOrder.registeredByName }}
                </h3>
              </div>
              <div class="col-md-2 col-sm-12">
                <label for="assignBy" class="infoLabel">ASIG. POR</label>
                <h3 id="assignBy" class="infoText">
                  {{
                    serviceOrderAssignment?.registeredByName
                      ? serviceOrderAssignment?.registeredByName
                      : "N/A"
                  }}
                </h3>
              </div>
              <div class="col-md-2 col-sm-12">
                <label for="modifyBy" class="infoLabel">MODIFI. POR</label>
                <h3 id="modifyBy" class="infoText">
                  {{ serviceOrder.modifiedByName }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-md-4 col-sm-12 mb-1"
            [hidden]="!serviceOrderAssignment"
          >
            <label class="form-label form-control-lg">Agregar Colección </label>
            <el-dropdown
              [options]="additionalForms"
              [(ngModel)]="additionalForm"
              [button]="addiontalFormsButton"
              [placeHolder]="'Elige una colección'"
              [disabled]="!canEdit"
            ></el-dropdown>
          </div>

          <div class="col-md-4 col-sm-12 mb-1">
            <label class="form-label form-control-lg">Cambiar Estado </label>
            <el-dropdown
              [options]="status"
              customClass="form-control form-control-md"
              [(ngModel)]="actualState"
              placeHolder="Elige un estado"
              [disabled]="!canEdit || (serviceOrderAssignment?.assignedInspectorId && serviceOrderAssignment?.assignedInspectorId !== currentUser.userId)"
            ></el-dropdown>
          </div>
          <div class="col-md-4 col-sm-12 mb-1">
            <label class="form-label form-control-lg"
              >Programa Inspección
            </label>
            <el-dropdown
              [options]="inspectionPrograms"
              [(ngModel)]="actualInspectionProgram"
              customClass="form-control form-control-md"
              placeHolder="Elige un programa de inspección"
              [disabled]="!canEdit"
            ></el-dropdown>
          </div>
        </div>
        <div class="accordion m-1 mb-2" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <div class="checkIcon d-flex m-2">
                  <i
                    *ngIf="!orderValid; else orderNotValid"
                    class="far fa-check-circle text-success"
                  ></i>
                  <ng-template #orderNotValid
                    ><i class="far fa-times-circle text-danger"></i
                  ></ng-template>
                </div>
                <h4>Informaci&oacute;n General</h4>
                <!-- <span class="badge badge-success text-centerd m-1">{{ serviceOrder.serviceOrderNumber }}</span> -->
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <div class="accordion-body">
                  <serviceOrderForm-Component
                    [canEdit]="canEdit"
                    (itChange)="serviceOrderChanged = $event"
                    #AssignServiceOrder
                    [serviceOrderInput]="serviceOrder"
                    (valid)="orderValid = $event"
                    (hasJustification)="hasJustification = $event"
                  ></serviceOrderForm-Component>
                </div>
              </div>
            </div>
          </div>
          <div
            class="accordion-item"
             
          >
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <div class="checkIcon m-2">
                  <i
                    *ngIf="!companyDataValid; else orderNotValid"
                    class="far fa-check-circle text-success"
                  ></i>
                  <ng-template #orderNotValid
                    ><i class="far fa-times-circle text-danger"></i
                  ></ng-template>
                </div>
                <h4>Datos de la Empresa</h4>
              </button>
            </h2>
            <div
              id="collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <app-company-data
                  #companyDataTemplate
                  [assignmentEdit]="assignmentEdit"
                  [canEdit]="canEdit"
                  [companyData]="companyData"
                  (itChange)="companyChanged = $event"
                  [serviceOrder]="serviceOrder"
                  (valid)="companyDataValid = $event"
                  [companyInfo]="companyInfo"
                  (activityBranchRLTIdChanges) = "getActivityBranchRLTId($event)"
                >
                </app-company-data>
              </div>
            </div>
          </div>          
          <div class="accordion-item" [hidden]="!companyData">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <div class="checkIcon m-2">
                  <i
                    *ngIf="!assignmentValid; else orderNotValid"
                    class="far fa-check-circle text-success"
                  ></i>
                  <ng-template #orderNotValid
                    ><i class="far fa-times-circle text-danger"></i
                  ></ng-template>
                </div>
                <h4>Asignar Orden / Datos de la RLT</h4>
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <app-order-assignment
                  #OrderAssigment
                  [canEdit]="canEdit"
                  [serviceOrder]="serviceOrder"
                  (itChange)="orderAssignmentChanged = $event"
                  [orderAssignment]="serviceOrderAssignment"
                  (valid)="assignmentValid = $event"
                  (createdOrderAssignment)="AssignmentValue = $event"
                  [isCompanyData]="!companyData"
                ></app-order-assignment>
              </div>
            </div>
          </div>
          <div class="accordion-item" [hidden]="!serviceOrderAssignment">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <div class="checkIcon m-2">
                  <i
                    *ngIf="!workerDataValid; else orderNotValid"
                    class="far fa-check-circle text-success"
                  ></i>
                  <ng-template #orderNotValid
                    ><i class="far fa-times-circle text-danger"></i
                  ></ng-template>
                </div>
                <h4>Datos de la Persona Trabajadora</h4>
                <div class="formsNumber ms-2">
                  <p>( {{ workerDataTemplate?.amount }} )</p>
                </div>                
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <app-order-worker
                  #workerDataTemplate
                  [canEdit]="canEdit"
                  [assignmentEdit]="assignmentEdit"
                  [workerData]="workerData"
                  (itChange)="workerChanged = $event"
                  [serviceOrder]="serviceOrder"
                  (valid)="workerDataValid = $event"
                ></app-order-worker>
              </div>
            </div>
          </div>
          <div class="accordion-item" [hidden]="!serviceOrderAssignment">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <div class="checkIcon m-2">
                  <i class="far fa-check-circle text-success"></i>
                </div>
                <h4>Documentos Anexados</h4>
                <div class="formsNumber ms-2">
                  <p>( {{ docs?.uploadedFiles?.length ?? 0 }} )</p>
                </div>
              </button>
            </h2>
            <div
              id="collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <!-- <app-file-uploader
                     #docs
                     toolbar="Documentos Anexos/Documentos de Inspeccion"
                     ></app-file-uploader> -->
                <app-attached-documents
                  [canEdit]="canEdit"
                  [serviceOrder]="serviceOrder"
                  #docs
                >
                </app-attached-documents>
              </div>
            </div>
          </div>
          <div
            class="accordion-item"
            *ngIf="visitData?.length || visitDataEdit"
          >
            <h2 class="accordion-header" id="headingsix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsesix"
                aria-expanded="false"
                aria-controls="collapsesix"
              >
                <div class="checkIcon m-2">
                  <i class="far fa-check-circle text-success"></i>
                </div>
                <h4>Datos de Visita</h4>
                <div class="formsNumber ms-2">
                  <p>( {{ visit?.amount }} )</p>
                </div>
              </button>
            </h2>
            <div
              id="collapsesix"
              class="accordion-collapse collapse"
              aria-labelledby="headingsix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <app-visit-history
                  [serviceOrder]="serviceOrder"
                  #visit
                  [canEdit]="canEdit"
                  (empty)="emptyVisit($event)"
                ></app-visit-history>
              </div>
            </div>
          </div>
          <div
            class="accordion-item"
            *ngIf="warningReport?.length || warningReportEdit"
          >
            <h2 class="accordion-header" id="headingseven">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseseven"
                aria-expanded="false"
                aria-controls="collapseseven"
              >
                <div class="checkIcon m-2">
                  <i class="far fa-check-circle text-success"></i>
                </div>
                <h4>Actas de Apercibimiento</h4>
                <div class="formsNumber ms-2">
                  <p>( {{ warning?.amount }} )</p>
                </div>
              </button>
            </h2>
            <div
              id="collapseseven"
              class="accordion-collapse collapse"
              aria-labelledby="headingseven"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <app-warning-report-history
                  [serviceOrder]="serviceOrder"
                  #warning
                  [canEdit]="canEdit"
                  (empty)="emptyWarning($event)"
                ></app-warning-report-history>
              </div>
            </div>
          </div>
          <div
            class="accordion-item"
            *ngIf="infractionReport?.length || infractionReportEdit"
          >
            <h2 class="accordion-header" id="headingeight">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseeight"
                aria-expanded="false"
                aria-controls="collapseeight"
              >
                <div class="checkIcon m-2">
                  <i class="far fa-check-circle text-success"></i>
                </div>
                <h4>Actas de Infracción</h4>
                <div class="formsNumber ms-2">
                  <p>( {{ infraction?.amount }} )</p>
                </div>
              </button>
            </h2>
            <div
              id="collapseeight"
              class="accordion-collapse collapse"
              aria-labelledby="headingeight"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <app-infraction-history
                  [canEdit]="canEdit"
                  [serviceOrder]="serviceOrder"
                  #infraction
                  (empty)="emptyInfraction($event)"
                ></app-infraction-history>
              </div>
            </div>
          </div>
          <div
            class="accordion-item"
            *ngIf="orderNotes?.length || orderNotesEdit"
          >
            <h2 class="accordion-header" id="headingnine">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsenine"
                aria-expanded="false"
                aria-controls="collapsenine"
              >
                <div class="checkIcon m-2">
                  <i class="far fa-check-circle text-success"></i>
                </div>
                <h4>Notas</h4>
                <div class="formsNumber ms-2">
                  <p>( {{ notes?.amount }} )</p>
                </div>
              </button>
            </h2>
            <div
              id="collapsenine"
              class="accordion-collapse collapse"
              aria-labelledby="headingnine"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <app-order-notes-history
                  [serviceOrder]="serviceOrder"
                  #notes
                  [canEdit]="canEdit"
                  (empty)="emptyNotes($event)"
                ></app-order-notes-history>
              </div>
            </div>
          </div>
          <div
            class="accordion-item"
            *ngIf="inspectionReport || inspectionReportEdit"
          >
            <h2 class="accordion-header" id="headingten">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseten"
                aria-expanded="false"
                aria-controls="collapseten"
              >
                <div class="checkIcon m-2">
                  <i class="far fa-check-circle text-success"></i>
                </div>
                <h4>Informe de Inspección</h4>
              </button>
            </h2>
            <div
              id="collapseten"
              class="accordion-collapse collapse"
              aria-labelledby="headingten"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <app-inspection-history
                  [serviceOrder]="serviceOrder"
                  #inspection
                  [canEdit]="canEdit"
                  (empty)="emptyInspection($event)"
                ></app-inspection-history>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-group float-right m-3" *ngIf="canEdit">
          <button
            type="button"
            class="rounded-pill btn btn-save px-5 ml-2"
            [disabled]="
              AssignServiceOrder.serviceOrder.invalid ||

              companyDataTemplate.companyDataForm.invalid
            "
            (click)="
              saveAll(
                AssignServiceOrder.serviceOrder.value,
                OrderAssigment.assignmentForm.value,
                companyDataTemplate?.companyDataForm.value,
                docs.selectedFiles
              )
            "
          >
            Guardar
          </button>
          <!-- <button
            (click)="
              test(
                AssignServiceOrder.serviceOrder,
                OrderAssigment.assignmentForm,
                workerDataTemplate.workerDataForm,
                companyDataTemplate?.companyDataForm
              )
            "
          >
            test
          </button> -->
        </div>
        <ng-container #pdfContainer></ng-container>
      </div>

      <!-- end container -->
    </ng-template>
  </ng-template>
</ng-template>
