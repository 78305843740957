<div>
  <div class="centered-content" [ngClass]="{ cantEditCols: !canEdit }">
    <form [ngClass]="{ cantEdit: !canEdit }">
        <div class="row m-2">
        <div class="col-12 col-md text-left">
          <button
          id="toggleAddWorkerBtn"
            type="button"
            class="btn btn-outline-danger"
            data-bs-dismiss="modal"
            (click)="onClickBtnNew()"
          >
          <i class="fa fa-user-plus" aria-hidden="true">&nbsp;Agregar</i>
          </button> 
        </div>    
      </div>      
      <div *ngIf="hasRecords" class="mt-2 ms-1 mr-2">
        <app-prime-table
          [records]="records"
          [loading]="loading"
          [cols]="cols"
          [buttons]="customButtons"
          [filters]="filters"
          [hasGlobalFilters]="true"
        ></app-prime-table>
      </div>      
    </form>
  </div>
</div>
<ng-template #isTheFilter></ng-template>

