<div class="m-2">
  <div class="m-1 fs-4 proxima">
    En esta sección serán parametrizadas las rutas que representarán las
    empresas que deben ser visitadas por el Inspector.
  </div>
  <app-prime-table
    [records]="records"
    [loading]="loading"
    [cols]="cols"
    [buttons]="customButtons"
    [filters]="filters"
    notRegistersMessage="Sin rutas creadas"
    [creationButton]="creationButton"
  ></app-prime-table>
</div>
