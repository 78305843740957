import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { HttpClientService } from '../../_services/http-client/http-client.service';
import { Notification } from './_models/notification';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.css']
})
export class NotificationBellComponent implements OnInit {

  constructor(private http: HttpClientService, private user: UserService, private route: Router) { }
  notifications: Notification[] = []
  ngOnInit(): void {
    this.http.get<Notification[]>(`UserAlert?UserCode=${this.user.getUserData().userCode}&RepLocalProvId=${this.user.getUserData().repLocalProvId}&Status=true`)
    .pipe(catchError(() => []))
    .subscribe(
      response => {
        this.notifications = response;

      }
    )
  }
  delete(id: number) {
    this.notifications = this.notifications.filter(x => x.userAlertId !== id);
    this.http.patch(id, `UserAlert/${id}/false`).toPromise();
  }

  seeDetail(record) {
    this.route.navigate([`Casos/ordenServicio`, record.serviceOrderNumber])
  }

}
