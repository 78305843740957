<div class="col-12 col-md text-left">
  <span class="row">{{titleheader}}</span>
</div>
<div class="row m-2">
  <div class="col-12 col-md text-left">
    <button
    id="toggleActDBtn"
      type="button"
      class="{{btnCssDefault}}"
      data-bs-dismiss="modal"
      (click)="onClickBtnNew()"
    >
    <i class="{{ibtnCssDefault}}" aria-hidden="true">&nbsp;{{titlebtn}}</i>
    </button> 
  </div>    
</div>
<div *ngIf="isNewUser" class="mt-2 ms-1 mr-2">
  <app-prime-table
    [records]="records"
    [loading]="loading"
    [cols]="cols"
    [buttons]="customButtons"
    [filters]="filters"
    [hasGlobalFilters]="true"
    highlightColumn="recordStatus"
  ></app-prime-table>
</div>
<div *ngIf="!isNewUser">
  <div  class="mt-2 ms-1 mr-2">
    <app-prime-table
      [records]="recordAds"
      [loading]="loading"
      [cols]="colsAd"
      [buttons]="customAddButtons"
      [filters]="filtersAd"
      [hasGlobalFilters]="true"
    ></app-prime-table>
  </div>
</div>
