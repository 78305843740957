import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { ButtonModel } from 'src/app/modules/shared/_elements/element-ui/table/models/button-model';
import { TableConfigView } from 'src/app/modules/shared/_elements/element-ui/table/models/table-config-view';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { user as userModel } from '../../../../auth/_models/User';
import { serviceOrder } from '../../../serviceOrderForm/_models/serviceOrder';
import { visitRouteModel } from '../visit-route/models/visitRouteModel';
import { routeDataService } from '../visit-route/routeData.service';
import { VisitRouteComponent } from '../visit-route/visit-route.component';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-route-history',
  templateUrl: './route-history.component.html',
  styleUrls: ['./route-history.component.css']
})
export class RouteHistoryComponent implements OnInit {

  @Input() inspectionProgramId: number;
  @Input() programNumber: string
  @Input() serviceOrder: serviceOrder;
  @Output() empty = new EventEmitter<boolean>()
  @Input() canEdit: boolean;
  amount = 0;
  reLoad: Function;
  history: Subscription
  headers: TableConfigView[];
  records: visitRouteModel[];
  cols: any[];
  filters: any[];
  loading: boolean = true;
  InitialFormValue: FormGroup;
  subs: Subscription;
  currentUser = this.user.getUserData() as userModel
  maxDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() }
  customButtons: ButtonModel[];
  creationButton: any
  constructor(private http: HttpClientService, private route: Router, private user: UserService,
    private fb: FormBuilder, private routeService: routeDataService, private ngBModal: NgbModal
  ) {

  }

  searchForm: FormGroup;

  ngOnDestroy(): void {

    this.history?.unsubscribe()
    this.subs?.unsubscribe();
  }

  // deleteRecord(record) {
  //   Swal.fire({
  //     icon: 'warning',
  //     title: '¿Está seguro que desea eliminarlo?',
  //     showDenyButton: true,
  //     confirmButtonText: `Eliminar`,
  //     denyButtonText: `Cancelar`,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.http.patch<visitData[]>(record, `VisitData/${record.visitDataId}?registrationStatus=false`).subscribe(
  //         {
  //           next: response => {
  //             this.getRecords()
  //           },
  //           error: error => {
  //           }
  //         })
  //     } else if (result.isDenied) {
  //     }
  //   });
  // }
  ngOnInit(): void {
    if (this.currentUser.roleCode == 'REPLO' || this.currentUser.roleCode == 'DIR') {
      this.creationButton = {
        label: 'Crear Ruta', action: () => {
          const ngmodal: NgbModalRef = this.ngBModal.open(
            VisitRouteComponent, { size: 'xl' });
          ngmodal.componentInstance.inspectionProgramNumber = this.programNumber;
          ngmodal.componentInstance.inspectionProgramId = this.inspectionProgramId;
        }
      }
    }

    this.assignButtons()

    this.getRecords()
    this.subs = this.routeService.subject.subscribe(data => {
      this.getRecords()

    });



    this.history = this.http.get<visitRouteModel[]>(`VisitRoute/?status=true&inspectionProgramNumber=${this.programNumber}`).subscribe(
      {
        next: response => {

          this.records = response
          this.loading = false;

        },
        error: error => {
          this.records = []
          this.loading = false
        }
      }
    )

    this.filters = ["visitRouteId", "route", "assignedInspectorCodeName", "registeredByName", "visitDate", "typeOfScheduleInfo"]
    this.cols = [

      {
        field: 'visitRouteId',
        view: { table: true, detail: true },
        header: 'ID RUTA',
        width: 3,
        tooltip: 'ID RUTA',
        fixedColumn: true,

      },
      {
        field: 'assignedInspectorCodeName',
        view: { table: true, detail: true },
        header: 'INSPECTOR',
        width: 3,
        tooltip: 'INSPECTOR',
        fixedColumn: true,

      },
      {
        field: 'route',
        view: { table: true, detail: true },
        header: 'RUTA(CUADRANTE)',
        width: 3,
        tooltip: 'RUTA(CUADRANTE)',
        fixedColumn: true,

      },

      {
        field: 'startDateStr',
        header: 'FECHA INICIO',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'FECHA INICIO',
        fixedColumn: true,

      },



    ]
  }




  getRecords() {

    this.loading = true;
    this.history = this.http.get<visitRouteModel[]>(`VisitRoute/?status=true&inspectionProgramNumber=${this.programNumber}`).subscribe(
      {

        next: response => {

          this.records = response
          this.loading = false;
          this.amount = response.length

        },
        error: error => {

          this.empty.emit(true);
          this.records = []
          this.loading = false;
        }
      }
    )

  }

  assignButtons() {
    this.customButtons = [
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 me-2",
        function: async (record: serviceOrder) => {
          const ngmodal: NgbModalRef = this.ngBModal.open(
            VisitRouteComponent, { size: 'xl' });

          ngmodal.componentInstance.inspectionProgramId = this.inspectionProgramId;

          ngmodal.componentInstance.route = cloneDeep(record);

        }, label: `${this.canEdit ? 'Editar' : 'Consultar'}`
      },
    ]
  }
}
