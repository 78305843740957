import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { visitData } from '../_models/visitData';


import { NgbTimeStruct, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { visitDataService } from './visitData.service';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AdvanceFilterModel } from '../../service/advance-Filters/advanced-filter/AdvanceFilterModel';

const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10)
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null
      ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}`
      : null;
  }
}
@Component({
  selector: 'app-visit-data',
  templateUrl: './visit-data.component.html',
  styleUrls: ['./visit-data.component.css'],
  animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }, { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }]
})
export class VisitDataComponent implements OnInit {
  @Input() reLoad: (orderNumber) => {}
  @Output() edit: EventEmitter<{ edit: boolean, estados: Array<any>, id: number }> = new EventEmitter();
  @Output() checkComplete = new EventEmitter<boolean>()
  @Input() canEdit: boolean = true;
  @Input() isFilter: boolean = false
  @Output() hasValue = new EventEmitter<boolean>();

  serviceOrderNumber: string;
  visitData: visitData;
  title: string;
  TypeOfActionTaken: any[];
  TypeOfSchedule: DropDownOptionModel[];
  time = { hour: 13, minute: 30 };
  meridian = true;
  show: boolean = false;
  constructor(private ngbActiveModal: NgbActiveModal, private fb: FormBuilder, private user: UserService,
    private HttpService: HttpClientService, private sweet: SweetAlertService, private visitService: visitDataService,
    private toast: ToastService, public tool: ToolsService, private elem: ElementRef, private cd: ChangeDetectorRef) {
    this.getDropdowns()
  }
  visitDataForm: FormGroup;

  minDate: any
  maxDate: any
  orderFilter: AdvanceFilterModel = this.tool.getFilterLS('orderFilter')?.form

  get currentDay() {
    let date = new Date()
    return date.setDate(date.getDate() - 1)
  }

  assignVISPR() {
    let id = this.TypeOfActionTaken?.find(el =>
      el.alternateField == "TOMAC"
    )?.value

    this.visitDataForm.patchValue({
      'actionTakenId': id
    })
  }
  refreshDateValidators() {

    this.minDate = { year: 2023, month: 5, day: 15 }
    // this.minDate = { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date }
    this.maxDate = { year: moment().year(), month: moment().month() + 3, day: moment().date() }
  }
  get actionType() {
    if (this.visitDataForm.get('actionTakenId').value && this.TypeOfActionTaken) {



      let { alternateFieldGroup } = this.TypeOfActionTaken?.find(x => x.value == (this.visitDataForm.get('actionTakenId').value));
      return alternateFieldGroup == "OTHER" ? true : false;
    }
  }
  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  ngOnInit() {
    //this.minDate = { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date }
    this.minDate = { year: 2023, month: 5, day: 15 }
    this.maxDate = { year: moment().year(), month: moment().month() + 3, day: moment().date() }

    this.createForm()

  }



  actionDescription(event) {

    let { alternateFieldGroup } = this.TypeOfActionTaken.find(x => x.value == event);
    if (alternateFieldGroup == "OTHER") {
      this.visitDataForm.get('actionTakenOthers').setValidators([Validators.required]);
      this.visitDataForm.get('actionTakenOthers').updateValueAndValidity();
    } else {
      this.visitDataForm.get('actionTakenOthers').clearValidators()
      this.visitDataForm.get('actionTakenOthers').updateValueAndValidity();
    }

  }

  createForm() {

    if (this.isFilter) {
      this.visitDataForm = this.fb.group({
        visitDate: new FormControl(this.orderFilter ? this.orderFilter.visitDateStr : null, [this.tool.dateValidator]),
        typeOfScheduleId: new FormControl(this.orderFilter ? this.orderFilter.typeOfApplicantId : null),
        actionTakenId: new FormControl(this.orderFilter ? this.orderFilter.actionTakenId : null)
      })
      this.hasValue.emit(this.tool.hasFormAnyValue(this.visitDataForm.value))
    }
    else {
      if (this.visitData) {
        this.title = 'Actualizar datos de visita'
         
        if(this.visitData.typeOfScheduleInfo !=='Diurno' && this.visitData.scheduleVisit === null) this.visitData.scheduleVisit = "12:00:00";
        this.visitDataForm = this.fb.group({
          visitDataId: new FormControl(this.visitData.visitDataId),
          serviceOrderNumber: new FormControl(this.visitData.serviceOrderNumber),
          visitDate: new FormControl(this.tool.dateToObject(this.visitData.visitDate), [Validators.required, this.tool.dateValidator]),
          typeOfScheduleId: new FormControl(this.visitData.typeOfScheduleId, Validators.required),
          scheduleVisit: new FormControl(this.visitData.scheduleVisit),
          actionTakenId: new FormControl(this.visitData.actionTakenId, Validators.required),
          actionTakenOthers: new FormControl(this.visitData.actionTakenOthers),
          finishedVisitDetail: new FormControl(this.visitData.finishedVisitDetail),
          registeredBy: this.visitData.registeredBy,
          modifiedBy: this.user.getUserData().userCode,
          recordModificationDate: new Date(),
          registrationDate: this.visitData.registrationDate,
          recordStatus: true
        })
        this.minDate = null
        this.maxDate = null
      }
      else {
        this.title = " Agregar datos de visita"
        this.visitDataForm = this.fb.group({
          visitDataId: new FormControl(0),
          serviceOrderNumber: new FormControl(this.serviceOrderNumber),
          visitDate: new FormControl(null, [Validators.required, this.tool.dateValidator]),
          typeOfScheduleId: new FormControl(null, Validators.required),
          scheduleVisit: new FormControl(null),
          actionTakenId: new FormControl(null),
          actionTakenOthers: new FormControl(null),
          finishedVisitDetail: new FormControl(null),
          registeredBy: this.user.getUserData().userCode,
          modifiedBy: this.user.getUserData().userCode,
          recordStatus: true
        })


      }
    }
    // this.minDate = { year: moment().add(-14, 'd').toObject().years, month: moment().add(-14, 'd').toObject().months + 1, day: moment().add(-14, 'd').toObject().date }
    this.minDate = { year: 2023, month: 5, day: 15 }
    this.maxDate = { year: moment().year(), month: moment().month() + 3, day: moment().date() }

  }


  close(direct: boolean) {
    if (direct) {
      this.ngbActiveModal.close('lklk');
    } else {
      this.tool.modalWarning()
        .then((result) => {
          if (result.isConfirmed) {
            this.ngbActiveModal.close();
          }
        })
    }
  }

  async saveVisitData() {
    if (this.visitDataForm.valid) {
      if (this.visitData) {
        try {
 
          await this.HttpService.update<visitData>(this.mapVisitData(this.visitDataForm.value), "VisitData").toPromise()
          this.edit.emit({ edit: true, estados: this.TypeOfActionTaken, id: this.visitDataForm.get('actionTakenId').value });
          this.close(true);

          this.sweet.record('success', `Registro No.${this.visitData.visitDataId}`, 'Su visita se ha actualizado correctamente', ``)
          this.visitService.subject.next(true)
        } catch (err) {
          this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
        }

      }

      else {
        try {
          this.assignVISPR()
          const response = await this.HttpService.post<visitData>(this.mapVisitData(this.visitDataForm.value), "VisitData").toPromise()
          this.edit.emit({ edit: true, estados: this.TypeOfActionTaken, id: this.visitDataForm.get('actionTakenId').value });
          this.close(true);
          this.sweet.record('success', `Registro No.${response.visitDataId}`, 'Su visita se ha registrado correctamente', `Este caso se ha añadido a su historial de visitas`)
          this.visitService.subject.next(true)
        }
        catch (err) {
          this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
        }


      }
    } else {
      this.tool.createWarning(this.visitDataForm, this.elem).then(
        result => this.notFound()
      )
    }
  }

  mapVisitData(visitData: visitData) {
    let mapVisit = {

      visitDate: new Date(`${visitData.visitDate['year']}-${visitData.visitDate['month']}-${visitData.visitDate['day']}`),
    }

    return { ...visitData, ...mapVisit }
  }

  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }

  getDropdowns() {
    this.HttpService.get<any[]>(`TypeOfActionTaken`).subscribe((response) => { this.TypeOfActionTaken = response });
    this.HttpService.get<any[]>(`TypeOfSchedule`).subscribe((response) => { this.TypeOfSchedule = response });



  }
  clean() {
    this.visitDataForm.patchValue({
      visitDate: null,
      typeOfScheduleId: null,
      actionTakenId: null
    })
  }
}

