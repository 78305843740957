<div class="card" style="width: 99.4%; height: 99%; margin-left: 4px">
  <div class="card-body">
    <p class="title h4 mb-2">
      <strong class="text-bold">{{ totalTitle }}</strong
      >{{ title }} <br />
      <span class="h6">EN TOTAL</span>
    </p>

    <canvas
      baseChart
      [chartType]="'bar'"
      [labels]="labels"
      [data]="data"
      [options]="chartOptions"
      [colors]="chartColors"
    >
    </canvas>
  </div>
  <div *ngIf="hasFooter" class="footer text-center card-footer">
    <p class="title float-end m-auto">Total general <strong>3433</strong></p>
  </div>
</div>
