import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { ButtonModel } from 'src/app/modules/shared/_elements/element-ui/table/models/button-model';
import { TableConfigView } from 'src/app/modules/shared/_elements/element-ui/table/models/table-config-view';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { InspectionReportComponent } from '../../../serviceOrderForm/inspection-report/inspection-report.component';
import { InspectionReportService } from '../../../serviceOrderForm/inspection-report/inspection-report.service';
import { serviceOrder } from '../../../serviceOrderForm/_models/serviceOrder';
import Swal from 'sweetalert2';
import { inspectionReport } from '../../../serviceOrderForm/inspection-report/_models/inspectionReport';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-inspection-history',
  templateUrl: './inspection-history.component.html',
  styleUrls: ['./inspection-history.component.css']
})
export class InspectionHistoryComponent implements OnInit {

  @Input() serviceOrder: serviceOrder;
  @Output() empty = new EventEmitter<boolean>()
  @Input() canEdit: boolean = true;

  amount = null;
  reLoad: Function;
  history: Subscription
  headers: TableConfigView[];
  records: inspectionReport[] = [];
  cols: any[];
  filters: any[];
  loading: boolean = true;
  InitialFormValue: FormGroup;
  subs: Subscription;
  maxDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() }
  constructor(private http: HttpClientService, private route: Router, private user: UserService,
    private fb: FormBuilder, private inspectionData: InspectionReportService, private ngBModal: NgbModal) { }

  searchForm: FormGroup;

  ngOnDestroy(): void {
    this.history.unsubscribe()
    this.subs.unsubscribe();
  }
  customButtons: ButtonModel[];

  deleteRecord(record) {
    Swal.fire({
      icon: 'warning',
      title: '¿Esta seguro que desea eliminarlo?',
      showDenyButton: true,
      confirmButtonText: `Eliminar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.http.patch<inspectionReport>(record, `inspectionReport/${record.inspectionReportId}?registrationStatus=false`).subscribe(
          {
            next: response => {
              this.getRecords()
            },
            error: error => {
            }
          })
      } else if (result.isDenied) {
      }
    });
  }
  ngOnInit(): void {
    this.assignButtons()
    this.getRecords()
    this.subs = this.inspectionData.subject.subscribe(data => {
      this.getRecords()

    });

    this.searchForm = this.fb.group({
      sinceDate: new FormControl(this.dateToObject(moment().subtract(1, 'M').set('date', 1).format().toString())),
      untilDate: new FormControl(this.dateToObject(moment().format().toString())),
      show: new FormControl(false)
    })


    this.filters = ["registeredByName", "registeredByName", "reportDate"]
    this.cols = [


      {
        field: 'inspectionReportNumber',
        view: { table: true, detail: true },
        header: 'NUMERO INFORME',
        width: 3,
        tooltip: 'NUMERO INFORME',
        fixedColumn: true,

      },


      {
        field: 'reportDateStr',
        header: 'FECHA INFORME',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'FECHA INFORME',
        fixedColumn: true,

      },


      {
        field: 'reportStatusInfo',
        header: 'ESTADO',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'ESTADO',
        fixedColumn: true,
        length: 30
      }


    ]
  }



  dateToObject(date: string) {
    if (date) {
      let a: any[] = date.slice(0, 10).split('-').map(x => {
        return Number(x)
      })
      return { year: a[0], month: a[1], day: a[2] };
    }
    return null
  }

  getRecords() {

    this.loading = true;
    this.history = this.http.get<inspectionReport>(`InspectionReport/ServiceOrderNumber?serviceOrderNumber=${this.serviceOrder.serviceOrderNumber}&status=true`).subscribe(
      {

        next: response => {
          this.records = []
          this.records.push(response)
          this.loading = false;
          this.amount = response

        },
        error: error => {

          this.empty.emit(true);
          this.records = []
          this.loading = false;
        }
      }
    )

  }

  assignButtons() {
    this.customButtons = [
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 me-2",
        function: async (record: inspectionReport) => {
          const ngmodal: NgbModalRef = this.ngBModal.open(
            InspectionReportComponent, { size: 'xl' });
          ngmodal.componentInstance.inspectionReport = cloneDeep(record);
          ngmodal.componentInstance.canEdit = this.canEdit
        }, label: `${this.canEdit ? 'Editar' : 'Consultar'}`
      },


    ]
  }
}
