<div *ngIf="hasInternet; else noInternet">
  <form [formGroup]="searchForm">
    <div class="form-row">
      <div class="col-md-6 col-lg-6 col-12">
        <label class="form-label form-control-lg">Tipo Historial</label>
        <el-dropdown
          [options]="forms"
          formControlName="typeHistory"
          (onChange)="changeTable($event)"
          customClass="form-control form-control-lg mainDrop"
          [disabled]="
            this.searchForm.get('almostClose').value ||
            this.searchForm.get('advancedFilter').value ||
            loading
          "
          placeHolder="Elige un tipo de historial"
          [filterable]="false"
        ></el-dropdown>
      </div>
    </div>
    <div class="form-row">
      <div class="col-lg-3 col-md-6 col-sm-12">
        <label class="form-label form-control-lg"
          >Fecha Desde: <small class="text-danger"></small
        ></label>
        <div class="input-group">
          <input
            class="form-control form-control-lg"
            placeholder="Fecha Desde"
            name="since"
            formControlName="sinceDate"
            [maxDate]="maxDate"
            ngbDatepicker
            #since="ngbDatepicker"
            readonly
            (click)="since.toggle()"
            [disabled]="
              this.searchForm.get('show').value ||
              this.searchForm.get('advancedFilter').value
            "
          />
          <div class="input-group-append">
            <button
              pButton
              icon="pi pi-calendar"
              class="btn btn-outline-secondary btn-lg cs-calendar calendar"
              (click)="since.toggle()"
              type="button"
              [disabled]="
                this.searchForm.get('show').value ||
                this.searchForm.get('advancedFilter').value
              "
            ></button>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6 col-sm-12">
        <label class="form-label form-control-lg"
          >Fecha Hasta <small class="text-danger"></small
        ></label>
        <div class="input-group">
          <input
            class="form-control form-control-lg"
            placeholder="Fecha Hasta"
            name="dateTopicker"
            name="until"
            formControlName="untilDate"
            readonly
            [maxDate]="maxDate"
            ngbDatepicker
            #until="ngbDatepicker"
            (click)="until.toggle()"
            [disabled]="
              this.searchForm.get('show').value ||
              this.searchForm.get('almostClose').value ||
              this.searchForm.get('advancedFilter').value
            "
          />
          <div class="input-group-append">
            <button
              pButton
              icon="pi pi-calendar"
              class="btn btn-outline-secondary btn-lg cs-calendar calendar"
              type="button"
              (click)="until.toggle()"
              [disabled]="
                this.searchForm.get('show').value ||
                this.searchForm.get('almostClose').value ||
                this.searchForm.get('advancedFilter').value
              "
            ></button>
          </div>
        </div>
      </div>
      <div
        class="
          col-lg-2 col-md-2 col-12
          d-flex
          align-items-end
          my-2 my-sm-0
          justify-content-center justify-content-sm-start
        "
      >
        <button
          pTooltip="Busqueda por rango de fecha"
          tooltipPosition="top"
          type="button"
          [disabled]="
            this.searchForm.get('show').value ||
            this.searchForm.get('advancedFilter').value ||
            loading
          "
          class="btn btn-secondary buttons px-5 px-sm-2"
          (click)="getRecordsWithDate()"
        >
          <i class="fa fa-search"></i>&nbsp;Buscar
        </button>
      </div>
      <!-- <div
        class="
          col-lg-3 col-md-6 col-sm-12
          text-center
          d-flex
          justify-content-center
          align-items-end
          mt-2
        "
      >
        <p-toggleButton
          formControlName="show"
          onLabel="Mostrando Todas las RLT"
          offLabel="Mostrar Todas las RLT"
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          [style]="{ width: '15rem', height: '2.7rem' }"
          (onChange)="showAllRecords()"
          [disabled]="this.searchForm.get('advancedFilter').value"
        ></p-toggleButton>
      </div> -->
      <div
        class="
          col-lg-4 col-md-4 col-sm-12
          text-center
          d-flex
          align-items-end
          mt-fiter
          justify-content-center justify-content-sm-start
        "
        
      >
        <button
          pButton
          type="button"
          pTooltip="Filtro avanzado de colección"
          tooltipPosition="top"
          label="Filtro Avanzado"
          class="p-button-secondary advancedFilterbtn buttons"
          [style]="{ height: '46px' }"
          (click)="advancedFilter()"
          [disabled]="loading"
        ></button>
        <p-toggleButton
          pTooltip="Activa o desactiva el Filtro avanzado"
          tooltipPosition="top"
          formControlName="advancedFilter"
          onIcon="pi pi-check"
          offIcon="pi pi-times"
          [style]="{ height: '46px', borderRadius: '0px' }"
          [Class]="'advancedFilterbtn buttons'"
          (onChange)="toggleAdvanceFilter()"
          [disabled]="loading"
        ></p-toggleButton>
      </div>
    </div>
  </form>
  <div class="mt-2 ms-0 mr-2">
    <app-prime-table
      [records]="records"
      [loading]="loading"
      [cols]="cols"
      [buttons]="customButtons"
      [filters]="filters"
      [hasGlobalFilters]="true"
      [hasRouter]="hasRouter"
      #table
    ></app-prime-table>
  </div>

  <div class="m-3"></div>
</div>
<ng-template #noInternet>
  <div class="row noInternetCon">
    <div class="d-flex align-items-center flex-column">
      <div class="col-4"></div>
      <div class="col-4 text-center align-items-center iconContainer">
        <i class="pi pi-wifi" style="font-size: 7rem"></i>
        <h6>No tienes conexion a internet</h6>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</ng-template>
