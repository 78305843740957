import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Injectable, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbCalendar,
  NgbDateParserFormatter,
  NgbTimeAdapter,
  NgbTimeStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ConnectionService } from 'ngx-connection-service';
import { Observable } from 'rxjs';
import { UserService as serviceUser } from 'src/app/modules/auth/_services/user.service';
import { DropDownOptionModel } from 'src/app/modules/shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { NgbDateCustomParserFormatter } from 'src/app/modules/shared/_models/dateFormat';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { SweetAlertService } from 'src/app/modules/shared/_services/sweetAlert/sweet-alert.service';
import { ToastService } from 'src/app/modules/shared/_services/toast/toast.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import Swal from 'sweetalert2';
import * as uuid from 'uuid';
import { UserServiceFilter } from '../advance-Filters/advanced-user-service-filter/userServiceFilter';
import { DbPwaService } from '../pwa/db_pwa.service';
import { UserService } from './_model/UserService';
import { applicantData } from './_model/applicantsData';
import { cloneDeep } from 'lodash';

const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const split = value.split(':');
    return {
      hour: parseInt(split[0], 10),
      minute: parseInt(split[1], 10),
      second: parseInt(split[2], 10),
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    return time != null
      ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}`
      : null;
  }
}
@Component({
  selector: 'app-user-service-form',
  templateUrl: './user-service-form.component.html',
  styleUrls: ['./user-service-form.component.css'], animations: [
    trigger('warning', [
      state('show', style({
        opacity: 1,
        transform: 'scale(2)',
        display: 'inline-block'
      })),
      state('hide', style({
        opacity: 1,
        transform: 'scale(1)',
        display: 'inline-block'
      })),
      transition('show => hide', animate('800ms ease-out')),
      transition('hide => show', animate('100ms ease-in'))
    ])
  ],
  providers: [
    { provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class UserServiceFormComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private HttpService: HttpClientService,
    private user: serviceUser,
    private sweet: SweetAlertService,
    private toast: ToastService,
    private router: Router,
    public tool: ToolsService,
    private elem: ElementRef,
    private pwa: DbPwaService,
    private connectionService: ConnectionService,
    private calendar: NgbCalendar
  ) {

    this.getDropdowns()
    this.connectionService.updateOptions({
      heartbeatExecutor: options => new Observable<any>(subscriber => {
        if (Math.random() > .2) {
          subscriber.next();
          subscriber.complete();
        } else {
          // throw new Error('Connection error');

        }
      })
    });

    this.connectionService.monitor().subscribe((currentState) => {
      if (!currentState.hasNetworkConnection) {
        this.getDropdowns()
      }
    })
  }
  isDateInvalid: boolean = false;
  userServiceInput: UserService;
  userServiceForm: FormGroup;
  show: boolean = false;
  meridian = true;
  @Input() isFilter: boolean = false
  applicantsCopy: FormArray;
  repLocalProvs: DropDownOptionModel[] = [];
  requestForms: any[] = [];
  serviceTypes: any[] = [];
  GroupCount: number = 0;
  conditions: DropDownOptionModel[] = [];
  Groups: DropDownOptionModel[] = [];
  applicantTypes: any[] = [];
  currentHour = moment().format("hh:mm");
  dropdownSettings: IDropdownSettings = {};
  serviceFilter: UserServiceFilter = this.tool.getFilterLS('serviceFilter')?.form;
  genders: DropDownOptionModel[] = [
    { value: 'M', text: 'Masculino' },
    { value: 'F', text: 'Femenino' },
  ];
  LocalTypeApplicants: DropDownOptionModel[] = [
    { value: 'I', text: 'Individual' },
    { value: 'G', text: 'Grupal' },
  ];
  otherForm = false;
  otherService = false;
  otherApplicant = false;
  maxDate = {
    year: moment().year(),
    month: moment().month() + 3,
    day: moment().date(),
  };
  minDate: any;
  MinEstimatedEndDate: any;
  maxEstimatedDate = {
    year: moment().year(),
    month: moment().month() + 3,
    day: moment().date(),
  };
  ApplicantCount: number;
  isSaving: boolean = false;
  userServiceValue: UserService;
  values2: any[] = [];
  ApplicantsToDelete: number[] = [];
  splitPattern = new RegExp('[,;]');
  activityBranchs: DropDownOptionModel[] = []
  nationalityDrop: DropDownOptionModel[] = []
  identificationTypes: any[]; 
  documentTypeCode: string;


  async permitirSalirDelRoute(): Promise<boolean | Observable<boolean>> {
    if (!this.userServiceInput) {
      if (this.userServiceForm.dirty && !this.userServiceInput && this.userServiceForm.invalid) {
        let response;
        await Swal.fire({
          title: 'Se han realizado nuevos cambios, ¿está seguro(a) que deseas salir?',
          text: "Toda su información se perderá",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'

        }).then((result) => {
          response = result.isConfirmed
        })

        return response
      } else {
        return true
      }
    }
    else {

      if (this.tool.shallowEqual(this.userServiceValue, this.userServiceForm.value) && !this.isSaving) {
        let response;
        await Swal.fire({
          title: 'Se realizaron nuevos cambios, Esta seguro que desea salir?',
          text: "Toda tu informacion se perdera",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'

        }).then((result) => {
          response = result.isConfirmed
        })

        return response
      } else {
        return true
      }
    }
  }

  ngOnInit(): void {

    this.minDate = !this.userServiceInput
      ? {
        year: moment().add(-14, 'd').toObject().years,
        month: moment().add(-14, 'd').toObject().months,
        day: moment().add(-14, 'd').toObject().date,
      }
      : null;
    if (this.route.data) {
      this.route.data.subscribe((data: { UserService: UserService }) => {
        this.userServiceInput = data.UserService;
      });
    }

  
    this.mutliSelectOptions();
    this.createForm();
    this.getDropdowns();
    this.otherRequestForm();
 
    
  }

  get currentDay() {
    let date = moment().utc();
    return date;
  }

  initApplicant(applicant?: applicantData) {
     
    if (!applicant) {
      return this.fb.group({
        userServiceNumber: this.userServiceInput
          ? this.userServiceInput.userServiceNumber
          : null,
        applicantDataId: 0,
        sex: [null, Validators.required],
        applicantTypeId: [null, Validators.required],
        applicantTypeOther: [null],
        applicantConditionId: [null, Validators.required],
        modifiedBy: [this.user.getUserData().userCode],
        registeredBy: [this.user.getUserData().userCode],
        registrationDate: [null],
        recordModificationDate: [null],
        recordStatus: [true],
        nationalityId: [null],
        identification: [null],
        identificationTypeId: [null],
      });
    } else {
       
      return this.fb.group({
        userServiceNumber: applicant.userServiceNumber,
        applicantDataId: applicant.applicantDataId,
        sex: applicant.sex,
        applicantTypeId: applicant.applicantTypeId,
        applicantTypeOther: applicant.applicantTypeOther,
        applicantConditionId: applicant.applicantConditionId,
        modifiedBy: applicant.modifiedBy,
        registeredBy: applicant.registeredBy,
        registrationDate: applicant.registrationDate,
        recordModificationDate: applicant.recordModificationDate,
        recordStatus: applicant.recordStatus,
        nationalityId: applicant.nationalityId,
        identification: applicant.identification,
        identificationTypeId: applicant.identificationTypeId,        
      });
    }
  }

  addApplicant(applicant?: applicantData) {
    if (!applicant) {
      const control = <FormArray>(
        this.userServiceForm.controls['applicantsListed']
      );
      control.push(this.initApplicant());
    } else {
      const control = <FormArray>(
        this.userServiceForm.controls['applicantsListed']
      );
      control.push(this.initApplicant(applicant));
    }
  }

  removeApplicant(i: number) {
    Swal.fire({
      icon: 'warning',
      title: '¿Esta seguro que desea eliminar?',
      showDenyButton: true,
      confirmButtonText: `Eliminar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const control = <FormArray>(
          this.userServiceForm.controls['applicantsListed']
        );
        //  this.HttpService.patch(control.controls[i].value,`UserServiceApplicant/${(control.controls[i].value).applicantDataId}/false`).toPromise().then(
        //    response=>{
        //      Swal.fire('Eliminado', '', 'success')
        //    }
        //  )
        Swal.fire('Eliminado', '', 'success');
        if (control.controls[i].value.applicantDataId) {
          this.ApplicantsToDelete.push(
            control.controls[i].value.applicantDataId
          );
        }
        control.removeAt(i);
        this.userServiceForm.patchValue({
          groupQuantity: control.length,
        });

        this.GroupCount = this.GroupCount - 1;
      } else {
      }
    });
  }

  createForm() {
    if (this.isFilter) {
      this.userServiceForm = this.fb.group({
        requestFormId: new FormControl(this.serviceFilter ? this.serviceFilter.requestFormId : null),
        reportDate: new FormControl(this.serviceFilter ? this.serviceFilter.reportDate : null, this.tool.dateValidator),
      })
    }
    else {
      if (!this.userServiceInput) {
        this.userServiceForm = this.fb.group({
          userServiceId: new FormControl(!this.isFilter ? 0 : null),
          userServiceNumber: new FormControl(null),
          reportDate: new FormControl(this.calendar.getToday(), [Validators.required, this.tool.dateValidator]),
          reportHour: new FormControl(this.currentHour, Validators.required),
          repLocalProvId: new FormControl(
            !this.isFilter ? this.user.getUserData().repLocalProvId : null,
            Validators.required
          ),
          applicantTypeOther: new FormControl(null),
          TypeOfApplicantUserServiceId: new FormControl(null),
          groupQuantity: new FormControl(!this.isFilter ? 0 : null),
          applicantsListed: !this.isFilter ? this.fb.array([]) : null,
          LocalApplicant: new FormControl(null, Validators.required),
          typeOfServiceListed: new FormControl(null, Validators.required),
          typeOfServiceOther: new FormControl(null),
          requestFormId: new FormControl(null, Validators.required),
          requestFormOther: new FormControl(null),
          observations: new FormControl(null),
          groupId: new FormControl(null),
          registeredBy: !this.isFilter ? this.user.getUserData().userCode : null,
          recordModificationDate: new FormControl(null),
          modifyBy: !this.isFilter ? this.user.getUserData().userCode : null,
          recordStatus: new FormControl(true),
          activityBranchRLTId: new FormControl(null),
          companyArea: new FormControl(null),
        });

        if (this.isFilter) {
          this.tool.removeValidators(this.userServiceForm)
        }
      } else {
        this.userServiceForm = this.fb.group({
          userServiceId: new FormControl(
            this.userServiceInput.userServiceId,
            Validators.required
          ),
          userServiceNumber: new FormControl(
            this.userServiceInput.userServiceNumber,
            Validators.required
          ),
          LocalApplicant: new FormControl(null, Validators.required),

          reportDate: new FormControl(
            this.tool.dateToObject(this.userServiceInput.reportDate),
            [Validators.required, this.tool.dateValidator]
          ),
          reportHour: new FormControl(
            this.userServiceInput.reportHour,
            Validators.required
          ),
          repLocalProvId: new FormControl(
            this.userServiceInput.repLocalProvId,
            Validators.required
          ),
          applicantTypeOther: new FormControl(
            this.userServiceInput.applicantTypeOther
          ),
          applicantsListed: this.fb.array([]),
          typeOfServiceOther: new FormControl(
            this.userServiceInput.typeOfServiceOther
          ),
          requestFormId: new FormControl(
            this.userServiceInput.requestFormId,
            Validators.required
          ),
          requestFormOther: new FormControl(
            this.userServiceInput.requestFormOther
          ),
          observations: new FormControl(this.userServiceInput.observations),
          groupId: new FormControl(this.userServiceInput.groupId),
          typeOfServiceListed: new FormControl(
            this.tool.JsonToObject(this.userServiceInput.typeOfServiceListedJson),
            Validators.required
          ),
          registrationDate: new FormControl(
            this.userServiceInput.registrationDate,
            Validators.required
          ),
          recordModificationDate: new FormControl(this.currentDay),
          registrationDateStr: new FormControl(
            this.userServiceInput.registrationDateStr
          ),
          recordModificationDateStr: new FormControl(
            this.userServiceInput.recordModificationDateStr
          ),
          registeredBy: new FormControl(this.userServiceInput.registeredBy),
          modifiedBy: new FormControl(this.user.getUserData().userCode),
          registeredByName: new FormControl(
            this.userServiceInput.registeredByName,
            Validators.required
          ),
          modifiedByName: new FormControl(this.userServiceInput.modifiedByName),
          groupQuantity: new FormControl(0),
          recordStatus: new FormControl(
            this.userServiceInput.recordStatus,
            Validators.required
          ),
          activityBranchRLTId: new FormControl(
            this.userServiceInput.activityBranchRLTId
          ), 
          companyArea: new FormControl(
            this.userServiceInput.companyArea
          ),                   
        });
        this.JsonApplicantsToObject(this.userServiceInput.applicantsListedJson);
        
        this.assignTypeOfApplicant();
        this.otherServices();
        this.applicantsCopy = cloneDeep(
          this.userServiceForm.get('applicantsListed').value
        );
        this.userServiceValue = cloneDeep(this.userServiceForm.value);
      }
    }
  }

  assignTypeOfApplicant() {
    if (this.userServiceInput) {
      if (this.userServiceForm.get('applicantsListed').value.length > 1) {
        this.userServiceForm.patchValue({
          'LocalApplicant': 'G'
        });
      } else {
        this.userServiceForm.patchValue({
          'LocalApplicant': 'I'
        });
      }
    } else {
      this.userServiceForm.patchValue({
        'LocalApplicant': null
      });
    }
    this.userServiceForm.patchValue({
      groupQuantity: this.userServiceForm.get('applicantsListed').value.length,
    });
    this.GroupCount = this.userServiceForm.get('applicantsListed').value.length;
  }
  increaseGroupCount(event) {
    if (event > this.GroupCount) {
      let i = event - this.GroupCount;
      for (let i = event - this.GroupCount; i !== 0; i--) {
        this.addApplicant();
      }

      this.GroupCount = event;
    } else {
      Swal.fire({
        icon: 'warning',
        title: '¿Esta seguro que desea eliminar?',
        showDenyButton: true,
        confirmButtonText: `Eliminar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const control = <FormArray>(
            this.userServiceForm.controls['applicantsListed']
          );
          for (let i = this.GroupCount - event; i !== 0; i--) {
            let applicantsLength =
              this.userServiceForm.get('applicantsListed').value.length;
            if (control.controls[applicantsLength - 1].value.applicantDataId) {
              this.ApplicantsToDelete.push(
                control.controls[applicantsLength - 1].value.applicantDataId
              );
            }
            // this.HttpService.patch(control.controls[applicantsLength - 1].value,`UserServiceApplicant/${(control.controls[applicantsLength - 1].value).applicantDataId}/false`).toPromise()
            control.removeAt(applicantsLength - 1);
          }
          this.GroupCount = event;
        }
      });
    }
  }

  JsonApplicantsToObject(json: string) {
    if (json) {
      let res: applicantData[] = JSON.parse(json);
      res.map((item) => {
          
        return {
          userServiceNumber: item.userServiceNumber,
          applicantDataId: item.applicantDataId,
          sex: item.sex,
          applicantTypeId: item.applicantTypeId,
          applicantTypeOther: item.applicantTypeOther,
          applicantConditionId: item.applicantConditionId,
          modifiedBy: item.modifiedBy,
          registeredBy: item.registeredBy,
          registrationDate: item.registrationDate,
          recordModificationDate: item.recordModificationDate,
          recordStatus: item.recordStatus,
          nationalityId: item.nationalityId,
          identification: item.identification,
          identificationTypeId: item.identificationTypeId,
        };
      });

      res.forEach((applicant) => {
        this.addApplicant(applicant);
      });
    }
    return null;
  }
  mutliSelectOptions() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Seleccionar Todos',
      unSelectAllText: 'Deseleccionar Todos',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: true,
      searchPlaceholderText: 'Buscar',
    };
  }

  async getDropdowns() {
    if (this.tool.hasInternetConnection) {
      let serviceTypesP = this.HttpService.get<any[]>(`TypeOfUserService?status=true`).toPromise()
      let repLocalProvsP = this.HttpService.get<DropDownOptionModel[]>(
        `LocalRepresentativeProvince`
      ).toPromise()
      let applicantTypesP = this.HttpService.get<DropDownOptionModel[]>(
        `TypeOfApplicantUserService/TypeOfApplicantsUserService?status=true`
      ).toPromise()
      let GroupsP = this.HttpService.get<DropDownOptionModel[]>(`Group`).toPromise()
      let conditionsP = this.HttpService.get<DropDownOptionModel[]>(
        `ApplicantCondition?status=true`
      ).toPromise()
      let requestFormsP = this.HttpService.get<DropDownOptionModel[]>(`RequestForm`).toPromise()

      let [serviceTypes, repLocalProvs, applicantTypes, Groups, conditions, requestForms] = await Promise.all([
        serviceTypesP, repLocalProvsP, applicantTypesP, GroupsP, conditionsP, requestFormsP
      ])
      this.serviceTypes = serviceTypes;
      this.repLocalProvs = repLocalProvs;
      this.applicantTypes = applicantTypes;
      this.Groups = Groups;
      this.conditions = conditions;
      this.requestForms = requestForms

      if (!localStorage.getItem('ServiceDropDowns')) {
        this.saveInLS()
      }
    } else {
      this.assignLS(JSON.parse(localStorage.getItem('ServiceDropDowns')));

    }
    this.activityBranchs = await this.HttpService.get<DropDownOptionModel[]>(`TypeOfActivityBranch`).toPromise();
    this.identificationTypes = await this.HttpService.get<any[]>(`TypeOfIdentification`).toPromise();
    this.nationalityDrop = await this.HttpService.get<any[]>(`Nationality`).toPromise();
    this.setDocumetType();


  }
  setDocumetType(objectIdentificationType?: any, index?: number) {
     
    if(objectIdentificationType !== undefined){
      let id = objectIdentificationType.identificationTypeId?.value;
 
      if (id) {
        if (this.identificationTypes) {
          let code: string = this.identificationTypes.find(doc => doc.value == id)?.alternateField
  
          this.documentTypeCode = code;
          this.identificationValidators(this.documentTypeCode)
        }
  
      } else {
        this.documentTypeCode = null;
      }
    }else{
      if(this.userServiceForm !== undefined){
        this.userServiceForm.value.applicantsListed.forEach(item => {
           
          let id =  item.identificationTypeId;
 
          if (id) {
            if (this.identificationTypes) {
              let code: string = this.identificationTypes.find(doc => doc.value == id)?.alternateField
      
              this.documentTypeCode = code;
              this.identificationValidators(this.documentTypeCode)
            }
      
          } else {
            this.documentTypeCode = null;
          }         
        });
      }

    }






  }

  identificationValidators(event) {
    if (!this.isFilter) {
      if (event !== 'CD') {
        this.userServiceForm.get('identification').setValidators([Validators.required, Validators.minLength(5)])
        this.userServiceForm.get('identification').updateValueAndValidity();

      }
      else {
        this.userServiceForm.get('identification').clearValidators()
        this.userServiceForm.get('identification').setValidators(Validators.required);
        this.userServiceForm.get('identification').updateValueAndValidity();

      }
    }
  } 
  otherRequestForm() {
    if (!this.isFilter) {
      let option = this.userServiceForm.get('requestFormId').value;

      if (
        this.requestForms?.find((x) => x.value == option)?.hasOptionalField ==
        true
      ) {
        this.otherForm = true;
        this.userServiceForm
          .get('requestFormOther')
          .setValidators([Validators.required]);
        this.userServiceForm.get('requestFormOther').updateValueAndValidity();
      } else {
        this.otherForm = false;
        this.userServiceForm.get('requestFormOther').clearValidators();
        this.userServiceForm.get('requestFormOther').updateValueAndValidity();
      }
    }
  }

  otherTypeApplicant(value: number, index: number) {
    const item = (<FormArray>this.userServiceForm.get('applicantsListed')).at(
      index
    );

    if (
      this.applicantTypes?.find((x) => x.value == value)?.hasOptionalField ==
      true
    ) {
      item.get('applicantTypeOther').setValidators([Validators.required]);
      item.get('applicantTypeOther').updateValueAndValidity();
    } else {
      item.get('applicantTypeOther').clearValidators();
      item.get('applicantTypeOther').updateValueAndValidity();
    }
  }

  isOtherApplicant(value: number) {
    return this.applicantTypes?.find((x) => x.value == value)
      ?.hasOptionalField == true
      ? true
      : false;
  }

  otherServices() {
    let option: any[] = this.userServiceForm.get('typeOfServiceListed').value;
    let test = false;
    option.map((t) => {
      if (
        this.serviceTypes?.find((x) => x.value == t.value)?.hasOptionalField ==
        true
      ) {
        test = true;
        this.userServiceForm
          .get('typeOfServiceOther')
          .setValidators([Validators.required]);
        this.userServiceForm.get('typeOfServiceOther').updateValueAndValidity();
      } else {
        this.userServiceForm.get('typeOfServiceOther').clearValidators();
        this.userServiceForm.get('typeOfServiceOther').updateValueAndValidity();
      }
    });

    this.otherService = test;
  }

  async saveRecord() {
    this.isSaving = true;

    if (this.userServiceForm.valid) {
      if (!this.userServiceInput) {
        if (this.tool.hasInternetConnection) {
          this.HttpService.post<UserService>(
            this.mapService(this.userServiceForm.value),
            'UserService'
          ).subscribe({
            next: (response) => {
              // this.toast.success('guardado correctamente', response.serviceOrderNumber)
              this.sweet.record(
                'success',
                `Registro No.${response.userServiceNumber}`,
                'Su servicio se ha registrado correctamente',
                `Este caso se ha añadido a su historial de registro`
              );
              localStorage.setItem('typeHistory', 'SERVU');

              return this.router.navigate(['./historial'], {
                relativeTo: this.route.parent.parent,
              });
            },
            error: (error) => {
              this.toast.error(
                'favor inténtelo mas tarde!',
                'La aplicación no esta disponible'
              );
            },
          });
        }
        else {
          this.sweet.record('success', `Registro local `, 'Su caso se ha registrado correctamente', `Este caso será añadido a sus registros locales`)
          this.pwa.addServiceTask(this.mapService(this.userServiceForm.value, true));
          this.clean()
        }
      } else {
        if (
          this.applicantsCopy !==
          this.userServiceForm.get('applicantsListed').value &&
          this.applicantsCopy.length >
          this.userServiceForm.get('applicantsListed').value.length
        )
          await this.deleteApplicants();
           
          let params:UserService;
          params = this.mapService(this.userServiceForm.value);
          if(params.modifiedBy) params.modifiedBy = this.user.getUserData().userCode;
          if(params.recordModificationDate) params.recordModificationDate = new Date().toString();
         
        this.HttpService.update<UserService>(this.mapService(this.userServiceForm.value),'UserService').subscribe({
          next: (response) => {
            // this.toast.success('guardado correctamente', response.serviceOrderNumber)
            this.sweet.record(
              'success',
              `Registro No.${this.userServiceInput.userServiceNumber}`,
              'Su servicio se ha actualizado correctamente',
              `Este caso se ha añadido a su historial de registro`
            );
            return this.router.navigate(['./historial'], {
              relativeTo: this.route.parent.parent,
            });
          },
          error: (error) => {
            this.toast.error(
              'favor inténtelo mas tarde!',
              'La aplicación no esta disponible'
            );
          },
        });
      }
    } else {
      this.tool.createWarning(this.userServiceForm, this.elem).then(
        result => this.notFound()
      )
    }
  }
  namesToObject(names: string) {
    if (names.split(',').length > 1) {
      let List: any[] = names.split(',').map((x) => {
        return { display: x, value: x };
      });
      return List;
    } else {
      return names;
    }
  }

  async deleteApplicants() {
    let applicants = this.ApplicantsToDelete.map((a) => {
      return `idList=${a}`;
    }).join('&');
    await this.HttpService.patch(
      this.ApplicantsToDelete,
      `UserServiceApplicant?${applicants}`
    ).toPromise();
  }




  mapService(service: UserService, id?: boolean) {
   
    let serviesTypes = service.typeOfServiceListed.map((motivo) => {
      return motivo['value'];
    });

    var map = {
      reportDate: new Date(
        `${service.reportDate['year']}-${service.reportDate['month']}-${service.reportDate['day']}`
      ),
      typeOfServiceListed: serviesTypes.join(','),
      groupQuantity: this.userServiceForm.get('applicantsListed').value.length,
    };

    return !id ? { ...this.userServiceForm.value, ...map } : { ...this.userServiceForm.value, ...map, ...{ _id: uuid.v4() } };
  }

  checkValidity() {
    if (this.userServiceForm.get('LocalApplicant').value == 'G') {
      if (
        this.userServiceForm.get('applicantsListed').value.length ==
        this.userServiceForm.get('groupQuantity').value
      ) {
        return this.userServiceForm.get('applicantsListed').value.length > 1
          ? false
          : true;
      } else {
        return true;
      }
    }
  }


  chooseTypeApplicant(value) {
    this.userServiceForm.patchValue({
      'LocalApplicant': value
    })
    const control = <FormArray>(
      this.userServiceForm.controls['applicantsListed']
    );
    if (value == 'G') {
      if (control.value.length < 1) {
        this.addApplicant();
        this.addApplicant();
      } else {
        this.addApplicant();
      }

      this.userServiceForm.patchValue({
        groupQuantity: 2,
      });
      this.GroupCount = 2;
      this.userServiceForm
        .get('groupQuantity')
        .setValidators([Validators.required]);
      this.userServiceForm.get('groupQuantity').updateValueAndValidity();
    } else {
      if (control.length >= 1) {
        if (control.length == 1) {
          this.clearUntilOne(control);
        }
        else {
          this.tool.createSwalModal('¿Está seguro(a) que desea continuar?', 'Se eliminarán los solicitantes', 'warning').then(result => {
            if (result.isConfirmed) { this.clearUntilOne(control); }
            else {
              this.userServiceForm.patchValue({
                'LocalApplicant': 'G'
              })
            }
          })
        }
      } else {
        this.addApplicant();
      }

      this.GroupCount = 1;
      this.userServiceForm.get('groupQuantity').clearValidators();
      this.userServiceForm.get('groupQuantity').updateValueAndValidity();
    }
  }
  clearFormArray = (formArray: FormArray) => {
    const control = <FormArray>(
      this.userServiceForm.controls['applicantsListed']
    );

    while (formArray.length !== 0) {
      if (control.controls[0].value.applicantDataId) {
        this.ApplicantsToDelete.push(control.controls[0].value.applicantDataId);
      }
      formArray.removeAt(0);
    }
  };

  clearUntilOne(formArray: FormArray) {
    const control = <FormArray>(
      this.userServiceForm.controls['applicantsListed']
    );

    while (formArray.length !== 1) {
      if (control.controls[formArray.length - 1].value.applicantDataId) {
        this.ApplicantsToDelete.push(
          control.controls[formArray.length - 1].value.applicantDataId
        );
      }
      formArray.removeAt(formArray.length - 1);
    }
  }

  notFound() {
    this.show = true;
    setTimeout(() => { this.show = false }, 1000)
  }

  saveInLS() {
    let dropDowns = {
      serviceTypes: this.serviceTypes,
      repLocalProvs: this.repLocalProvs,
      applicantTypes: this.applicantTypes,
      Groups: this.Groups,
      conditions: this.conditions,
      requestForms: this.requestForms
    };
    localStorage.setItem('ServiceDropDowns', JSON.stringify(dropDowns))
  }
  assignLS({ serviceTypes,
    repLocalProvs,
    applicantTypes,
    Groups,
    conditions,
    requestForms

  }) {
    this.serviceTypes = serviceTypes;
    this.repLocalProvs = repLocalProvs;
    this.applicantTypes = applicantTypes;
    this.Groups = Groups;
    this.conditions = conditions;
    this.requestForms = requestForms
  }


  clean() {
    this.userServiceForm.patchValue({
      userServiceId: 0,
      userServiceNumber: null,
      reportDate: null,
      reportHour: null,
      repLocalProvId: this.user.getUserData().repLocalProvId,
      applicantTypeOther: null,
      TypeOfApplicantUserServiceId: null,
      groupQuantity: 0,
      LocalApplicant: null,
      typeOfServiceListed: null,
      typeOfServiceOther: null,
      requestFormId: null,
      requestFormOther: null,
      activityBranchRLTId: null,
      companyArea: null,
      nationalityId: null,
      identification: null,
      identificationTypeId: null,
      observations: null,
      groupId: null,
      registeredBy: this.user.getUserData().userCode,
      recordModificationDate: null,
      modifyBy: this.user.getUserData().userCode,
      recordStatus: true
    })
    const control = <FormArray>(
      this.userServiceForm.controls['applicantsListed']
    );

    while (control.length !== 0) {
      if (control.controls[control.length - 1].value.applicantDataId) {
        this.ApplicantsToDelete.push(
          control.controls[control.length - 1].value.applicantDataId
        );
      }
      control.removeAt(control.length - 1);
    }
  }

  setClass() {

    if (this.userServiceForm.get('typeOfServiceListed').invalid && this.userServiceForm.get('typeOfServiceListed').touched) { return 'invalid' }
    else { return '' }
  }
}
