import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class routeDataService {
  constructor() { }
  subject = new Subject<boolean>();
  edition = new Subject<{ estados: any[], id: number }>();
}