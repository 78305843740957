<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-2 d-none d-lg-block p-0"><app-sidebar></app-sidebar></div>
    <div class="col-lg-10 col-sm-12 p-0 hidden">
      <div class="row h-100">
        <div class="col">
          <div class="row h-20">
            <div class="col"><app-navbar></app-navbar></div>
          </div>

          <hr style="height: 0px" />
          <div class="row h-80" style="height: 100% !important">
            <div class="col">
              <ngx-loading-bar color="#003785" height="4px"></ngx-loading-bar>

              <router-outlet></router-outlet>
            </div>
          </div>
        </div>

        <!-- <div class="d-flex flex-column  p-0 "><app-navbar></app-navbar></div>
              <div class=" d-flex flex-column p-0 ms-4 routeoutlet">
                 <div class="container m-5">
  
                    <router-outlet></router-outlet>
                 </div>
                   
       
              </div> -->
      </div>
    </div>
  </div>
</div>
