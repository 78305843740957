<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="centered-content mb-3">
      <div *ngIf="!pending?.length; else pendingList">
        <ul *ngIf="warningFields">
          <li class="text-danger" *ngFor="let field of warningFields">
            {{ field }}
          </li>
        </ul>
        <div *ngIf="formArray.length > 0">
          <div *ngFor="let applicant of formArray; let i = index">
            <div *ngIf="formArray[i].length > 0">
              <h6>
                {{ "Solicitante " + (formArray.length > 1 ? i + 1 : "") }}
              </h6>
              <ul>
                <li class="text-danger" *ngFor="let control of applicant">
                  {{ control }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ng-template #pendingList>
        <h4 class="mb-2">{{ subTitle }}</h4>
        <ul>
          <li class="text-danger" *ngFor="let item of pending">
            {{ item }}
          </li>
        </ul>
      </ng-template>
    </div>
  </div>
</div>
