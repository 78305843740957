<div class="m-2">
  <app-prime-table
    [records]="records"
    [loading]="loading"
    [cols]="cols"
    [buttons]="customButtons"
    [filters]="filters"
    [paginator]="false"
    [hasGlobalFilters]="false"
    [hasIndividualFilters]="false"
  ></app-prime-table>
</div>
