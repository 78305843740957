<div class="form-group w-100" [style]="customStyle" *ngIf="!button; else withButtons" #selectContainer>

  <p-dropdown [options]="options" [ngModel]="_selectedId" (ngModelChange)="changeValue($event)" [optionLabel]="textName || 'text'" [optionValue]="valueName || 'value'" [filter]="filterable" [filterBy]="textName || 'text'"
    [showClear]="true" [placeholder]="placeHolder || 'Elige una opción'" [disabled]="disabled">

  </p-dropdown>

</div>

<ng-template #withButtons>
  <div class="input-group mb-3">
    <select name="selectFilter" class="withButton" [ngClass]="customClass + ' ' + class + ' ' + 'form-select'"
      (change)="changeValue($event.target.value)" [disabled]="disabled" style="border-radius: 0px">
      <option [value]="null" [disabled]="true" [selected]="counterValue === null">
        {{ this.placeHolder ? this.placeHolder : "Elige una opción" }}
      </option>
      <option *ngFor="let option of options" [value]="option.value" [selected]="option.value === counterValue">
        {{ option.text }}
      </option>
    </select>
    <button [disabled]="this._selectedId == null" [class]="button.class" type="button"
      (click)="button.function(this._selectedId)" style="border-radius: 0px; border: 1px solid grey !important">
      <i [class]="button.icon"></i>
    </button>
  </div>
</ng-template>