<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="canEdit ? close(false) : close(true)"
    ></button>
  </div>
  <div class="modal-body">
    <div class="px-3 mt-2">
      <label class="title form-control-lg mb-2 fs-2 fw-bold"
        >RUTA DE LA VISITA
      </label>
      <div class="mb-4" [ngClass]="{ 'border-bottom border-1 pb-5': results }">
        <div [ngClass]="{ cantEditCols: !canEditRoute }">
          <div [ngClass]="{ cantEdit: !canEditRoute }">
            <form [formGroup]="routeForm">
              <div class="row mb-1">
                <div class="col-md-4 col-lg-4 col-12">
                  <label class="form-label form-control-lg"
                    >Provincia
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="routeForm.get('provinceCode').invalid"
                      >*</small
                    >
                  </label>

                  <el-dropdown
                    [options]="provinces"
                    formControlName="provinceCode"
                    customClass="form-control form-control-lg"
                    placeHolder="Elige un estatus"
                    [stringOut]="true"
                    valueName="value"
                    (ngModelChange)="getMunicipality($event)"
                  ></el-dropdown>
                </div>
                <div class="col-md-4 col-lg-4 col-12">
                  <label class="form-label form-control-lg"
                    >Municipio
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="routeForm.get('municipalityCode').invalid"
                      >*</small
                    >
                  </label>

                  <el-dropdown
                    [disabled]="routeForm.get('provinceCode').invalid"
                    [options]="municipalities"
                    formControlName="municipalityCode"
                    customClass="form-control form-control-lg"
                    placeHolder="Elige un estatus"
                    [stringOut]="true"
                    valueName="value"
                    (ngModelChange)="getmunicipalDistrict($event)"
                  ></el-dropdown>
                </div>
                <div class="col-md-4 col-lg-4 col-12">
                  <label class="form-label form-control-lg"
                    >Distrito&nbsp;Municipal
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="routeForm.get('municipalDistrictId').invalid"
                      >*</small
                    >
                  </label>

                  <el-dropdown
                    [disabled]="routeForm.get('municipalityCode').invalid"
                    [options]="municipalDistricts"
                    formControlName="municipalDistrictId"
                    customClass="form-control form-control-lg"
                    placeHolder="Elige un estatus"
                  ></el-dropdown>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-4 col-lg-4 col-12 mb-1">
                  <label class="form-label form-control-lg">Sección </label>

                  <input
                    formControlName="section"
                    type="text"
                    placeholder="Sección"
                    class="form-control form-control-lg"
                    specialIsAlphaNumeric
                    [fControl]="routeForm.get('section')"
                  />
                </div>
                <div class="col-md-4 col-lg-4 col-12 mb-1">
                  <label class="form-label form-control-lg">Paraje </label>

                  <input
                    formControlName="place"
                    type="text"
                    placeholder="Paraje"
                    class="form-control form-control-lg"
                  />
                </div>
                <div class="col-md-4 col-lg-4 col-12 mb-1">
                  <label class="form-label form-control-lg">Dirección </label>

                  <input
                    formControlName="address"
                    type="text"
                    placeholder="Dirección"
                    class="form-control form-control-lg"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-12 mb-1">
                  <label class="form-label form-control-lg"
                    >Ruta&nbsp;Cuadrante
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="routeForm.get('route').invalid"
                      >*</small
                    >
                  </label>

                  <input
                    formControlName="route"
                    type="text"
                    placeholder="Ruta/Cuadrante"
                    class="form-control form-control-lg"
                    specialIsAlphaNumeric
                    [fControl]="routeForm.get('route')"
                  />
                </div>
                <div class="col-md-4 col-12">
                  <label class="form-label form-control-lg mb-1"
                    >Fecha de Visita
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="routeForm.get('startDate').invalid"
                      >*
                    </small>
                  </label>
                  <div class="input-group">
                    <input
                      class="form-control form-control-lg"
                      placeholder="Fecha Creación"
                      name="dp"
                      formControlName="startDate"
                      ngbDatepicker
                      #d="ngbDatepicker"
                      maxlength="10"
                      [minDate]="minDate"
                      [markDisabled]="tool.isDisabled"
                    />
                    <div class="input-group-append">
                      <button
                        pButton
                        icon="pi pi-calendar"
                        class="btn btn-outline-secondary btn-lg"
                        (click)="d.toggle()"
                        type="button"
                      ></button>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <label class="form-label form-control-lg"
                    >Inspector Asignado
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="routeForm.get('assignedInspectorCode').invalid"
                      >*
                    </small>
                  </label>
                  <el-dropdown
                    [options]="inspectors"
                    formControlName="assignedInspectorCode"
                    customClass="form-control form-control-lg"
                    placeHolder="Elige un estatus"
                    [stringOut]="true"
                  ></el-dropdown>
                </div>
              </div>
              <div class="form-row mb-1">
                <div class="col-12 mb-1">
                  <label class="form-label form-control-lg"
                    >Empresas a Visitar
                    <small
                      [@warning]="tool.stateName(show)"
                      class="text-danger"
                      *ngIf="routeForm.get('companyToVisitListed').invalid"
                      >*
                    </small>
                  </label>
                  <ng-multiselect-dropdown
                    [placeholder]="'Empresas a Visitar Selección Multiple'"
                    [settings]="dropdownSettings"
                    [data]="companies"
                    formControlName="companyToVisitListed"
                    class="multiselect"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <button
                    type="button"
                    class="rounded-pill btn btn-save px-5 float-right"
                    data-bs-dismiss="modal"
                    *ngIf="canEditRoute"
                    (click)="saveRoute()"
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="form-row" *ngIf="geoInfo">
          <div class="col-md col-6 mb-1">
            <button (click)="openLocation()" class="btn btn-primary">
              ver locación
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="resultsForm.controls.length > 0">
        <div [ngClass]="{ cantEditCols: !canEditResult }">
          <div [ngClass]="{ cantEdit: !canEditResult }">
            <label class="title form-control-lg mb-2 fs-2 fw-bold"
              >RESULTADOS DE LA VISITA
            </label>

            <div class="accordion" id="accordionContainer">
              <div
                class="accordion-item mb-2"
                *ngFor="let result of resultsForm.controls; let i = index"
                [ngClass]="result.valid ? 'visit' : 'notVisit'"
              >
                <h2 class="accordion-header" [id]="'heading' + i">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse' + i"
                    aria-expanded="false"
                    [attr.aria-controls]="'collapse' + i"
                  >
                    {{ result.get("companyTradeName").value }}
                  </button>
                </h2>
                <div
                  [id]="'collapse' + i"
                  class="accordion-collapse collapse"
                  [attr.aria-labelledby]="'heading' + i"
                  data-bs-parent="#accordionContainer"
                >
                  <div class="accordion-body">
                    <span [@rncPopOver]="stateName" class="badge badge-danger"
                      >no se encontraron datos</span
                    >
                    <form [formGroup]="result">
                      <div class="form-row">
                        <div
                          *ngIf="result.get('isManuallyCreated').value"
                          class="col-md col-12 mb-1"
                        >
                          <label class="form-label form-control-lg"
                            >Nombre Empresa
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="result.get('companyTradeName').invalid"
                              >*</small
                            >
                          </label>
                          <div class="input-group mb-3">
                            <input
                              (keyup.enter)="
                                assignSpecificName(result, $event.target.value)
                              "
                              (blur)="
                                assignSpecificName(result, $event.target.value)
                              "
                              type="text"
                              placeholder="Nombre de Empresa"
                              class="form-control form-control-lg"
                              specialIsAlphaNumeric
                              formControlName="companyTradeName"
                              [fControl]="result.get('companyTradeName')"
                            />
                            <div class="input-group-append">
                              <button
                                [disabled]="
                                  !result.get('companyTradeName').value
                                "
                                class="btn btn-outline-secondary"
                                type="button"
                                (click)="
                                  getCompany(
                                    result,
                                    result.get('companyTradeName').value,
                                    'companyTradeName'
                                  )
                                "
                              >
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-md col-6 mb-1"
                          *ngIf="result.get('isManuallyCreated').value"
                        >
                          <label class="form-label form-control-lg">RNL </label>
                          <div class="input-group mb-3">
                            <input
                              type="number"
                              min="0"
                              onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                              class="form-control form-control-lg rnl"
                              formControlName="specificCompanyRnl"
                            />
                            <div class="input-group-append">
                              <button
                                [disabled]="
                                  !result.get('specificCompanyRnl').value
                                "
                                class="btn btn-outline-secondary"
                                type="button"
                                (click)="
                                  getCompany(
                                    result,
                                    result.get('specificCompanyRnl').value,
                                    'rnl'
                                  )
                                "
                              >
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md col-12">
                          <label class="form-label form-control-lg"
                            >Fecha de Cierre
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="result.get('closeDate').invalid"
                              >*
                            </small>
                          </label>
                          <div class="input-group">
                            <input
                              class="form-control form-control-lg"
                              placeholder="Fecha Creación"
                              name="dp"
                              formControlName="closeDate"
                              ngbDatepicker
                              #d="ngbDatepicker"
                              maxlength="10"
                              [minDate]="minDate"
                            />
                            <div class="input-group-append">
                              <button
                                pButton
                                icon="pi pi-calendar"
                                class="btn btn-outline-secondary btn-lg"
                                (click)="d.toggle()"
                                type="button"
                              ></button>
                            </div>
                          </div>
                        </div>
                        <div class="col-md col-12 mb-1">
                          <label class="form-label form-control-lg"
                            >Resultado
                            <small
                              [@warning]="tool.stateName(show)"
                              class="text-danger"
                              *ngIf="result.get('resultId').invalid"
                              >*
                            </small>
                          </label>

                          <el-dropdown
                            [options]="resultDropdown"
                            customClass="form-control form-control-lg"
                            [stringOut]="true"
                            placeHolder="Elige una gestion"
                            formControlName="resultId"
                          ></el-dropdown>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-12 col-12">
                          <label class="form-label form-control-lg"
                            >Resumen&nbsp;de&nbsp;la&nbsp;Gestión
                          </label>

                          <textarea
                            type="text"
                            formControlName="managementResult"
                            placeholder="Justificación otros solicitantes"
                            class="form-control form-control-lg mb-3"
                            specialIsAlphaNumeric
                            [fControl]="result.get('managementResult')"
                          ></textarea>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-12 col-12">
                          <label class="form-label form-control-lg">
                            Comentarios (Opcional)
                          </label>
                          <textarea
                            type="text"
                            formControlName="comment"
                            placeholder="Justificación otros solicitantes"
                            class="form-control form-control-lg mb-3"
                            specialIsAlphaNumeric
                            [fControl]="result.get('comment')"
                          ></textarea>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div
                            class="btn-group float-right text-sm-center mt-3"
                          >
                            <button
                              type="button"
                              class="rounded-pill btn btn-save px-5 mr-2"
                              (click)="saveResult(result)"
                            >
                              Guardar
                            </button>
                            <button
                              *ngIf="
                                !result.get('hasOrder').value &&
                                result.get('registrationDate').value &&
                                result.valid
                              "
                              type="button"
                              class="rounded-pill btn btn-save px-5"
                              (click)="createOrder(result.value)"
                            >
                              Crear Orden de Servicio
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 text-center">
                <button
                  pButton
                  icon="pi pi-plus"
                  iconPos="right"
                  label="Agregar Resultado de visita a Empresa no Existente"
                  class="px-4"
                  (click)="addResult()"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
