<div class="dropdown">
  <a
    class="dropdown-toggle"
    href="#"
    role="button"
    id="notifications"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i
      pBadge
      [value]="
        notifications?.length
          ? notifications.length > 99
            ? '99+'
            : notifications.length
          : notifications.length
      "
      severity="danger"
      class="fa fa-bell-o css-drop-settings"
      aria-hidden="true"
    ></i>
    <!-- <i
   
    class="fa fa-bell-o css-drop-settings"
    [ngClass]="{ 'faa-shake animated faa-slow': notifications?.length > 0 }"
    aria-hidden="true"
  ></i> -->
    <!-- <span
      class="cs-next-bell fa faa-flash badge badge-danger"
      [ngClass]="{ 'animated faa-slow': notifications?.length > 0 }"
      >{{ notifications?.length }}</span -->
    &nbsp;&nbsp;
  </a>

  <div
    class="dropdown-menu drop dropdown-menu-right dropdown-menu-lg-start"
    aria-labelledby="notifications"
  >
    <div *ngIf="notifications.length; else empty">
      <div
        class="card"
        style="width: 15rem"
        *ngFor="let notification of notifications"
      >
        <div [class]="'card-header ' + notification.cssStatusCode">
          {{ notification.title }}
        </div>
        <div class="card-body">
          <p class="text-justify">
            {{ notification.userAlertInfo }}
          </p>

          <div class="justify-content-between">
            <button
              pButton
              class="p-button-rounded info css-label-btn-grid float-end"
              iconPos="left"
              icon="pi pi-eye"
              label="Visto"
              (click)="
                delete(notification.userAlertId); $event.stopPropagation()
              "
            ></button>
            <button
              pButton
              class="p-button-rounded info css-label-btn-grid mr-2 float-end"
              iconPos="left"
              icon="pi pi-reply"
              label="Ir"
              (click)="seeDetail(notification)"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <ng-template #empty>
      <div class="card empty" style="width: 15rem">
        <div class="card-body">
          <p class="text-justify">No tienes notificaciones</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>
