import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DropDownOptionModel } from '../../shared/_elements/element-ui/dropdown/models/dropdown-option-model';
import { HttpClientService } from '../../shared/_services/http-client/http-client.service';
import { SweetAlertService } from '../../shared/_services/sweetAlert/sweet-alert.service';
import { ToastService } from '../../shared/_services/toast/toast.service';
import { ToolsService } from '../../shared/tools/tools.service';
import { BasicUserInfoDto } from '../models/basic-user-info-dto';
import { TableUser } from '../models/tableUser';
import { getTypeOfIdentificationMask } from '../../shared/_models/utils';

@Component({
  selector: 'app-user-history-edit',
  templateUrl: './user-history-edit.component.html',
  styleUrls: ['./user-history-edit.component.css']
})
export class UserHistoryEditComponent implements OnInit {

  @Input() currentUser: TableUser;
  @Input() usersUpdates: BasicUserInfoDto;
  localReps: DropDownOptionModel[];
  identificationTypes: DropDownOptionModel[];

  mask?: string = '?';

  profileForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    secondName: new FormControl(''),
    lastName: new FormControl('', [Validators.required]),
    secondLastName: new FormControl(''),
    typeOfIdentificationId: new FormControl(21, [Validators.required]),
    identification: new FormControl('', [Validators.required]),
    repLocalProvId: new FormControl('', [Validators.required]),
    userCode: new FormControl('', [Validators.required]),
    recordStatus: new FormControl('', [Validators.required]),
  })

  constructor(public ngbActiveModal: NgbActiveModal, public http: HttpClientService,
    public toast: ToastService, public sweet: SweetAlertService, private tool: ToolsService) { }

  ngOnInit(): void {
    this.getUserbyUsercode();

    this.http.get<DropDownOptionModel[]>(`TypeOfIdentification`).subscribe((response) => {
      this.identificationTypes = response
    });

    // this.http.get<DropDownOptionModel[]>(`LocalRepresentativeProvince`).subscribe(reps => {
    //   this.localReps = reps;
    // })
  }
  // butons for status
  stateOptions: any[] = [{ label: 'Inactivo', value: false }, { label: 'Activo', value: true }];
  value: string = 'Activo';

  public getUserbyUsercode() {
    return this.http.get<any>(`User/by-code/${this.currentUser.userCode}`).subscribe(data => {
      this.typeOfIdentificationChanged(data.typeOfIdentificationId || 21);
      this.profileForm.patchValue(Object.assign(data, { lastName: data.firstLastName }));
    })
  }

  public userUpdate() {
    if (this.profileForm.valid)
      this.http.update<BasicUserInfoDto>(this.profileForm.value, 'User/update-basic-info').subscribe({
        next: user => {
          this.sweet.record('success', '', 'Usuario editado correctamente', '');
          this.ngbActiveModal.close(this.profileForm.value);
        }, error: err => {
          console.log(err);
          this.toast.error('favor inténtelo mas tarde!', 'La aplicación no esta disponible')
        }
      });
  }

  typeOfIdentificationChanged(typeId: number) {
    this.mask = getTypeOfIdentificationMask(typeId);
  }
}
