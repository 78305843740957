<div *ngIf="!isFilter; else isTheFilter">
  <div class="row" [ngClass]="{ cantEditCols: !canEdit }">
    <form
      [formGroup]="serviceOrder"
      class="pl-sm-0"
      [ngClass]="{ cantEdit: !canEdit }"
    >
      <div class="form-row">
        <div class="col-md-3 col-12 mb-1">
          <label class="form-label form-control-lg"
            >Fecha&nbsp;de&nbsp;Orden
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('orderDate').invalid"
              >*</small
            >
          </label>
          <div class="input-group">
            <input
              class="form-control form-control-lg"
              placeholder="Fecha Orden"
              name="dp"
              formControlName="orderDate"
              ngbDatepicker
              [maxDate]="maxDate"
              [minDate]="minDateOrder"
              #d="ngbDatepicker"
              [disabled]="serviceOrderInput"
              maxlength="10"
              [appMaskValue]="serviceOrder.get('orderDate').value"
              dateMask
            />
            <div class="input-group-append">
              <button
                pButton
                icon="pi pi-calendar"
                class="btn btn-outline-secondary btn-lg"
                (click)="d.toggle()"
                type="button"
                [disabled]="serviceOrderInput"
              ></button>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-12 mb-1">
          <label class="form-label form-control-lg"
            >Representación&nbsp;Local
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('repLocalProvId').invalid"
              >*</small
            ></label
          >
          <el-dropdown
            [options]="localRepresentatives"
            formControlName="repLocalProvId"
            customClass="form-control form-control-lg"
            [disabled]="!isDirector"
            placeHolder="Elige una representación Local"
          ></el-dropdown>
        </div>
        <div class="col-md-3 col-12 mb-1">
          <label class="form-label form-control-lg"
            >Tipo de Servicio
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('serviceTypeId').invalid"
              >*</small
            ></label
          >
          <el-dropdown
            [options]="serviceTypes"
            customClass="form-control form-control-lg"
            formControlName="serviceTypeId"
            (onChange)="changeWithNotice($event)"
            [disabled]="serviceOrderInput"
            placeHolder="Elige un tipo de servicio"
          ></el-dropdown>
        </div>
        <div class="col-md-3 col-12 mb-1">
          <label class="form-label form-control-lg"
            >Tipo de Solicitante
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('applicantTypeId').invalid"
              >*</small
            ></label
          >
          <el-dropdown
            [options]="applicantTypes"
            customClass="form-control form-control-lg"
            formControlName="applicantTypeId"
            [disabled]="serviceOrder.get('serviceTypeId').invalid"
            (onChange)="otherApplicantTypes()"
            placeHolder="Elige un tipo de solicitante"
          ></el-dropdown>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 mb-1" *ngIf="otherApplicant">
          <label class="form-label form-control-lg">
            Justificación Tipo Solicitante
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('justifAnotherTypeApplicant').invalid"
              >*</small
            ></label
          >
          <textarea
            specialIsAlphaNumeric
            [fControl]="serviceOrder.get('justifAnotherTypeApplicant')"
            type="text"
            name="justifAnotherTypeApplicant"
            class="form-control form-control-lg mb-3"
            formControlName="justifAnotherTypeApplicant"
          ></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg"
            >Forma de Solicitud
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('requestFormId').invalid"
              >*</small
            ></label
          >
          <el-dropdown
            [options]="requestForms"
            formControlName="requestFormId"
            customClass="form-control form-control-lg"
            placeHolder="Elige una forma de solicitud"
          ></el-dropdown>
        </div>
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg"
            >Alerta&nbsp;para&nbsp;Vencimiento
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('amountDaysNoticeClosing').invalid"
              >*</small
            ></label
          >
          <input
            type="number"
            min="0"
            readonly
            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
            class="form-control form-control-lg"
            formControlName="amountDaysNoticeClosing"
          />
          <small
            [@warning]="tool.stateName(show)"
            class="text-danger"
            *ngIf="serviceOrder.get('amountDaysNoticeClosing')?.errors?.min"
            >debe de haber almenos 1 dia</small
          >
        </div>
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg"
            >Fecha Estimada Cierre
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('estimatedDeadLine').invalid"
              >*</small
            ></label
          >
          <div class="input-group">
            <input
              class="form-control form-control-lg"
              placeholder="Fecha Estimada Cierre"
              name="estimatedDeadLine"
              formControlName="estimatedDeadLine"
              ngbDatepicker
              #r="ngbDatepicker"
              readonly
              [markDisabled]="tool.isDisabled"
              [disabled]="true"
              (click)="r.toggle()"
            />
            <div class="input-group-append">
              <button
                pButton
                icon="pi pi-calendar"
                class="btn btn-outline-secondary btn-lg"
                (click)="r.toggle()"
                type="button"
                [disabled]="true"
              ></button>
            </div>
          </div>
        </div>
        <div class="col-md col-12 mb-1" *ngIf="serviceOrderInput">
          <label class="form-label form-control-lg">
            Nueva Fecha Cierre&nbsp;&nbsp;
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('newEstimatedDeadline').invalid"
              >*</small
            ></label
          >
          <div class="input-group">
            <input
              class="form-control form-control-lg cs-calendar"
              placeholder="Nueva Fecha Cierre"
              name="newEstimatedDeadline"
              formControlName="newEstimatedDeadline"
              [minDate]="tool.dateToObject(serviceOrderInput.estimatedDeadLine)"
              [maxDate]="maxEstimatedDate"
              ngbDatepicker
              [markDisabled]="tool.isDisabled"
              (dateSelect)="newDeadlineValidator()"
              #p="ngbDatepicker"
              readonly
              [disabled]="!canSetNewDate"
              (click)="p.toggle()"
            />
            <div class="input-group-append">
              <button
                pButton
                icon="pi pi-calendar"
                class="btn btn-outline-secondary btn-lg"
                (click)="p.toggle()"
                type="button"
                [disabled]="!canSetNewDate"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div
          class="col-md-12 col-12 mb-1"
          *ngIf="serviceOrder.get('newEstimatedDeadline').value"
        >
          <label class="form-label form-control-lg"
            >Motivo Cambio de Fecha Cierre
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="
                serviceOrder.get('justificationChangeEstimatedDateId').invalid
              "
              >*</small
            ></label
          >
          <el-dropdown
            [options]="Justifications"
            formControlName="justificationChangeEstimatedDateId"
            customClass="form-control form-control-lg"
            (onChange)="otherJustification()"
            placeHolder="Elige un motivo de cambio de fecha"
          ></el-dropdown>
        </div>
        <div
          class="col-md-12 col-12 mb-1"
          *ngIf="serviceOrderInput && Justification"
        >
          <label class="form-label form-control-lg">
            Justificación Otros
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="
                serviceOrder.get('justificationChangeEstimatedDate').invalid
              "
              >*</small
            ></label
          >
          <textarea
            specialIsAlphaNumeric
            [fControl]="serviceOrder.get('justificationChangeEstimatedDate')"
            type="text"
            name="justificationChangeEstimatedDate"
            placeholder="Justificación otros"
            class="form-control form-control-lg mb-3"
            formControlName="justificationChangeEstimatedDate"
          ></textarea>
        </div>
        <div class="col-md-12 col-12 mb-1">
          <label class="form-label form-control-lg"
            >Motivos de Inspección
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('inspectionReasonsListed').invalid"
              >*</small
            ></label
          >
          <ng-multiselect-dropdown
            [placeholder]="'Motivos de inspección Selección Multiple'"
            [settings]="dropdownSettings"
            [data]="inspectionMotives"
            [ngClass]="setClass()"
            formControlName="inspectionReasonsListed"
            class="multiselect"
            (ngModelChange)="motivesObservation()"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="form-row" *ngIf="otherObeservations">
        <div class="col-md col-12 mb-1">
          <label class="form-label form-control-lg">
            Observaciones del Motivo de Inspección
            <small
              [@warning]="tool.stateName(show)"
              class="text-danger"
              *ngIf="serviceOrder.get('inspectionReasonObservations').invalid"
              >*</small
            ></label
          >
          <textarea
            specialIsAlphaNumeric
            [fControl]="serviceOrder.get('inspectionReasonObservations')"
            name="inspectionReasonObservations"
            type="text"
            placeholder="Observaciones del motivo de inspección"
            class="form-control form-control-lg mb-3"
            formControlName="inspectionReasonObservations"
          ></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md col-12 mb-1" *ngIf="serviceOrderInput">
          <label class="form-label form-control-lg"
            >No. de Orden de Servicio
          </label>
          <input
            type="text"
            formControlName="serviceOrderNumber"
            class="form-control form-control-lg"
            placeholder="Numero de Orden de Servicio"
            readonly
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md col-6 mb-1" *ngIf="serviceOrderInput">
          <label class="form-label form-control-lg">Digitado Por</label>
          <input
            type="text"
            placeholder="Registrado por"
            class="form-control form-control-lg"
            readonly
            [value]="serviceOrderInput.registeredByName"
          />
        </div>
        <div class="col-md col-6 mb-1" *ngIf="serviceOrderInput">
          <label class="form-label form-control-lg">Modificado Por</label>
          <input
            type="text"
            placeholder="Modificado por"
            class="form-control form-control-lg"
            readonly
            [value]="serviceOrderInput.modifiedByName"
          />
        </div>
      </div>
    </form>
    <br />
    <br />
    <br />
  </div>
</div>

<ng-template #isTheFilter>
  <form [formGroup]="serviceOrder">
    <div class="form-row">
      <div class="col-md col-6 mb-1">
        <label class="form-label form-control-lg">Tipo de Servicio </label>
        <el-dropdown
          [options]="serviceTypes"
          customClass="form-control form-control-lg"
          formControlName="serviceTypeId"
          [disabled]="serviceOrderInput"
          placeHolder="Elige un tipo de servicio"
        ></el-dropdown>
      </div>
      <div class="col-md col-6 mb-1">
        <label class="form-label form-control-lg">Tipo de Solicitante </label>
        <el-dropdown
          [options]="applicantTypes"
          customClass="form-control form-control-lg"
          formControlName="applicantTypeId"
          [disabled]="serviceOrder.get('serviceTypeId').invalid"
          placeHolder="Elige un tipo de solicitante"
        ></el-dropdown>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md col-6 mb-1">
        <label class="form-label form-control-lg">Forma de Solicitud </label>
        <el-dropdown
          [options]="requestForms"
          formControlName="requestFormId"
          customClass="form-control form-control-lg"
          placeHolder="Elige una forma de solicitud"
        ></el-dropdown>
      </div>

      <div class="col-md col-6 mb-1">
        <label class="form-label form-control-lg">Fecha Estimada Cierre </label>
        <div class="input-group">
          <input
            class="form-control form-control-lg"
            placeholder="Fecha Estimada Cierre"
            name="estimatedDeadLine"
            formControlName="estimatedDeadLine"
            ngbDatepicker
            #r="ngbDatepicker"
            readonly
            [markDisabled]="tool.isDisabled"
            [disabled]="!isFilter"
            (click)="r.toggle()"
          />
          <div class="input-group-append">
            <button
              pButton
              icon="pi pi-calendar"
              class="btn btn-outline-secondary btn-lg"
              (click)="r.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-12 col-12 mb-1">
        <label class="form-label form-control-lg">Motivos de Inspección </label>
        <ng-multiselect-dropdown
          [placeholder]="'Motivos de inspección Selección Multiple'"
          [settings]="dropdownSettings"
          [data]="inspectionMotives"
          formControlName="inspectionReasonsListed"
          class="multiselect"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>
  </form>
</ng-template>
