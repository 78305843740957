import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { ButtonModel } from 'src/app/modules/shared/_elements/element-ui/table/models/button-model';
import { TableConfigView } from 'src/app/modules/shared/_elements/element-ui/table/models/table-config-view';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';
import Swal from 'sweetalert2';
import { serviceOrder } from '../../../serviceOrderForm/_models/serviceOrder';
import { orderNotes } from '../../../serviceOrderForm/order-notes/_models/orderNotes';
import { OrderNotesDataService } from '../../../serviceOrderForm/order-notes/order-notes-data.service';
import { OrderNotesComponent } from '../../../serviceOrderForm/order-notes/order-notes.component';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-order-notes-history',
  templateUrl: './order-notes-history.component.html',
  styleUrls: ['./order-notes-history.component.css']
})
export class OrderNotesHistoryComponent implements OnInit {
  @Input() serviceOrder: serviceOrder;
  @Output() empty = new EventEmitter<boolean>()
  @Input() canEdit: boolean = true;

  amount = 0;
  reLoad: Function;
  history: Subscription
  headers: TableConfigView[];
  records: orderNotes[];
  cols: any[];
  filters: any[];
  loading: boolean = true;
  InitialFormValue: FormGroup;
  subs: Subscription;
  maxDate = { year: moment().year(), month: moment().month() + 1, day: moment().date() }
  constructor(private http: HttpClientService, private route: Router, private user: UserService,
    private fb: FormBuilder, private orderNotesData: OrderNotesDataService, private ngBModal: NgbModal
    , private httpservice: HttpClient, private tool: ToolsService) {

  }

  searchForm: FormGroup;

  ngOnDestroy(): void {
    this.history.unsubscribe()
    this.subs.unsubscribe();
  }
  customButtons: ButtonModel[];

  deleteRecord(record) {
    Swal.fire({
      icon: 'warning',
      title: '¿Esta seguro que desea eliminarlo?',
      showDenyButton: true,
      confirmButtonText: `Eliminar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.http.patch<orderNotes[]>(record, `ServiceOrderNote/${record.serviceOrderNoteId}?registrationStatus=false`).subscribe(
          {
            next: response => {
              this.getRecords()
            },
            error: error => {
            }
          })
      } else if (result.isDenied) {
      }
    });
  }
  ngOnInit(): void {
    this.assignButtons()
    if (!this.canEdit) {
      this.customButtons.pop()
    }
    this.getRecords()
    this.subs = this.orderNotesData.subject.subscribe(data => {
      this.getRecords()

    });

    this.searchForm = this.fb.group({
      sinceDate: new FormControl(this.tool.dateToObject(moment().subtract(1, 'M').set('date', 1).format().toString())),
      untilDate: new FormControl(this.tool.dateToObject(moment().format().toString())),
      show: new FormControl(false)
    })


    this.filters = ["serviceOrderNoteInfo", "registrationDateStr", "registeredByName"]
    this.cols = [


      {
        field: 'serviceOrderNoteInfo',
        view: { table: true, detail: true },
        header: 'INFORMACIÓN',
        width: 3,
        tooltip: 'INFORMACIÓN',
        fixedColumn: true,

      },


      {
        field: 'registrationDateStr',
        header: 'FECHA NOTA',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'FECHA NOTA',
        fixedColumn: true,

      },


      {
        field: 'registeredByName',
        header: 'REGISTRADO POR',
        view: { table: true, detail: true },
        width: 3,
        tooltip: 'REGISTRADO POR',
        fixedColumn: true,

      },


    ]
  }

  getRecords() {

    this.loading = true;
    this.history = this.http.get<orderNotes[]>(`ServiceOrderNote/ServiceOrderNotes/${this.serviceOrder.serviceOrderNumber}`).subscribe(
      {

        next: response => {

          this.records = response
          this.loading = false;
          this.amount = response.length

        },
        error: error => {

          this.empty.emit(true);
          this.records = []
          this.loading = false;
        }
      }
    )

  }

  assignButtons() {
    this.customButtons = [
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 me-2 ",
        function: async (record: serviceOrder) => {
          const ngmodal: NgbModalRef = this.ngBModal.open(
            OrderNotesComponent, { size: 'xl' });
          ngmodal.componentInstance.orderNotes = cloneDeep(record);
          ngmodal.componentInstance.canEdit = this.canEdit;
        }, label: `${this.canEdit ? 'Editar' : 'Consultar'}`
      },
      {
        class: "p-button-rounded p-button-danger css-label-btn-grid px-2 tableDeleteButton",
        function: async (record: serviceOrder) => {
          this.deleteRecord(record)
        }, label: "Eliminar"
      }]
  }

}
