export class tableNewUser {
    registeredBy: string = "";
    userId:number = 0;
    recordStatus:boolean = true;
    userCode:string = "";
    firstName:string = "";
    firstLastName:string = "";
    typeOfIdentificationId:number = 0;
    identification:string = "00000000000";
    phone:string = "";
    address:string = "";
    gender:string = "";
    email:string = "";
    repLocalProvId:number  = 0;
    roleId:number  = 0;
    supervisorUserId:number  = null;
    message:string = "";
    groupId:number = 1;

}