<div class="mt-2 ms-1 mr-2 position-relative">
  <div *ngIf="opening" class="position-absolute fixed-top d-flex justify-content-center align-items-start"
    style="background-color: rgba(0,0,0,.3);height: 100%">
    <div class="d-flex flex-column align-items-center bg-white border-secondary mt-5 py-3" style="min-width: 30%">
      <h5 style="color: #000;">Cargando el documento, por favor espere.</h5>
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>
  <app-prime-table [records]="records" [loading]="loading" [cols]="cols" [buttons]="customButtons" [filters]="filters"
    [hasGlobalFilters]="true"></app-prime-table>
</div>