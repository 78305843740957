import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface puedeSalir {
  permitirSalirDelRoute: () => Observable<boolean> | Promise<boolean> | boolean
}

@Injectable({
  providedIn: 'root'
})
export class CanExitGuard implements CanDeactivate<puedeSalir> {
  canDeactivate(component: puedeSalir) {
    return component.permitirSalirDelRoute ? component.permitirSalirDelRoute() : true


  }

}
