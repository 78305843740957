import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardLayoutComponent } from "./layout/dashboard-layout/dashboard-layout.component";


const routes: Routes = [
    { path: '', component: DashboardLayoutComponent, data: { breadcrumb: `Dashboard` } }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})


export class DashboardRoutingModule { }