import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/modules/auth/_services/user.service';
import { HttpClientService } from 'src/app/modules/shared/_services/http-client/http-client.service';
import { ToolsService } from 'src/app/modules/shared/tools/tools.service';

@Component({
  selector: 'app-forms-creation',
  templateUrl: './forms-creation.component.html',
  styleUrls: ['./forms-creation.component.css']
})
export class FormsCreationComponent implements OnInit {
  selected: boolean = false;
  constructor(private router: Router, private HttpService: HttpClientService, private user: UserService, private Aroute: ActivatedRoute, private tool: ToolsService) {


  }
  forms: any[] = []
  selection: number;
  async ngOnInit() {
    await this.checkPermission()


    //this.forms = await this.HttpService.get<any[]>('TypeOfRegister?independentRecord=true&registrationStatus=true').toPromise()
    if (this.tool.hasInternetConnection) {
      if (this.selectedForm()) {
        this.route(this.selectedForm());

      }
      else {
        this.selection = null;
      }
    } else {
      this.selection = null;

    }

  }
  selectedForm(): number {
    return Number(localStorage.getItem('typeFormId'));
  }

  findActive(Field) {
    this.selection = this.forms?.find(x => x.alternativeField == Field)?.value

  }
  async checkPermission() {

    if (this.tool.hasInternetConnection) {
      let allForms = await this.HttpService.get<any[]>('TypeOfRegister?independentRecord=true&registrationStatus=true').toPromise()
      this.forms = allForms.filter(form => {
        if (this.user.getUserData().checkCode(form.alternateField)) {
          return form
        }
      })
      if (!localStorage.getItem('forms')) {
        localStorage.setItem('forms', JSON.stringify(this.forms))
      }
    }
    else {
      let formsM: any[] = JSON.parse(localStorage.getItem('forms'));
      this.forms = formsM.filter(form => {
        if (form.alternateField !== 'PROGI' && form.alternateField !== 'INFIN') {
          return form
        }
      })
    }
  }

  route(id) {
    let { alternateField } = this.forms?.find(x => x.value == Number(id))
    localStorage.setItem('typeFormId', id)
    this.selection = Number(id);
    switch (alternateField) {
      case "ORDSE":
        this.router.navigate(['ordenServicio'], { relativeTo: this.Aroute })
        break;

      case "PROGI":
        this.router.navigate(['programaInspeccion'], { relativeTo: this.Aroute })
        break;

      case "SERVU":
        this.router.navigate(['ServicioUsuario'], { relativeTo: this.Aroute })
        break;

      default:
        null
        break;
    }
    this.selected = true;

  }
}
